import { AxiosError, AxiosResponse } from "axios";
import { Toast } from "hooks/useToast";
import {
    useInfiniteQuery,
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryResult,
} from "react-query";
import {
    CastingOffer,
    CastingOfferData,
    CreateCastingOffer,
    FetchCastingOffersParams,
    FetchCastingOffersResponse,
} from "type/castingOffer";
import { ERROR } from "type/common";
import requestToCastingVote from "utils/requestToCastingVote";

// 캐스팅 제안
const createCastingOffer = ({
    artistId,
    profileId,
    castingCallId,
    description,
    deadlineDate,
}: CreateCastingOffer) => {
    return requestToCastingVote({
        method: "POST",
        url: "/offers",
        data: {
            artistId,
            profileId,
            castingCallId,
            description,
            deadlineDate,
        },
    });
};
export const useCreateCastingOfferMutation = () => {
    return useMutation<
        AxiosResponse<CastingOfferData>,
        AxiosError<ERROR>,
        CreateCastingOffer
    >(createCastingOffer, {
        onError: (error: AxiosError<ERROR>) => {
            Toast.error(error.response?.data.message);
        },
    });
};

// 제안 수락
const acceptCastingOffer = ({ id }: CastingOffer) => {
    return requestToCastingVote({
        method: "PATCH",
        url: `offers/${id}/accept`,
    });
};
export const useAcceptCastingOfferMutation = (): UseMutationResult<
    AxiosResponse<CastingOffer>,
    AxiosError<ERROR>,
    CastingOffer
> => {
    return useMutation<
        AxiosResponse<CastingOffer>,
        AxiosError<ERROR>,
        CastingOffer
    >(acceptCastingOffer, {
        onError: (error: AxiosError<ERROR>) => {
            Toast.error(error.response?.data.message);
        },
    });
};

// 제안 거절
const rejectCastingOffer = ({ id }: CastingOffer) => {
    return requestToCastingVote({
        method: "PATCH",
        url: `offers/${id}/reject`,
    });
};
export const useRejectCastingOfferMutation = (): UseMutationResult<
    AxiosResponse<CastingOffer>,
    AxiosError<ERROR>,
    CastingOffer
> => {
    return useMutation<
        AxiosResponse<CastingOffer>,
        AxiosError<ERROR>,
        CastingOffer
    >(rejectCastingOffer, {
        onError: (error: AxiosError<ERROR>) => {
            Toast.error(error.response?.data.message);
        },
    });
};

// 받은 제안 목록
const fetchCastingOffers = (params: FetchCastingOffersParams) => {
    return requestToCastingVote({
        method: "GET",
        url: "/offers/received",
        params,
    }).then((res) => res.data);
};
export const useCastingOffers = (params: FetchCastingOffersParams) => {
    return useInfiniteQuery(
        ["sendProfiles", params],
        ({ pageParam = 0 }) =>
            fetchCastingOffers({ ...params, page: pageParam, size: 20 }),
        {
            getNextPageParam: (lastPage) => {
                return lastPage?.data.last
                    ? undefined
                    : lastPage.data.pageable.pageNumber + 1;
            },
            cacheTime: 0,
            onError: (error: AxiosError<ERROR>) => {
                Toast.error(error.response?.data.message);
            },
        }
    );
};

// 보낸 제안 목록
const fetchStaffCastingSuggest = (params: FetchCastingOffersParams) => {
    return requestToCastingVote({
        method: "GET",
        url: `/offers/sent`,
        params: params,
    }).then((res) => res.data);
};
export const useStaffCastingSuggest = (params?: FetchCastingOffersParams) => {
    return useInfiniteQuery(
        ["castingSuggest", params],
        ({ pageParam = 0 }) =>
            fetchStaffCastingSuggest({ ...params, page: pageParam, size: 20 }),
        {
            getNextPageParam: (lastPage) => {
                return lastPage.data.last
                    ? undefined
                    : lastPage?.data.pageable.pageNumber + 1;
            },
            keepPreviousData: true,
            onError: (error: AxiosError<ERROR>) => {
                Toast.error(error.response?.data.message);
            },
        }
    );
};
