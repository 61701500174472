import React from "react";

import { useProductionDetailQuery } from "api/Mypage/Mypage";
import Thumnail from "assets/thumnail.png";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useParams } from "react-router-dom";
import { MY_ROLE } from "utils/onRoleStatus";

const StaffMyFilmo = () => {
  const role = MY_ROLE();
  const { productId } = useParams();
  const { data } = useProductionDetailQuery(Number(productId));

  const filmoData = data?.data.data;

  const filmInfo = [
    {
      id: 0,
      title: "분야",
      desc: filmoData?.format?.label,
    },
    {
      id: 1,
      title: "감독",
      desc: filmoData?.directorName,
    },
    {
      id: 2,
      title: "제작사",
      desc: filmoData?.companyName,
    },
    {
      id: 3,
      title: "캐스팅",
      desc:
        role === "ROLE_MANAGER"
          ? "매니저"
          : role === "ROLE_CASTING_DIRECTOR"
          ? "캐스팅 디렉터"
          : role === "ROLE_DIRECTOR"
          ? "연출"
          : role === "ROLE_PRODUCER"
          ? "제작"
          : role === "ROLE_AGENCY"
          ? "광고"
          : role === "ROLE_WRITER"
          ? "작가"
          : "",
    },
  ];

  return (
    <>
      <HeaderWithBackButton title={""} />
      <div className="py-5 px-5 pb-24">
        <div className="flex mb-4 items-center justify-between">
          <div className="BBody20">{filmoData?.title}</div>
        </div>
        <div className="flex items-start gap-4">
          <div className="w-full flex-col gap-2 flex MBody14">
            {filmInfo.map((item) => {
              return (
                <div key={item.id} className="flex items-start gap-2">
                  <div className="w-[74px] text-Gray05">{item.title}</div>
                  <div className="flex-1">{item.desc}</div>
                </div>
              );
            })}
          </div>
          <img
            src={
              filmoData?.thumbnailUrl !== null
                ? `${process.env.REACT_APP_MEDIA_URL}${filmoData?.thumbnailUrl}`
                : Thumnail
            }
            className="max-w-[109px] object-cover rounded-[10px] flex-1 h-[136px]"
            alt="filmoDetailImage"
          />
        </div>
        <div>
          <div className="RBody16 whitespace-pre-wrap my-4 mb-10">
            {filmoData?.description}
          </div>
          {/* <div>
          <div className="BBody18 mb-4">모집중인 캐스팅</div>
          <div className="flex flex-wrap gap-4">
            {castingCallList?.data.data.content.map((cast, i) => {
              return (
                <CastingRecruitCard
                  key={cast.castingCallId}
                  title={cast.title}
                  type={cast?.castingCallRole.label}
                  gender={cast.gender?.label}
                  age={`${cast.minAge}살 ~ ${cast.maxAge}살`}
                  onClick={() => {
                      navigate(`/casting/${cast.castingCallId}`, {
                        state: {
                          directorName: filmoData?.directorName,
                          companyName: filmoData?.companyName,
                          castingDirectorName: filmoData?.castingDirectorName,
                        },
                      });
                  }}
                />
              );
            })}
          </div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default StaffMyFilmo;
