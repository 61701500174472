import React from "react";

const Skeleton = ({ type }: { type?: string }) => {
  return (
    <div className="absolute z-50 w-full mx-auto">
      {type === "ARTIST_CARD" ? (
        <div className="w-[139px] bg-Gray00">
          <div className="skeleton w-full max-w-[139px] h-[173.75px] bg-Gray03" />
          <div className="skeleton mt-2 mb-3 w-[53px] h-5 bg-Gray03" />
          <div className="skeleton w-full max-w-[139px] h-4 bg-Gray03" />
        </div>
      ) : type === "ARTIST_LIST_CARD" ? (
        <div className="w-full bg-Gray00">
          <div className="skeleton w-full aspect-4/5 bg-Gray03" />
          <div className="skeleton my-2 w-[53px] h-5 bg-Gray03" />
          <div className="skeleton w-full max-w-[114px] h-4 bg-Gray03" />
        </div>
      ) : type === "ARTIST_THUMBNAIL" ? (
        <>
          <div className="skeleton w-full h-[450px] rounded-[10px]"></div>
        </>
      ) : type === "STAFF_LIST" ? (
        <>
          <div className="w-full max-w-[360px] p-4 flex items-center gap-4">
            <div className="skeleton w-[60px] h-[60px] !rounded-full"></div>
            <div className="flex flex-col gap-2.5">
              <div className="skeleton w-12 h-4"></div>
              <div className="skeleton w-44 h-4 "></div>
            </div>
          </div>
        </>
      ) : type === "PROFILE_LIST" ? (
        <>
          <div className="pt-9 w-[360px]">
            <div className="skeleton w-[55px] h-4 bg-Gray03 inline-block" />
            <div className="skeleton w-[270x] h-6 mt-3 bg-Gray03" />
          </div>
        </>
      ) : type === "PROFILE_DETAIL" ? (
        <>
          <div className="h-full w-[360px] mx-auto">
            <div className="flex flex-col items-start gap-2.5">
              <div className="skeleton w-full h-6"></div>
              <div className="skeleton w-full h-6"></div>
              <div className="skeleton w-[245px] h-6"></div>
            </div>
            <div className="skeleton w-14 h-4 my-6"></div>
            <div className="skeleton w-full h-[450px] rounded-[10px]"></div>
            <div className="w-full h-[298px] rounded-[10px] mt-4 mb-7 bg-Gray01 p-4">
              <div className="flex items-start">
                <div className="flex flex-col gap-4 w-28">
                  <div className="skeleton w-7 h-4"></div>
                  <div className="skeleton w-10 h-4"></div>
                  <div className="skeleton w-7 h-4"></div>
                  <div className="skeleton w-7 h-4"></div>
                  <div className="skeleton w-7 h-4"></div>
                  <div className="skeleton w-7 h-4"></div>
                  <div className="skeleton w-10 h-4"></div>
                  <div className="skeleton w-12 h-4"></div>
                  <div className="skeleton w-14 h-4"></div>
                </div>
                <div className="flex flex-col gap-4 flex-1">
                  <div className="skeleton w-7 h-4"></div>
                  <div className="skeleton w-12 h-4"></div>
                  <div className="skeleton w-8 h-4"></div>
                  <div className="skeleton w-7 h-4"></div>
                  <div className="skeleton w-7 h-4"></div>
                  <div className="skeleton w-9 h-4"></div>
                  <div className="skeleton w-11 h-4"></div>
                  <div className="skeleton w-10 h-4"></div>
                  <div className="skeleton w-12 h-4"></div>
                </div>
              </div>
            </div>
            <div className="skeleton w-16 h-6"></div>
            <div className="skeleton w-20 h-4 mt-6 mb-7"></div>
            <div className="skeleton w-full h-4 mb-3"></div>
            <div className="skeleton w-2/4 h-4"></div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Skeleton;
