import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import requestToCastingVote from "../utils/requestToCastingVote";
import { useRecoilState } from "recoil";
import { loginCheck } from "../store/auth/atom";

const KakaoLoginSuccess = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useRecoilState(loginCheck);

  useEffect(() => {
    requestToCastingVote({
      method: "GET",
      url: "/auth",
    })
      .then((response) => {
        if (response.data.code === "200") {
          setLogin(true);
          const info = response.data.data;
          const id = String(info?.memberId);
          const userInfo = {
            id: id,
            username: info.username,
            role: info.role,
          };
          const userJson = JSON.stringify(userInfo);
          localStorage.clear();
          localStorage.setItem("user", userJson);
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("오류 발생", error); //
      });
  }, []);

  return <div></div>;
};

export default KakaoLoginSuccess;
