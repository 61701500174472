import { AxiosError } from "axios";
import { useInfiniteQuery } from "react-query";
import requestToCastingVote from "utils/requestToCastingVote";
import { ERROR } from "type/common";
import { Toast } from "hooks/useToast";

export interface NoticeQueryParams {
    page?: number;
    size?: number;
}
export interface ScrapProfileQueryParams {
    type: "ARTIST" | "STAFF";
    page?: number;
    size?: number;
}

// 스크랩 공고 조회
const fetchScrapNotice = (params: NoticeQueryParams) => {
    return requestToCastingVote({
        method: "GET",
        url: "/favorites/castingcall",
        params,
    }).then((res) => res.data);
};
export const useScarpNotice = (params: NoticeQueryParams) => {
    return useInfiniteQuery(
        ["scrapNotice", params],
        ({ pageParam = 0 }) => fetchScrapNotice({ ...params, page: pageParam, size: 20 }),
        {
            getNextPageParam: (lastPage) => {
                return lastPage?.data.last ? undefined : lastPage.data.pageable.pageNumber + 1;
            },
            cacheTime: 0,
            onError: (error: AxiosError<ERROR>) => {
                Toast.error(error.response?.data.message);
            },
        }
    );
};

// 스크랩 작업 조회
const fetchScrapProduction = (params: NoticeQueryParams) => {
    return requestToCastingVote({
        method: "GET",
        url: "/favorites/production",
        params,
    }).then((res) => res.data);
};
export const useScarpProduction = (params: NoticeQueryParams) => {
    return useInfiniteQuery(
        ["scrapNotice", params],
        ({ pageParam = 0 }) => fetchScrapProduction({ ...params, page: pageParam, size: 20 }),
        {
            getNextPageParam: (lastPage) => {
                return lastPage?.data.last ? undefined : lastPage.data.pageable.pageNumber + 1;
            },
            cacheTime: 0,
            onError: (error: AxiosError<ERROR>) => {
                Toast.error(error.response?.data.message);
            },
        }
    );
};

// 스크랩 프로필 조회
const fetchScrapProfile = (params: ScrapProfileQueryParams) => {
    return requestToCastingVote({
        method: "GET",
        url: "/favorites/profile",
        params,
    }).then((res) => res.data);
};
export const useScarpProfile = (params: ScrapProfileQueryParams) => {
    return useInfiniteQuery(
        ["scrapNotice", params],
        ({ pageParam = 0 }) => fetchScrapProfile({ ...params, page: pageParam, size: 20 }),
        {
            getNextPageParam: (lastPage) => {
                return lastPage?.data.last ? undefined : lastPage.data.pageable.pageNumber + 1;
            },
            cacheTime: 0,
            onError: (error: AxiosError<ERROR>) => {
                Toast.error(error.response?.data.message);
            },
        }
    );
};
