import React from "react";

const Private = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">
        개인정보 처리방침
      </h1>
      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제1조 (목적)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          주식회사 커넥션스튜디오(이하 ‘회사’)는 이용자의 개인정보를 보호하고자
          관련 법령을 준수하며, 서비스 이용자의 개인정보 보호와 관련된 고충을
          신속하고 원활하게 처리하기 위해 본 개인정보처리방침을 수립합니다. 본
          방침은 회사가 운영하는 캐스팅보트 앱(이하 ‘서비스’)에서 수집하는
          개인정보의 처리 원칙 및 관리에 관한 내용을 명시합니다.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제2조 (개인정보 처리의 원칙)
        </h2>
        <p className="text-gray-600 leading-relaxed mb-4">
          회사는 개인정보 보호 법령을 준수하며, 이용자의 개인정보는 목적에 따라
          필요한 경우에만 수집 및 사용되며, 법령이 정한 경우를 제외하고는
          이용자의 동의 없이 제3자에게 제공하지 않습니다.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제3조 (본 방침의 공개)
        </h2>
        <p className="text-gray-600 leading-relaxed mb-4">
          회사는 이용자가 언제든지 본 방침을 쉽게 확인할 수 있도록 앱 및
          웹사이트 첫 화면에 게시하고 있으며, 글자 크기와 색상 등을 통해
          이용자가 쉽게 인식할 수 있도록 합니다.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제4조 (본 방침의 변경)
        </h2>
        <ul className="list-decimal list-inside text-gray-600">
          <li>
            회사는 관련 법령 및 내부 방침에 따라 개인정보처리방침을 개정할 수
            있으며, 개정이 있을 경우 시행일을 명시하여 최소 7일 전 공지합니다.
            이용자의 권리 변경 등 중대한 변경이 있을 경우 최소 30일 전에
            공지합니다.
          </li>
          <li>
            공지 방법: 회사의 홈페이지, 앱의 공지사항, 또는 개별 알림(전자우편,
            문자메시지 등)을 통해 공지합니다.
          </li>
        </ul>
      </section>
      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제5조 (회원 가입 시 수집하는 정보)
        </h2>
        <p className="text-gray-600 leading-relaxed mb-4">
          회사는 서비스 이용을 위해 회원가입 시 다음 정보를 수집합니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>
            <strong>필수 정보:</strong> 이름, 닉네임, 생년월일, 성별, 프로필 중
            선택항목을 제외한 사항, 내/외국인 여부, 연락처, 이동통신사 정보,
            전자우편, 주소, 비밀번호, 닉네임, 카드 번호·비밀번호·유효기간,
            은행명, 은행계좌번호, 보호자의 이름·생년월일·연락처·이동통신사 정보
          </li>
          <li>
            <strong>선택 정보:</strong> 프로필 중 사진, 관심사
          </li>
        </ul>
      </section>
      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제6조 (본인 인증을 위한 정보 수집)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          이용자의 본인 인증을 위해 다음 정보를 수집합니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>
            <strong>필수 정보:</strong> 휴대폰 번호, 이메일 주소, 이름,
            생년월일, 성별, 이동통신사 정보, 내/외국인 여부
          </li>
        </ul>
      </section>
      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제7조 (법정대리인 동의를 위한 정보)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          14세 미만 이용자의 경우 법정대리인의 동의를 위해 다음 정보를
          수집합니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>
            <strong>필수 정보:</strong> 보호자 이름, 생년월일, 휴대폰 번호,
            이동통신사 정보
          </li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제8조 (결제 서비스 제공을 위한 정보)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 유료 서비스 제공을 위해 결제 정보를 수집합니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>
            <strong>필수 정보:</strong> 카드번호, 카드 비밀번호, 유효기간,
            생년월일, 은행명 및 계좌번호
          </li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제9조 (서비스 제공을 위한 정보)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          서비스 제공 및 사용자 관리를 위해 다음 정보를 수집합니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>
            <strong>필수 정보:</strong> 아이디, 이메일 주소, 이름, 생년월일,
            연락처
          </li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제10조 (부정 이용 확인 및 분석을 위한 정보)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          서비스 이용 및 부정이용 방지를 위해 다음 정보를 수집합니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>
            <strong>수집 정보:</strong> 서비스 이용기록, 쿠키, 접속지 정보,
            기기정보
          </li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제11조 (개인정보 수집 방법)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 다음과 같은 방법으로 이용자의 개인정보를 수집합니다.
        </p>
        <ul className="list-decimal list-inside text-gray-600">
          <li>이용자가 서비스에 직접 입력하는 방식</li>
          <li>앱을 통한 수집</li>
          <li>자동화된 수단에 의한 수집(쿠키 및 유사 기술)</li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제12조 (개인정보의 제3자 제공
        </h2>
        <p className="text-gray-600 leading-relaxed"></p>
        <ul className="list-decimal list-inside text-gray-600">
          <li>
            회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위
            내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등
            개인정보보호법 제17조, 제18호에 해당하는 경우에만 개인정보를
            제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게
            제공하지 않습니다.
          </li>
          <li>
            회사는 원활한 서비스 제공을 위해 다음의 경우 정보주체의 동의를 얻어
            필요 최소한의 범위로만 제공합니다.
          </li>
          <ul className="list-inside text-gray-600">
            <li>
              ■ 제공받는 자: 캐스팅 디렉터·스탭·권한자·관계자(보다 자세한 사항은
              각 공고에 기재된 캐스팅 디렉터, 스탭 등의 정보를 확인 바랍니다)
            </li>
            <li>■ 제공목적: 서비스 제공(캐스팅, 피드백 관련)</li>
            <li>
              ■ 제공항목: 성명, 생년월일, 성별, 프로필, 내/외국인 여부, 연락처,
              전자우편, 주소, 보호자의 이름·연락처
            </li>
            <li>■ 보유 및 이용기간: 캐스팅 이용 목적이 완료되는 시까지</li>
          </ul>
          <li>
            회사는 정부 관계부처가 합동으로 발표한 「긴급상황 시 개인정보 처리
            및 보호수칙」에 따라 재난, 감염병, 급박한 생명·신체 위험을 초래하는
            사건·사고, 급박한 재산손실 등의 긴급상황이 발생하는 경우 정보주체의
            동의 없이 관계기관에 개인정보를 제공할 수 있습니다.
          </li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제13조 (개인정보처리의 위탁)
        </h2>
        <table className="table-auto border-collapse border">
          <thead>
            <tr>
              <th className="border">위탁받는 자(수탁자)</th>
              <th className="border">위탁업무</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border">(주)카카오</td>
              <td className="border">카카오 알림톡 발송</td>
            </tr>
            <tr>
              <td className="border"></td>
              <td className="border">결제 서비스</td>
            </tr>
          </tbody>
        </table>
        <ul className="list-disc list-inside text-gray-600">
          <li>
            회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보
            처리업무를 위탁하고 있습니다.
          </li>
          <li>
            회사는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무
            수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한,
            수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등
            문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고
            있습니다.
          </li>
          <li>
            위탁업무의 내용이나 수탁자가 변경될 경우 지체없이 본 개인정보
            처리방침을 통하여 공개하도록 하겠습니다.
          </li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제14조 (개인정보의 이용 목적)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 수집한 개인정보를 다음과 같은 목적으로 이용합니다.
        </p>
        <ul className="list-decimal list-inside text-gray-600">
          <li>서비스 제공 및 회원 관리</li>
          <li>서비스 개선을 위한 사용자 피드백 수집</li>
          <li>마케팅 및 프로모션 안내</li>
          <li>서비스 이용 분석 및 맞춤형 서비스 제공</li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제15조 (개인정보 보유 및 이용 기간)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 이용자의 개인정보를 다음과 같이 이용 목적이 달성될 때까지
          보유하며, 목적 달성 후에는 해당 정보를 지체 없이 파기합니다.
        </p>
        <ul className="list-decimal list-inside text-gray-600">
          <li>
            회원 가입 및 관리 : 탈퇴 시까지
            <br />
            다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
          </li>
          <ul className="list-disc list-inside text-gray-600">
            <li>
              관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당
              수사·조사 종료 시까지
            </li>
            <li>
              이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산
              시까지
            </li>
            <li>부정이용 방지를 위한 경우 탈퇴 후 1년</li>
          </ul>
          <li>
            재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제·정산
            완료시까지
          </li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제16조 (법령에 따른 개인정보 보유 및 이용 기간)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 「전자상거래 등에서의 소비자 보호에 관한 법률」,
          「통신비밀보호법」 및 관련 법령에 따라 다음과 같은 기간 동안
          개인정보를 보유합니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>계약 및 청약철회 기록: 5년</li>
          <li>대금 결제 및 재화 공급 기록: 5년</li>
          <li>소비자 불만 및 분쟁 처리 기록: 3년</li>
          <li>표시 및 광고 기록: 6개월</li>
          <li>웹사이트 로그 기록: 3개월</li>
          <li>전자금융거래 기록: 5년</li>
          <li>위치정보 기록: 6개월</li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제17조 (개인정보 파기 절차 및 방법)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 개인정보 처리 목적이 달성된 경우 개인정보 보호책임자의 승인을
          받아 지체 없이 파기합니다. 전자적 파일 형태의 정보는 재생 불가능한
          기술적 방법으로 삭제하며, 종이 자료는 분쇄하거나 소각하여 파기합니다.
        </p>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제18조 (서비스 미이용자의 개인정보 처리)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          1년간 서비스를 이용하지 않은 회원의 개인정보는 분리 보관 후 파기되며,
          개인정보의 파기 30일 전 사전 통지를 통해 개인정보가 파기되는 사실,
          기간 만료일 및 파기되는 개인정보의 항목을 이메일, 문자 등 이용자에게
          통지 가능한 방법으로 알리고 있습니다.
        </p>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제19조 (광고성 정보 전송)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 영리 목적의 광고성 정보를 발송하기 전에 이용자의 사전 동의를
          받으며, 수신 거부 요청 시 즉시 처리합니다.
        </p>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제20조 (이용자의 권리와 의무)
        </h2>
        <ul className="list-decimal list-inside text-gray-600">
          <li>
            이용자는 언제든지 자신의 개인정보를 조회, 수정 및 수집 동의를 철회할
            수 있으며, 정확한 정보 유지를 통해 불이익을 방지하도록 해야 합니다.
          </li>
          <li>
            권리행사는 회사에 대해 서면, 전자우편, FAX 등을 통하여 할 수 있으며,
            회사는 이에 대해 지체없이 조치합니다.
          </li>
          <li>
            권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을
            통하여 할 수도 있습니다.
          </li>
          <li>
            개인정보 열람 및 처리정지 요구는 「개인정보 보호법」제35조 제4항,
            제37조 제2항에 의하여 이용자의 권리가 제한될 수 있습니다.
          </li>
          <li>
            개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
            대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
          </li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제21조 (개인정보의 안전성 확보조치)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
          있습니다.
        </p>
        <ul className="list-decimal list-inside text-gray-600">
          <li>
            관리적 조치 : 내부관리계획 수립·시행, 전담조직 운영, 정기적 직원
            교육
          </li>
          <li>
            기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
            설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신
          </li>
          <li>물리적 조치 : 전산실, 자료보관실 등의 접근통제</li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제22조 (개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)
        </h2>
        <ul className="list-decimal list-inside text-gray-600">
          <li>
            회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용 정보를
            저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
          </li>
          <li>
            쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
            브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의
            하드디스크에 저장되기도 합니다.
          </li>
          <ul className="list-disc list-inside text-gray-600">
            <li>
              쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한
              방문 및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여
              이용자에게 최적화된 정보 제공을 위해 사용됩니다.
            </li>
            <li>
              쿠키의 설치·운영 및 거부: 웹브라우저 상단의 도구&gt;인터넷
              옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수
              있습니다.
            </li>
            <li>
              쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
              있습니다.
            </li>
          </ul>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제23조 (개인정보 보호책임자 지정)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 이용자의 개인정보를 보호하고 관련 문의와 불만을 처리하기 위해
          아래와 같은 개인정보 보호책임자를 지정하고 있습니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>책임자: 유성봉</li>
          <li>전화번호: 02-517-2468</li>
          <li>이메일: support@castingvote.im</li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제24조 (개인정보 열람청구)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          이용자는 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>부서명: 캐스팅보트 개발팀</li>
          <li>전화번호: 02-517-2468</li>
          <li>이메일: support@castingvote.im</li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제25조 (권익침해 구제방법)
        </h2>
        <ul className="list-decimal list-inside text-gray-600">
          <li>
            이용자는 개인정보침해로 인한 구체를 받기 위하여
            개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
            분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
            개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
            바랍니다.
          </li>
          <ul>
            <li>
              ① 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
            </li>
            <li>
              ② 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
            </li>
            <li>③ 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
            <li>④ 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
          </ul>
          <li>
            회사는 이용자의 개인정보자기결정권을 보장하고, 개인정보침해로 인한
            상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우
            아래의 담당부서로 연락해 주시기 바랍니다.
          </li>
          <ul className="list-disc list-inside text-gray-600">
            <li>부서명: 캐스팅보트 고객센터</li>
            <li>전화번호: 02-517-2468</li>
            <li>이메일: support@castingvote.im</li>
          </ul>
          <li>
            「개인정보 보호법」제35조(개인정보의 열람), 제36조(개인정보의
            정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에
            대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
            이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
            청구할 수 있습니다.
          </li>
          <ul className="list-disc list-inside text-gray-600">
            <li>중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)</li>
          </ul>
        </ul>
      </section>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          textAlign: "left",
          border: "1px solid black",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                width: "50%",
                border: "1px solid black",
                padding: "8px",
              }}
            >
              위탁받는 자(수탁자)
            </th>
            <th
              style={{
                width: "50%",
                border: "1px solid black",
                padding: "8px",
              }}
            >
              위탁업무
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
              }}
            >
              (주)카카오
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
              }}
            >
              카카오 알림톡 발송
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
              }}
            >
              웰컴페이먼츠
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
              }}
            >
              결제 서비스
            </td>
          </tr>
        </tbody>
      </table>

      <footer className="text-gray-500 text-sm mt-8">
        <p>본 방침은 2025년 1월 1일부터 시행됩니다.</p>
      </footer>
    </div>
  );
};

export default Private;
