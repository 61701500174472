export const JOB_LIST = [
    { id: 0, title: "배우", role: "ACTOR" },
    { id: 1, title: "모델", role: "MODEL" },
    { id: 2, title: "인플루언서", role: "INFLUENCER" },
    { id: 3, title: "뮤지컬배우", role: "MUSICAL_ACTOR" },
    { id: 4, title: "가수", role: "SINGER" },
    { id: 5, title: "유튜버", role: "YOUTUBER" },
    { id: 6, title: "성우", role: "VOICE_ACTOR" },
    { id: 7, title: "코미디언", role: "COMEDIAN" },
    { id: 8, title: "아나운서", role: "ANNOUNCER" },
    { id: 9, title: "방송인", role: "BROADCASTER" },
    { id: 10, title: "스턴트맨", role: "STUNTMAN" },
    { id: 11, title: "나레이터", role: "NARRATOR" },
    { id: 12, title: "MC", role: "MC" },
    // { id: 13, title: "일반인", role: "GENERAL" },
];

export const STAFF_JOB_LIST = [
    {
        id: 0,
        title: "캐스팅 디렉터",
        value: "CASTING_DIRECTOR",
        role: "CASTING_DIRECTOR",
        name: "JOB",
    },
    { id: 1, title: "제작", value: "PRODUCER", role: "PRODUCER", name: "JOB" },
    { id: 2, title: "연출", value: "DIRECTOR", role: "DIRECTOR", name: "JOB" },
    { id: 3, title: "매니저", value: "MANAGER", role: "MANAGER", name: "JOB" },
    { id: 4, title: "광고", value: "AGENCY", role: "AGENCY", name: "JOB" },
    { id: 5, title: "작가", value: "WRITER", role: "WRITER", name: "JOB" },
];

export const TYPE_LIST = [
    { id: 0, title: "영화", role: "MOVIE" },
    { id: 1, title: "드라마", role: "TV_SERIES" },
    { id: 2, title: "예능", role: "VARIETY_SHOW" },
    { id: 3, title: "광고", role: "ADVERTISEMENT" },
    { id: 4, title: "뮤직비디오", role: "MUSIC_VIDEO" },
    { id: 5, title: "웹드라마", role: "WEB_SERIES" },
    { id: 6, title: "숏폼", role: "SHORT_FORM" },
    { id: 7, title: "독립영화", role: "INDEPENDENT_FILM" },
    { id: 8, title: "단편영화", role: "SHORT_FILM" },
    { id: 9, title: "뮤지컬", role: "MUSICAL" },
    { id: 10, title: "연극", role: "THEATER" },
    { id: 11, title: "다큐멘터리", role: "DOCUMENTARY" },
];

export const CASTING_LIST: { id: number; title: string; role: string }[] = [
    { id: 0, title: "주연", role: "LEADING_ACTOR" },
    { id: 1, title: "주조연", role: "LEADING_SUPPORTING_ACTOR" },
    { id: 2, title: "조연", role: "SUPPORTING_ACTOR" },
    { id: 3, title: "조단역", role: "SUPPORTING_BIT_PART" },
    { id: 4, title: "단역", role: "BIT_PART" },
    { id: 5, title: "대역", role: "DOUBLE" },
    { id: 6, title: "이미지", role: "IMAGE_BIT_PART" },
    { id: 7, title: "특별출연", role: "CAMEO" },
    { id: 8, title: "에피소드", role: "EPISODE" },
    // { id: 9, title: "기타", role: "ETC" },
];
export const KEYWORD_LIST = [
    { id: 1, title: "동양적인" },
    { id: 2, title: "강아지상" },
    { id: 3, title: "날카로운" },
    { id: 4, title: "고양이상" },
    { id: 5, title: "토끼상" },
    { id: 6, title: "서구적인" },
    { id: 7, title: "부드러운" },
    { id: 8, title: "터프한" },
    { id: 9, title: "개성있는" },
    { id: 10, title: "청순한" },
    { id: 11, title: "섹시한" },
    { id: 12, title: "반항적인" },
    { id: 13, title: "중성적인" },
    { id: 14, title: "귀여운" },
];

export const HOBBY_LIST = [
    { id: 15, title: "액션" },
    { id: 16, title: "승마" },
    { id: 17, title: "영어" },
    { id: 18, title: "태권도" },
    { id: 19, title: "중국어" },
    { id: 20, title: "검도" },
    { id: 21, title: "달리기" },
    { id: 22, title: "일본어" },
    { id: 23, title: "한국무용" },
    { id: 24, title: "발레" },
    { id: 25, title: "수영" },
    { id: 26, title: "불어" },
];

export const GENDER_LIST = [
    { id: 0, title: "여성", role: "FEMALE" },
    { id: 1, title: "남성", role: "MALE" },
];
