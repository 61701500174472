import React from "react";
import { formatDate } from "utils/onDateFormat";

export const useTodayWithNextMonth = (duration: number = 1) => {
    const today = new Date();

    const calculateNextMonth = (date: Date, duration: number) => {
        const targetDate = new Date(date);
        targetDate.setMonth(targetDate.getMonth() + duration);

        if (targetDate.getDate() < date.getDate()) {
            targetDate.setDate(0);
        }

        return targetDate;
    };

    const nextMonth = calculateNextMonth(today, duration);

    return {
        today: formatDate(String(today)),
        nextMonth: formatDate(String(nextMonth)),
    };
};

export const formatDateWithTime = (data: string) => {
    const date = new Date(data);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}.${month}.${day} ${hours}:${minutes}`;
    return formattedDate;
};
