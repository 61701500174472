import { AxiosResponse, AxiosError } from "axios";
import { Toast } from "hooks/useToast";
import {
    UseMutationResult,
    UseQueryResult,
    useInfiniteQuery,
    useMutation,
    useQuery,
} from "react-query";
import { APIResponse, CastingCallDetailResponse } from "type/artist";
import { ERROR } from "type/common";
import {
    castingCalls,
    castingCallsParams,
    ChangeCastingCallStatusPayload,
    CreateCastingCallData,
    CreateCastingCallResponse,
    UpdateCastingCallRequest,
    UpdateCastingCallResponse,
} from "type/staff";
import requestToCastingVote from "utils/requestToCastingVote";

// 공고 등록
const createCastingCall = (
    data: CreateCastingCallData
): Promise<AxiosResponse<CreateCastingCallResponse>> => {
    return requestToCastingVote.post("castingcalls", data);
};
export const useCreateCastingCallMutation = (): UseMutationResult<
    AxiosResponse<CreateCastingCallResponse>,
    AxiosError<ERROR>,
    CreateCastingCallData
> => {
    const createCasting = useMutation<
        AxiosResponse<CreateCastingCallResponse>,
        AxiosError<ERROR>,
        CreateCastingCallData
    >(["createCastingCall"], {
        mutationFn: createCastingCall,
        onError: (error) => {
            Toast.error(error.response?.data.message);
        },
    });
    return createCasting;
};

// 공고 수정
const updateCastingCall = async (
    data: UpdateCastingCallRequest
): Promise<AxiosResponse<UpdateCastingCallResponse>> => {
    return requestToCastingVote.patch("/castingcalls", data);
};
export const useUpdateCastingCall = (): UseMutationResult<
    AxiosResponse<UpdateCastingCallResponse>,
    AxiosError<ERROR>,
    UpdateCastingCallRequest
> => {
    return useMutation(updateCastingCall, {
        onError: (error) => {
            Toast.error(error.response?.data.message);
        },
    });
};

// 공고 상태 변경
export const changeCastingCallStatus = async (
    payload?: ChangeCastingCallStatusPayload
): Promise<AxiosResponse<CreateCastingCallResponse>> => {
    return requestToCastingVote.patch("/castingcalls/status", payload);
};

export const useChangeCastingCallStatusMutation = () => {
    return useMutation(changeCastingCallStatus, {
        onSuccess: () => {
            Toast.success("공고 상태가 성공적으로 변경되었습니다.");
        },
        onError: (error: AxiosError<ERROR>) => {
            Toast.error(error.response?.data.message);
        },
    });
};

// 공고 목록 조회
// 무한스크롤
const fetchCastingCalls = ({
    title,
    type,
    deadlineType,
    roles,
    ageType,
    gender,
    page,
    formats,
    size,
    productionTitle,
    directorName,
    companyName,
    sort,
}: castingCalls) => {
    return requestToCastingVote({
        method: "GET",
        url: "castingcalls",
        params: {
            title,
            type,
            deadlineType,
            roles,
            formats,
            ageType,
            gender,
            page,
            size,
            productionTitle,
            directorName,
            companyName,
            sort,
        },
    }).then((res) => res.data);
};
export const useCastingCalls = (params: castingCalls) => {
    return useInfiniteQuery(
        ["castingcalls", params],
        ({ pageParam = 0 }) =>
            fetchCastingCalls({ ...params, page: pageParam, size: 20 }),
        {
            getNextPageParam: (lastPage) => {
                return lastPage?.data.last
                    ? undefined
                    : lastPage.data.pageable.pageNumber + 1;
            },
            keepPreviousData: true,
            onError: (error: AxiosError<ERROR>) => {
                Toast.error(error.response?.data.message);
            },
        }
    );
};

// 공고 상세 조회
const fetchCastingCallDetail = async (
    id: number
): Promise<AxiosResponse<APIResponse<any>>> => {
    return requestToCastingVote.get(`/castingcalls/${id}`);
};

export const useCastingCallDetail = (
    castingCallId: number
): UseQueryResult<AxiosResponse<APIResponse<any>>, AxiosError<ERROR>> =>
    useQuery(
        ["castingCallDetail", castingCallId],
        () => fetchCastingCallDetail(castingCallId),
        {
            onError: (error: AxiosError<ERROR>) => {
                Toast.error(error.response?.data.message);
            },
        }
    );

// 작품별 공고 목록 조회
const fetchProductionCastingCalls = (params: castingCallsParams) => {
    return requestToCastingVote
        .get(`/productions/${params.productionId}/castingcalls`, {
            params,
        })
        .then((res) => res.data);
};
export const useFetchProductionCastingCalls = (params: castingCallsParams) => {
    return useInfiniteQuery(
        ["castingCallByProduct", params],
        ({ pageParam = 0 }) =>
            fetchProductionCastingCalls({
                ...params,
                page: pageParam,
                size: 20,
            }),
        {
            getNextPageParam: (lastPage) => {
                return lastPage?.data.last
                    ? undefined
                    : lastPage.data.pageable.pageNumber + 1;
            },
            enabled: !!params.productionId!,
            onError: (error: AxiosError<ERROR>) => {
                Toast.error(error.response?.data.message);
            },
        }
    );
};
