import React, { ReactNode, useRef } from "react";

import { useScrollRestorationWithVirtualization } from "hooks/useScrollRestorationWithVirtuoso";
import { useLocation, useNavigationType } from "react-router";
import { Virtuoso, VirtuosoHandle, ListItem } from "react-virtuoso";

interface ReactVirtuosoProps<T> {
  data: T[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  isLoading: boolean;
  itemContent: (index: number, postItem: any) => ReactNode;
  overscan?: number;
  sleep?: number;
  useWindowScroll?: boolean;
}

const ReactVirtuoso = <T,>({
  data,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  itemContent,
  overscan,
  sleep = 0,
  useWindowScroll,
}: ReactVirtuosoProps<T>) => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const scrollKey = `scrollIndex${location.pathname}`;
  const virtuosoRef = useRef<VirtuosoHandle | null>(null);

  useScrollRestorationWithVirtualization(virtuosoRef, isLoading);

  const handleItemsRendered = (items: ListItem<T>[]) => {
    if (items.length > 0) {
      const startIndex = items[0]?.index;
      sessionStorage.setItem(scrollKey, String(startIndex));
    }
  };

  return (
    <Virtuoso
      useWindowScroll={useWindowScroll}
      data={data}
      itemContent={itemContent}
      overscan={overscan}
      endReached={() => {
        if (hasNextPage && !isFetchingNextPage) {
          fetchNextPage?.();
        }
      }}
      ref={virtuosoRef}
      itemsRendered={handleItemsRendered}
    />
  );
};

export default ReactVirtuoso;
