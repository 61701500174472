import React from "react";

import Button from "components/Button";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import PointCard from "components/PointCard";
import { useNavigate } from "react-router-dom";
import {
  useMemberPoint,
  usePaymentHistory,
  usePointHistory,
} from "api/Payment/Payment";
import { MY_ROLE } from "utils/onRoleStatus";
import { FillButton } from "components/FillButton";

const PointDetails = () => {
  const navigate = useNavigate();
  const role = MY_ROLE();

  const { data: myPoint } = useMemberPoint();
  const { data: myCharge } = usePaymentHistory({
    paymentCategory: "POINT",
    size: 5,
  });
  const { data: myUse } = usePointHistory({
    size: 5,
  });

  // 충전내역 ChargingHistory
  const ChargingHistory =
    myCharge?.pages.flatMap((item: any) => item.data.content) || [];
  const UsageHistory =
    myUse?.pages.flatMap((item: any) => item.data.content) || [];

  return (
    <>
      <HeaderWithBackButton title={"포인트"} />
      <div className="pb-24 pt-5">
        <div>
          <div className="px-5">
            <div className="MBody16 text-Gray05">내 포인트</div>
            <div className="flex mt-2 items-center justify-between">
              <div className="text-Blue04 BHead24">
                {myPoint?.data ? myPoint?.data?.toLocaleString() : "0"} P
              </div>
              {(role === "ROLE_MANAGER" || role === "ROLE_ARTIST") && (
                <FillButton
                  onClick={() => {
                    navigate("/point/charge");
                  }}
                  variant="Membership"
                  size="M"
                  text="충전"
                />
              )}
            </div>
          </div>
          <hr className="w-full mt-4 mb-7 h-2 bg-Gray01" />
          <div className="flex flex-col gap-7">
            <div className=" px-5">
              <div className="flex items-center mb-4 justify-between ">
                <div className="MBody16 text-Gray05">충전내역</div>
                {ChargingHistory.length !== 0 && (
                  <div
                    onClick={() => {
                      navigate("/point/history/charge");
                    }}
                    className="MBody16 cursor-pointer text-Blue04"
                  >
                    더보기
                  </div>
                )}
              </div>
              {ChargingHistory.length !== 0 ? (
                <div className=" flex flex-col gap-4">
                  {ChargingHistory.map((item) => {
                    return (
                      <PointCard
                        onClick={() => {
                          navigate(`/point/charge/${item.paymentId}`, {
                            state: {
                              cardName: item.cardName,
                              orderAmount: item.orderAmount,
                              orderId: item.orderId,
                              paymentDate: item.paymentDate,
                              paymentId: item.paymentId,
                              price: item.price,
                              canceled:
                                item?.paymentStatus?.code === "CANCELED",
                            },
                          });
                        }}
                        canceled={item?.paymentStatus?.code === "CANCELED"}
                        price={`${item.price}원`}
                        point={item.price}
                        paymentDate={item.paymentDate}
                        key={item.paymentId}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="text-Gray05 RBody14">충전내역이 없어요</div>
              )}
            </div>
            <div className="px-5">
              <div className="flex items-center mb-4 justify-between ">
                <div className="MBody16 text-Gray05">사용내역</div>
                {UsageHistory.length !== 0 && (
                  <div
                    onClick={() => {
                      navigate("/point/history/use");
                    }}
                    className="MBody16 cursor-pointer text-Blue04"
                  >
                    더보기
                  </div>
                )}
              </div>
              {UsageHistory.length !== 0 ? (
                <div className="flex flex-col gap-4">
                  {UsageHistory.map((item, i) => {
                    return (
                      <PointCard
                        desc={item.description}
                        price={`${item.point}포인트`}
                        paymentDate={item.createAt}
                        key={i}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="text-Gray05 RBody14">사용내역이 없어요</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PointDetails;
