import useOutSideClick from "hooks/onOutSideClick";
import React, { useRef } from "react";
import { createPortal } from "react-dom";

interface BottomPopupButtonProps {
  className?: string;
  children?: React.ReactNode;
  onClose: () => void;
  onClick?: () => void;
}

const BottomButtonPopup = ({
  children,
  onClose,
  onClick,
  className,
}: BottomPopupButtonProps) => {
  return (
    <div
      onClick={onClick}
      className="max-w-[400px] z-[51] animate-bottom transition-all text-Gray00 h-20 w-full fixed bottom-0 left-2/4 -translate-x-2/4 bg-Blue04"
    >
      {children}
    </div>
  );
};

export default BottomButtonPopup;
