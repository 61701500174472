import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { decrypt } from "utils/crypto";
import CryptoJS from "crypto-js";
import { Toast } from "hooks/useToast";

const KakaoLoginFail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const errCode = searchParams?.get("errCode");
  const message = searchParams?.get("message");

  const decryptUserInfo = () => {
    if (errCode === "ERR4006" || errCode === "ERR4009") {
      const info = searchParams?.get("info")!;
      const decodedWordArray = CryptoJS.enc.Base64.parse(info);
      const encryptUserInfo = CryptoJS.enc.Utf8.stringify(decodedWordArray);

      const decryptUserInfo = decrypt(encryptUserInfo);
      const userInfo = JSON.parse(decryptUserInfo);

      return userInfo;
    }
  };

  useEffect(() => {
    let message = "";
    const userInfo = decryptUserInfo();

    switch (errCode) {
      case "ERR4006":
        message = "카카오로 회원 가입된 회원이 아님";
        navigate(`/signup/kakao`, { replace: true, state: userInfo });
        break;
      case "ERR4007":
        message = "카카오 로그인 실패";
        navigate(`/login`, { replace: true });
        break;
      case "ERR4009":
        message = "카카오 로그인 핸드폰 번호 중복";
        navigate(`/link/kakao`, { replace: true, state: userInfo });
        break;
      case "ERR4010":
        message = "핸드폰 번호가 없습니다.";
        navigate(`/login`, { replace: true });
        break;
      case "ERR4011":
        message = "핸드폰 번호가 +82로 시작하지 않습니다.";
        navigate(`/login`, { replace: true });
        break;
      case "ERR4012":
        message = "생년월일을 생성할 수 없습니다.";
        navigate(`/login`, { replace: true });
        break;
      case "ERR4013":
        message = "생년, 월일 문자열이 비어 있습니다.";
        navigate(`/login`, { replace: true });
        break;
      case "ERR4014":
        message = "연도는 4자리 숫자여야 합니다.";
        navigate(`/login`, { replace: true });
        break;
      case "ERR4015":
        message = "월/일은 4자리 숫자 형식(MMDD)이어야 합니다.";
        navigate(`/login`, { replace: true });
        break;
      case "ERR4016":
        message =
          "같은 핸드폰 번호로 여러 계정이 존재합니다. 고객센터에 문의해주세요.";
        navigate(`/login`, { replace: true });
        break;
      default:
        message = "알 수 없는 오류가 발생했습니다.";
        navigate(`/`, { replace: true });
        break;
    }

    if (message) {
      Toast.error(message);
    }
  }, [errCode, navigate]);

  return null;
};

export default KakaoLoginFail;
