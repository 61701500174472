import React, { Children, useCallback, useRef } from "react";
import { FillButton } from "./FillButton";
import { LineButton } from "./LineButton";

interface Props {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  multiple?: boolean;
  accept?: string;
  id: string;
  children?: React.ReactNode;
  onDrop?: (e: React.DragEvent<HTMLLabelElement>) => Promise<void>;
  onDragOver?: (e: React.DragEvent<HTMLLabelElement>) => void;
}

const ImgUpload = ({
  onChange,
  title,
  accept,
  multiple,
  onDrop,
  onDragOver,
  id,
  children,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  // const onUploadImageButtonClick = useCallback(() => {
  //     if (!inputRef.current) {
  //         return;
  //     }
  //     inputRef.current.click();
  // }, []);

  return (
    <label
      htmlFor={id}
      className="cursor-pointer flex justify-center items-center w-full  disabled:opacity-20 text-center rounded-md border border-Gray03 MBody16 h-12 px-4"
      onDrop={onDrop}
      onDragOver={onDragOver}
    >
      <input
        multiple={multiple}
        accept={accept}
        id={id}
        onChange={onChange}
        className="hidden"
        type="file"
        ref={inputRef}
      />
      {title}
      {/* <LineButton
                className="w-full"
                variant="normal"
                text={title}
                onClick={onUploadImageButtonClick}
                size="L"
            ></LineButton> */}
    </label>
  );
};

export default ImgUpload;
