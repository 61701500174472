import { AxiosError, AxiosResponse } from "axios";
import { Toast } from "hooks/useToast";
import { useInfiniteQuery, useQuery, UseQueryResult } from "react-query";
import {
    ApiResponse,
    CastingCallsResponse,
    ERROR,
    MainCasting,
    MainCommon,
} from "type/common";
import requestToCastingVote from "utils/requestToCastingVote";

// 진행중인 캐스팅
const getMainCastingList = ({ status, page, size }: MainCasting) => {
    return requestToCastingVote({
        method: "GET",
        url: "home/castingcalls",
        params: {
            status,
            page,
            size,
        },
    });
};
export const useMainCastingList = (
    params: MainCasting
): UseQueryResult<AxiosResponse<CastingCallsResponse>, AxiosError<ERROR>> =>
    useQuery(["useMainCastingList", params], () => getMainCastingList(params), {
        onError: (error: AxiosError<ERROR>) => {
            Toast.error(error.response?.data.message);
        },
    });

// 캐스팅보트 pick
const MainHot = (params: MainCommon) => {
    return requestToCastingVote({
        method: "GET",
        url: "/home/profiles/hot",
        params: params,
    }).then((res) => res.data);
};

export const useMainPick = (params?: MainCommon) => {
    return useInfiniteQuery(
        ["castingPick", params],
        ({ pageParam = 0 }) =>
            MainHot({ ...params, page: pageParam, size: 20 }),
        {
            getNextPageParam: (lastPage) => {
                return lastPage?.data.last
                    ? undefined
                    : lastPage.data.pageable.pageNumber + 1;
            },
            keepPreviousData: true,
            onError: (error: AxiosError<ERROR>) => {
                Toast.error(error.response?.data.message);
            },
        }
    );
};
export const useMainHotList = (params: MainCommon) =>
    useQuery(["useMainHotList", params], () => MainHot(params), {
        onError: (error: AxiosError<ERROR>) => {
            Toast.error(error.response?.data.message);
        },
    });

// 새로 올라온 프로필
const getMainNewList = ({ page, size }: MainCommon) => {
    return requestToCastingVote({
        method: "GET",
        url: "home/profiles/new",
        params: {
            page,
            size,
        },
    });
};
export const useMainNewList = (
    params: MainCommon
): UseQueryResult<AxiosResponse<ApiResponse>, AxiosError<ERROR>> =>
    useQuery(["useMainNewList", params], () => getMainNewList(params), {
        onError: (error: AxiosError<ERROR>) => {
            Toast.error(error.response?.data.message);
        },
    });

const fetchBanners = async (
    type: string
): Promise<
    {
        bannerId: number;
        content: string;
        type: { code: string; label: string };
    }[]
> => {
    const response = await requestToCastingVote({
        method: "GET",
        url: "/banners",
        params: { type },
    });
    return response.data.data;
};
export const useBanners = (type: string) => {
    return useQuery(["banners", type], () => fetchBanners(type), {
        enabled: !!type,
    });
};
