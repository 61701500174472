import { atom } from "recoil";

export const loginCheck = atom<boolean>({
  key: "loginCheck",
  default: false,
});

export const innderHeightValue = atom<number>({
  key: "innderHeightValue",
  default: window.innerHeight,
});

export const loadingState = atom({
  key: "loadingState",
  default: false,
});

export const authInfo = atom<{
  memberId?: null | number;
  userId?: string;
  birthDate?: string;
  gender: { code: string; label: string };
  name?: string;
  profileId?: null | number;
  profileName?: string;
  thumbnailUrl?: string;
  authorities?: string[];
  favoriteArtistProfileIds?: number[];
  favoriteCastingCallIds?: number[];
  favoriteProductionIds?: number[];
  favoriteStaffProfileIds?: number[];
  role: { code: string; label: string };
}>({
  key: "AuthInfo",
  default: {
    authorities: [],
    birthDate: "",
    favoriteArtistProfileIds: [],
    favoriteCastingCallIds: [],
    favoriteProductionIds: [],
    favoriteStaffProfileIds: [],
    gender: {
      code: "",
      label: "",
    },
    memberId: null,
    name: "",
    profileId: null,
    profileName: "",
    role: { code: "", label: "" },
    thumbnailUrl: "",
    userId: "",
  },
});
