import React from "react";

const PrivatePayment = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">
        캐스팅보트 유료서비스 이용약관
      </h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제1조 (목적)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          이 약관은 주식회사 커넥션스튜디오(이하 ‘회사’)가 운영 중인 CastingVote
          서비스(이하 ‘서비스’)에서 제공되는 각종 유료 서비스의 이용조건 및
          절차에 관한 사항을 규정함을 목적으로 합니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제2조 (약관의 게시와 개정)
        </h2>
        <p className="text-gray-600 leading-relaxed mb-4">
          ① 회사는 이 약관을 회원이 그 전부를 인쇄 또는 확인할 수 있도록 기술적
          조치를 취합니다.
          <br />
          ② 회사는 회원이 약관에 동의하기에 앞서 약관에 정해져 있는 내용 중
          청약철회 등과 같은 중요한 내용을 회원이 쉽게 이해할 수 있도록 별도의
          연결화면 또는 팝업화면 등을 제공합니다.
          <br />
          ③ 회사는 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수
          있습니다.
          <br />
          ④ 회사가 약관을 개정할 경우, 적용일자 및 개정사유를 명시하여
          공지합니다. 다만, 회원에게 불리한 약관의 개정 시에는 30일 전부터
          명확히 통지합니다.
          <br />⑤ 회원이 개정 약관에 동의하지 않을 경우, 회사는 이용계약을
          해지할 수 있습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제3조 (약관의 해석)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          이 약관에서 정하지 아니한 사항과 해석에 관하여는 관계법령, ‘캐스팅
          보트 이용약관’ 및 상관례에 따릅니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제4조 (회원에 대한 통지)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          ① 회사는 회원에 대한 통지를 서비스 내 전자우편, 전자쪽지 등으로 할 수
          있습니다.
          <br />② 회원 전체에 대한 통지는 7일 이상 회사의 게시판에 게시함으로써
          갈음할 수 있습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제5조 (유료서비스의 내용 등의 게시)
        </h2>
        <ul className="list-disc list-inside text-gray-600">
          <li>유료서비스의 명칭, 종류 및 내용</li>
          <li>유료서비스의 가격과 그 지급방법 및 시기</li>
          <li>유료서비스의 공급방법, 시기 및 제공기간</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제6조 (이용계약의 성립 등)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          ① 회원은 유료서비스의 확인 및 선택, 결제방법의 선택 등을 통해
          이용신청을 합니다.
          <br />② 회사는 회원의 이용신청이 허위 정보, 실명이 아닌 경우 등을
          이유로 승낙하지 않을 수 있습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제7조 (유료서비스의 중단 및 변경)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 사업 전환, 기술상의 이유 등으로 유료서비스를 중단할 경우,
          회원에게 통지하고 보상합니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제8조 (회원의 청약철회 및 계약해지)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회원은 일정 기간 내 청약을 철회할 수 있으며, 회사는 환불 절차를
          진행합니다. 다만, 멤버십 구독은 환불되지 않습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제9조 (회사의 계약해제, 해지 및 이용제한)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 회원이 금지행위를 할 경우 사전 통지 없이 계약을 해제하거나
          이용을 제한할 수 있습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제10조 (사용기간 등)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          멤버십 구독의 사용기간은 구매일로부터 1개월이며, 프로필 보내기
          이용권은 사전에 표시된 기간 동안 사용 가능합니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제11조 (유료서비스 하자 등에 의한 회원피해보상)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 유료서비스의 하자에 따른 피해보상을 관련 지침에 따라
          처리합니다.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제12조 (책임 제한)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 천재지변 등 불가항력적 사유로 서비스를 제공할 수 없는 경우,
          책임이 면제됩니다.
        </p>
      </section>

      <p className="text-gray-500 text-sm mt-8">
        [부칙] 이 약관은 2025년 1월 1일부터 적용됩니다.
      </p>
    </div>
  );
};

export default PrivatePayment;
