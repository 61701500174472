import { AxiosError, AxiosResponse } from "axios";
import { Toast } from "hooks/useToast";
import { useMutation, useQuery } from "react-query";
import { ERROR } from "type/common";
import {
  DeleteFavoriteResponse,
  FavoriteCastingCallsResponse,
  FavoriteProductionsResponse,
  FavoriteResponse,
  GetFavoriteProfilesResponse,
} from "type/favorite";
import requestToCastingVote from "utils/requestToCastingVote";

// 프로필 즐겨찾기
const addFavoriteProfile = (
  profileId: number
): Promise<AxiosResponse<FavoriteResponse>> => {
  return requestToCastingVote.post("/favorites/profile", {
    profileId,
  });
};
export const useAddFavoriteProfile = () => {
  return useMutation((profileId: number) => addFavoriteProfile(profileId), {
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};

// 프로필 즐겨찾기 삭제
const deleteFavoriteProfile = (
  favoriteId: number
): Promise<AxiosResponse<DeleteFavoriteResponse>> => {
  return requestToCastingVote.delete("/favorites/profile", {
    data: { favoriteId },
  });
};
export const useDeleteFavoriteProfile = () => {
  return useMutation(
    (favoriteId: number) => deleteFavoriteProfile(favoriteId),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

//   작품 즐겨찾기
const addFavoriteProduction = (
  productionId: number
): Promise<AxiosResponse<FavoriteResponse>> => {
  return requestToCastingVote.post("/favorites/production", {
    productionId,
  });
};
export const useAddFavoriteProduction = () => {
  return useMutation(
    (productionId: number) => addFavoriteProduction(productionId),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 작품 즐겨찾기 삭제
const removeFavoriteProduction = (
  favoriteId: number
): Promise<AxiosResponse<DeleteFavoriteResponse>> => {
  return requestToCastingVote.delete("/favorites/production", {
    data: {
      favoriteId,
    },
  });
};
export const useRemoveFavoriteProduction = () => {
  return useMutation(
    (favoriteId: number) => removeFavoriteProduction(favoriteId),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 공고 즐겨찾기 추가
const addFavoriteCastingCall = (
  castingCallId: number
): Promise<AxiosResponse<FavoriteResponse>> => {
  return requestToCastingVote.post("/favorites/castingcall", {
    castingCallId,
  });
};
export const useAddFavoriteCastingCall = () => {
  return useMutation(
    (castingCallId: number) => addFavoriteCastingCall(castingCallId),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 공고 즐겨찾기 삭제
const removeFavoriteCastingCall = (
  favoriteId: number
): Promise<AxiosResponse<DeleteFavoriteResponse>> => {
  return requestToCastingVote.delete("/favorites/castingcall", {
    data: {
      favoriteId,
    },
  });
};
export const useRemoveFavoriteCastingCall = () => {
  return useMutation(
    (favoriteId: number) => removeFavoriteCastingCall(favoriteId),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 프로필 즐겨찾기 조회
const getFavoriteProfiles = (
  page: number = 0,
  size: number = 10
): Promise<AxiosResponse<GetFavoriteProfilesResponse>> => {
  return requestToCastingVote.get(`/favorites/profile`, {
    params: {
      page,
      size,
    },
  });
};
export const useFavoriteProfiles = (page: number, size: number) => {
  return useQuery(
    ["favoriteProfiles", page, size],
    () => getFavoriteProfiles(page, size),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 작품 즐겨찾기 조회
const fetchFavoriteProductions = (
  page: number,
  size: number
): Promise<FavoriteProductionsResponse> => {
  return requestToCastingVote.get(`/favorites/production`, {
    params: { page, size },
  });
};
export const useFavoriteProductions = (page: number = 0, size: number = 10) => {
  return useQuery(
    ["favoriteProductions", page, size],
    () => fetchFavoriteProductions(page, size),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

//   공고 즐겨찾기 조회
const fetchFavoriteCastingCalls = async (
  page: number = 0,
  size: number = 10
) => {
  return requestToCastingVote.get<FavoriteCastingCallsResponse>(
    `/favorites/castingcall?page=${page}&size=${size}`
  );
};

export const useFavoriteCastingCalls = (
  page: number = 0,
  size: number = 10
) => {
  return useQuery(
    ["favoriteCastingCalls", page, size],
    () => fetchFavoriteCastingCalls(page, size),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};
