import ArtistListCard from "components/ArtistListCard";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import ReactVirtuosoGrid from "components/ReactVirtuosoGrid";
import { useMemo, useCallback } from "react";
import { useNavigate } from "react-router";
import { splitBirth } from "utils/onBirth";
import EmptyStateImage from "../assets/icon_nolist.png";
import { useGetManageArtistProfileList } from "api/Manage";
import { Profile } from "type/common";
import { FillButton } from "components/FillButton";

const ManageArtist = () => {
  const navigate = useNavigate();
  const {
    data: artists,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useGetManageArtistProfileList({});

  const allLists = useMemo(
    () =>
      artists ? artists?.pages?.flatMap((artist) => artist.data.content) : [],
    [artists]
  );

  const loadMore = useCallback(async () => {
    if (!isFetchingNextPage && hasNextPage) {
      await fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  if (isLoading) return null;

  return (
    <div>
      <HeaderWithBackButton title="아티스트 관리">
        <div
          onClick={() => navigate("/manage/artist/profile")}
          className="text-Blue04 BCaption14 cursor-pointer"
        >
          등록
        </div>
      </HeaderWithBackButton>
      <div className="relative">
        {allLists.length === 0 ? (
          <div className="flex flex-col items-center text-center mt-24">
            <img
              src={EmptyStateImage}
              alt="빈 상태 이미지"
              className="w-16 h-16 mb-4"
            />
            <p className="text-Gray05 MBody16 mb-10">
              아직 프로필이 없어요.
              <br />
              아티스트를 등록하고 캐스팅보트를 시작해보세요.
            </p>
            <FillButton
              onClick={() => navigate("/manage/artist/profile")}
              className="px-4 py-3 bg-Blue04 text-white MBody16 rounded-[6px]"
              variant="Membership"
              size="L"
              text="아티스트 등록"
            />
          </div>
        ) : (
          <div className="pt-5 pb-16">
            <ReactVirtuosoGrid
              isLoading={isLoading}
              useWindowScroll
              data={isLoading ? Array.from({ length: 1 }) : allLists}
              overscan={100}
              hasNextPage={hasNextPage}
              listClassName="grid grid-cols-3 gap-y-4 gap-x-2 px-5"
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={loadMore}
              itemContent={(index, item: Profile) => (
                <ArtistListCard
                  key={item.profileId}
                  onClick={() =>
                    navigate(`/manage/artist/detail/${item.profileId}`)}
                  title={item?.profileName}
                  src={item?.thumbnailUrl}
                  subTitle={`${splitBirth(item.birthDate!)}`}
                />
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageArtist;
