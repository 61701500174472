import { AxiosError } from "axios";
import { Toast } from "hooks/useToast";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { StaffProfileUpdateRequest } from "type/artist";
import { Data, ERROR } from "type/common";
import { CreateStaffProfile } from "type/staff";
import { MEMBER_APPROVED } from "utils/onRoleStatus";
import requestToCastingVote from "utils/requestToCastingVote";

interface ReturnResponse {
  code: string;
  status: string;
  message: string;
  data: number | null;
}

// 매니저 아티스트 프로필 등록
interface ManageArtistProfile {
  name: string;
  job: string;
  gender: string;
  birthDate: string;
  zonecode?: string;
  roadAddress?: string;
  jibunAddress?: string;
  sido?: string;
  sigungu?: string;
  height: number;
  weight: number;
  shoeSize?: number;
  bust?: number;
  waist?: number;
  hip?: number;
  education?: string;
  rank: string;
  salary?: number;
  agency?: string;
  introduce?: string;
  instagram?: string;
  youtube?: string;
  facebook?: string;
  twitter?: string;
  tiktok?: string;
  linkedin?: string;
  hashtags?: string[];
  hobbies?: string[];
  jobs?: string[];
  filmographies?: {
    id?: number;
    title?: string;
    type?: string;
    role?: string;
    releaseYear?: number | null;
    description?: string;
    orderNumber?: number;
  }[];
  attachments?: {
    id: number;
    type?: string;
    isNew?: boolean | undefined;
    orderNumber?: number;
    url?: string;
  }[];
  videoLinks: {
    id?: number;
    link: string;
    type?: string;
    orderNumber?: number;
  }[];
}

const postManageArtistAddProfile = async (
  payload: ManageArtistProfile
): Promise<ReturnResponse> => {
  const { data } = await requestToCastingVote.post<ReturnResponse>(
    "manage-profiles",
    payload
  );

  return data;
};

const usePostManageArtistAddProfile = (): UseMutationResult<
  ReturnResponse,
  AxiosError<ERROR>,
  ManageArtistProfile
> => {
  const mutation = useMutation<
    ReturnResponse,
    AxiosError<ERROR>,
    ManageArtistProfile
  >(["postManageArtistAddProfile"], {
    mutationFn: postManageArtistAddProfile,
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });

  return mutation;
};

// 매니저 아티스트 프로필 수정
interface ManageArtistEditProfile {
  profileId: number;
  name?: string;
  job: string;
  jobs: string[];
  gender?: string;
  birthDate?: string;
  zonecode?: string | null;
  roadAddress?: string | null;
  jibunAddress?: string | null;
  sido?: string | null;
  sigungu?: string | null;
  height?: number;
  weight?: number;
  shoeSize?: number;
  bust?: number;
  waist?: number;
  hip?: number;
  education?: string;
  rank?: string;
  salary?: number;
  agency?: string;
  introduce?: string;
  instagram?: string;
  youtube?: string;
  facebook?: string;
  twitter?: string;
  tiktok?: string;
  linkedin?: string;
  addHashtags?: string[];
  removeHashtagIds?: [];
  addHobbies?: any;
  removeHobbyIds?: number[];
  addJobs?: string[];
  removeJobs?: string[];
  addFilmographies?: {
    title: string;
    type: string;
    role: string;
    releaseYear: number;
    description: string;
    orderNumber: number;
  }[];
  removeFilmographyIds?: number[];
  addAttachments?: {
    id: number;
    type: string;
    orderNumber: number;
  }[];
  removeAttachments?: number[];
  addVideoLinks?: {
    id?: number;
    isNew?: boolean;
    link?: string;
    type?: string;
    orderNumber?: number;
  }[];
  removeVideoLinks: number[];
}

const patchManageArtistEditProfile = async (
  payload: ManageArtistEditProfile
): Promise<ReturnResponse> => {
  const { data } = await requestToCastingVote.patch<ReturnResponse>(
    "/manage-profiles",
    payload
  );

  return data;
};

const usePatchManageArtistEditProfile = (): UseMutationResult<
  ReturnResponse,
  AxiosError<ERROR>,
  ManageArtistEditProfile
> => {
  const mutation = useMutation<
    ReturnResponse,
    AxiosError<ERROR>,
    ManageArtistEditProfile
  >(["postManageArtistAddProfile"], {
    mutationFn: patchManageArtistEditProfile,
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });

  return mutation;
};

// 매니저 아티스트 프로필 삭제

const deleteManageArtistProfile = async ({
  profileId,
}: {
  profileId: number;
}): Promise<ReturnResponse> => {
  const { data } = await requestToCastingVote.delete<ReturnResponse>(
    "manage-profiles",
    {
      data: { profileId },
    }
  );

  return data;
};

const useDeleteManageArtistProfile = (): UseMutationResult<
  ReturnResponse,
  AxiosError<ERROR>,
  { profileId: number }
> => {
  const mutation = useMutation<
    ReturnResponse,
    AxiosError<ERROR>,
    { profileId: number }
  >(["postManageArtistAddProfile"], {
    mutationFn: deleteManageArtistProfile,
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });

  return mutation;
};

// 매니저 아티스트 프로필 리스트
interface ManageArtistProfileList {
  name?: string;
  page?: number | string;
  size?: number | string;
}

interface ManageArtistProfileListResponse extends Omit<ReturnResponse, "data"> {
  data: Data;
}

const getManageArtistProfileList = async (
  payload: ManageArtistProfileList
): Promise<ManageArtistProfileListResponse> => {
  const { data } =
    await requestToCastingVote.get<ManageArtistProfileListResponse>(
      "manage-profiles",
      {
        params: payload,
      }
    );

  return data;
};
const useGetManageArtistProfileList = (
  params: ManageArtistProfileList
): UseInfiniteQueryResult<
  ManageArtistProfileListResponse,
  AxiosError<ERROR>
> => {
  const isApproved = MEMBER_APPROVED();

  return useInfiniteQuery(
    ["getManagerArtistProfileList", params],
    ({ pageParam = 0 }) =>
      getManageArtistProfileList({
        ...params,
        page: pageParam,
        size: 20,
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      enabled: isApproved,
      keepPreviousData: true,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 매니저 아티스트 프로필 리스트
interface ManagerArtistProfileList {
  name?: string;
  managerId: number;
  isManager?: boolean;
  page?: number | string;
  size?: number | string;
}
// 매니저가 관리하는 아티스트 프로필 조회
const getManagerArtistProfileList = async (
  payload: ManagerArtistProfileList
): Promise<ManageArtistProfileListResponse> => {
  const { data } =
    await requestToCastingVote.get<ManageArtistProfileListResponse>(
      `/profiles/${payload.managerId}/manage`,
      {
        params: payload,
      }
    );

  return data;
};
const useGetManagerArtistProfileList = (
  params: ManagerArtistProfileList
): UseInfiniteQueryResult<ManageArtistProfileListResponse, AxiosError<ERROR>> =>
  useInfiniteQuery(
    ["getManageArtistProfileList", params],
    ({ pageParam = 0 }) =>
      getManagerArtistProfileList({
        ...params,
        page: pageParam,
        size: 20,
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      enabled: !!params?.managerId && !!params?.isManager,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );

// 매니저 아티스트 프로필 상세
interface ManageArtistProfileDetail extends Omit<ReturnResponse, "data"> {
  data: {
    profileId: number;
    thumbnailUrl: string;
    profileName: string;
    gender: {
      code: string;
      label: string;
    };
    birthDate: string;
    basicInfo: {
      job: {
        code: string;
        label: string;
      };
      address: {
        zonecode: string;
        roadAddress: string;
        jibunAddress: string;
        sido: string;
        sigungu: string;
      };
      height: number;
      weight: number;
      shoeSize: number;
      threeSize: {
        bust: number;
        waist: number;
        hip: number;
      };
      education: string;
      agency: string;
    };
    rank: {
      code: string;
      label: string;
    };
    salary: number;
    introduce: string;
    snsInfo: {
      instagram: string;
      youtube: string;
      facebook: string;
      twitter: string;
      tiktok: string;
      linkedin: string;
    };
    hashtags: [
      {
        id: number;
        name: string;
      },
      {
        id: number;
        name: string;
      }
    ];
    hobbies: {
      id: number;
      name: string;
    }[];
    jobs: string[];
    filmographies: {
      id: number;
      title: string;
      type: {
        code: string;
        label: string;
      };
      role: {
        code: string;
        label: string;
      };
      releaseYear: string;
      description: string;
      orderNumber: number;
    }[];
    attachments: [
      {
        id: number;
        type: {
          code: string;
          label: string;
        };
        url: string;
        orderNumber: number;
      },
      {
        id: number;
        type: {
          code: string;
          label: string;
        };
        url: string;
        orderNumber: number;
      },
      {
        id: number;
        type: {
          code: string;
          label: string;
        };
        url: string;
        orderNumber: number;
      }
    ];
    videoLinks: [
      {
        id: number;
        link: string;
        type: {
          code: string;
          label: string;
        };
        orderNumber: number;
      },
      {
        id: number;
        link: string;
        type: {
          code: string;
          label: string;
        };
        orderNumber: number;
      },
      {
        id: number;
        link: string;
        type: {
          code: string;
          label: string;
        };
        orderNumber: number;
      }
    ];
  };
}

const getManageArtistProfileDetail = async (
  id: number
): Promise<ManageArtistProfileDetail> => {
  const { data } = await requestToCastingVote.get<ManageArtistProfileDetail>(
    `manage-profiles/${id}`
  );

  return data;
};
const useGetManageArtistProfileDetail = (
  id: number
): UseQueryResult<ManageArtistProfileDetail, AxiosError<ERROR>> =>
  useQuery(
    ["getManageArtistProfileDetail", id],
    () => getManageArtistProfileDetail(id),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );

// 매니저 프로필 등록
const postManagerProfile = async (
  payload: CreateStaffProfile
): Promise<ReturnResponse> => {
  const { data } = await requestToCastingVote.post<ReturnResponse>(
    "/profiles/staff",
    payload
  );

  return data;
};
const usePostManagerProfile = (): UseMutationResult<
  ReturnResponse,
  AxiosError<ERROR>,
  CreateStaffProfile
> => {
  const mutation = useMutation<
    ReturnResponse,
    AxiosError<ERROR>,
    CreateStaffProfile
  >(["postManagerProfile"], {
    mutationFn: postManagerProfile,
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });

  return mutation;
};

// 매니저 프로필 수정
const patchManagerProfile = async (
  payload: StaffProfileUpdateRequest
): Promise<ReturnResponse> => {
  const { data } = await requestToCastingVote.patch<ReturnResponse>(
    "/profiles/staff",
    payload
  );

  return data;
};

const usePatchManagerProfile = (): UseMutationResult<
  ReturnResponse,
  AxiosError<ERROR>,
  StaffProfileUpdateRequest
> => {
  const mutation = useMutation<
    ReturnResponse,
    AxiosError<ERROR>,
    StaffProfileUpdateRequest
  >(["patchManagerProfile"], {
    mutationFn: patchManagerProfile,
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });

  return mutation;
};

// 매니저 받은 제안 목록
interface ManageOffers {
  status?: "OFFERED" | "ACCEPTED" | "REJECTED" | "CLOSED";
  profileId?: string | number | null;
  page?: number | string;
  size?: number | string;
}

interface ManageOffersContent {
  castingOfferId: number;
  description: string;
  deadlineDate: string;
  createdAt: string;
  readAt: string;
  acceptedAt: string;
  rejectedAt: string;
  status: {
    code: string;
    label: string;
  };
  castingCallId: number;
  castingCallTitle: string;
  staffId: number;
  staffName: string;
  staffRole: {
    code: string;
    label: string;
  };
  staffAgency: string;
  staffThumbnailUrl: string;
  profileId: number;
  profileName: string;
  thumbnailUrl: string;
}

interface ManageOffersResponse extends Omit<ReturnResponse, "data"> {
  data: {
    content: ManageOffersContent[];
    pageable: {
      pageNumber: number;
      pageSize: number;
      sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
      };
      offset: number;
      paged: boolean;
      unpaged: boolean;
    };
    size: number;
    number: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    first: boolean;
    last: boolean;
    numberOfElements: number;
    empty: boolean;
  };
}

const getManageOffersReceived = async (
  payload: ManageOffers
): Promise<ManageOffersResponse> => {
  const { data } = await requestToCastingVote.get<ManageOffersResponse>(
    "offers/received",
    {
      params: {
        ...payload,
      },
    }
  );

  return data;
};

const useGetManageOffersReceived = (
  payload: ManageOffers
): UseInfiniteQueryResult<ManageOffersResponse, AxiosError<ERROR>> =>
  useInfiniteQuery(
    ["getManageOffersReceived"],
    ({ pageParam = 0 }) =>
      getManageOffersReceived({
        page: pageParam,
        size: 20,
        ...payload,
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      keepPreviousData: true,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );

const getManageOffersSend = async (
  payload: ManageOffers
): Promise<ManageOffersResponse> => {
  const { data } = await requestToCastingVote.get<ManageOffersResponse>(
    "offers/sent",
    {
      params: {
        ...payload,
      },
    }
  );

  return data;
};

const useGetManageOffersSend = (
  payload: ManageOffers
): UseInfiniteQueryResult<ManageOffersResponse, AxiosError<ERROR>> =>
  useInfiniteQuery(
    ["getManageOffersSend"],
    ({ pageParam = 0 }) =>
      getManageOffersSend({
        page: pageParam,
        size: 20,
        ...payload,
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      keepPreviousData: true,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );

interface ManageOffersSendProfile {
  status?:
    | "DELIVERED"
    | "CANCELED"
    | "READ"
    | "FEEDBACK"
    | "REFUND_REQUEST"
    | "REFUNDED";
  profileId?: string | number | null;
  type?: "DELIVERY" | "PREMIUM_DELIVERY" | "CASTING";
  page?: number | string;
  size?: number | string;
}
interface ManageOffersSendProfileContent {
  castingCallId: number;
  castingCallTitle: string;
  createdAt: string;
  deliveryProfileId: number;
  staffId: number;
  staffName: string;
  profileName: string;
  thumbnailUrl: string;
  status: {
    code: string;
    label: string;
  };
  type: {
    code: "DELIVERY" | "PREMIUM_DELIVERY" | "CASTING";
    label: string;
  };
  readAt: string;
  readFeedbackAt: string;
  feedbackAt: string;
  isOffered: boolean;
}
interface ManageOffersSendProfileResponse extends Omit<ReturnResponse, "data"> {
  data: {
    content: ManageOffersSendProfileContent[];
    pageable: {
      pageNumber: number;
      pageSize: number;
      sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
      };
      offset: number;
      paged: boolean;
      unpaged: boolean;
    };
    size: number;
    number: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    first: boolean;
    last: boolean;
    numberOfElements: number;
    empty: boolean;
  };
}
const getManageOffersSendProfile = async (
  payload: ManageOffersSendProfile
): Promise<ManageOffersSendProfileResponse> => {
  const { data } =
    await requestToCastingVote.get<ManageOffersSendProfileResponse>(
      "/delivery-profiles/sent",
      {
        params: {
          ...payload,
        },
      }
    );

  return data;
};

const useGetManageOffersSendProfile = (
  payload: ManageOffersSendProfile
): UseInfiniteQueryResult<ManageOffersSendProfileResponse, AxiosError<ERROR>> =>
  useInfiniteQuery(
    ["getManageOffersSend", payload],
    ({ pageParam = 0 }) =>
      getManageOffersSendProfile({
        page: pageParam,
        size: 20,
        ...payload,
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      keepPreviousData: true,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );

interface ManageOffersSendProfileDetail extends Omit<ReturnResponse, "data"> {
  data: {
    deliveryProfileId: number;
    title: string;
    description: string;
    rating: number;
    comments: string;
    createdAt: string;
    readAt: string;
    feedbackAt: string;
    isOffered: boolean;
    status: {
      code: string;
      label: string;
    };
    productionId: number;
    productionFormat: {
      code: string;
      label: string;
    };
    directorName: string;
    companyName: string;
    castingCallId: number;
    castingCallTitle: string;
    shootingDetails: {
      location: string;
      startDate: string;
      endDate: string;
      role: {
        code: string;
        label: string;
      };
      episodeInfo: string;
      minAge: number;
      maxAge: number;
      ageType: {
        code: string;
        label: string;
      };
      gender: {
        code: string;
        label: string;
      };
      numberOfPositions: number;
      fee: number;
    };
    staffThumbnailUrl?: string;
    castingId: number;
    castingStatus: {
      code: string;
      label: string;
    };
    staffId: number;
    staffName: string;
  };
}
// 보낸 프로필 상세 조회
const getManageOffersSendProfileDetail = async (
  id: number
): Promise<ManageOffersSendProfileDetail> => {
  const { data } =
    await requestToCastingVote.get<ManageOffersSendProfileDetail>(
      `/delivery-profiles/${id}/sent`
    );

  return data;
};
const useGetManageOffersSendProfileDetail = (
  id: number
): UseQueryResult<ManageOffersSendProfileDetail, AxiosError<ERROR>> =>
  useQuery(
    ["getManageOffersSendProfileDetail", id],
    () => getManageOffersSendProfileDetail(id),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );

// const patchManageOffersSendProfileRead = async ({ profileId }: { profileId: number }): Promise<ReturnResponse> => {
// 	const { data } = await requestToCastingVote.patch<ReturnResponse>(`/delivery-profiles/${profileId}/read`);

// 	return data;
// };

// const usePatchManageOffersSendProfileRead = (): UseMutationResult<
// 	ReturnResponse,
// 	AxiosError<ERROR>,
// 	{ profileId: number }
// > => {
// 	const mutation = useMutation<ReturnResponse, AxiosError<ERROR>, { profileId: number }>(
// 		["patchManageOffersSendProfileRead"],
// 		{
// 			mutationFn: patchManageOffersSendProfileRead,
// 			onError: (error: AxiosError<ERROR>) => {
// 				Toast.error(error.response?.data.message);
// 			},
// 		}
// 	);

// 	return mutation;
// };

export {
  postManageArtistAddProfile,
  usePostManageArtistAddProfile,
  patchManageArtistEditProfile,
  usePatchManageArtistEditProfile,
  deleteManageArtistProfile,
  useDeleteManageArtistProfile,
  getManageArtistProfileList,
  useGetManageArtistProfileList,
  getManageArtistProfileDetail,
  useGetManageArtistProfileDetail,
  usePostManagerProfile,
  usePatchManagerProfile,
  useGetManageOffersReceived,
  useGetManageOffersSend,
  useGetManageOffersSendProfile,
  // usePatchManageOffersSendProfileRead,
  useGetManageOffersSendProfileDetail,
  useGetManagerArtistProfileList,
};

export type {
  ManageArtistProfile,
  ManageArtistEditProfile,
  ManageArtistProfileList,
  ManageArtistProfileDetail,
  ManageArtistProfileListResponse,
  ReturnResponse,
  ManageOffersSendProfile,
  ManageOffersSendProfileContent,
  ManageOffersSendProfileDetail,
};
