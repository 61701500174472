import { useQuery } from "react-query";
import requestToCastingVote from "utils/requestToCastingVote";

// 공유하기 링크 요청
const fetchShareLink = async (
  params: ShareRequestParams
): Promise<ShareResponse> => {
  const { id, type, period } = params;
  const response = await requestToCastingVote.get<ShareResponse>(
    `/share/request`,
    { params: { id, type, period } }
  );
  return response.data;
};
export const useShareLink = (params: ShareRequestParams) => {
  return useQuery(["shareLink", params], () => fetchShareLink(params), {
    enabled: !!params.id,
  });
};

const fetchProfileShare = async (value: string): Promise<ProfileShareData> => {
  const response = await requestToCastingVote.get<ApiResponse>(`/share`, {
    params: { value },
  });
  return response.data.data;
};

export const useProfileShare = (value: string) => {
  return useQuery(["profileShare", value], () => fetchProfileShare(value), {
    enabled: !!value,
  });
};

export const fetchSharedReceivedProfile = async (
  value: string
): Promise<ProfileShareData> => {
  const response = await requestToCastingVote.get<ApiResponse>("/share", {
    params: { value },
  });
  return response.data.data;
};

interface ShareRequestParams {
  id: number | null;
  type: "PROFILE" | "DELIVERY_PROFILE";
  period: "DAY" | "WEEK" | "MONTH";
}

interface ShareResponse {
  code: string;
  status: string;
  message: string;
  data: {
    value: string;
    expiredAt: string;
  };
}

interface ShareRequestParams {
  id: number | null;
  type: "PROFILE" | "DELIVERY_PROFILE";
  period: "DAY" | "WEEK" | "MONTH";
}

interface ShareResponse {
  code: string;
  status: string;
  message: string;
  data: {
    value: string;
    expiredAt: string;
  };
}

interface ProfileShareData {
  expiredAt: string;
  profileId: number;
  thumbnailUrl: string;
  jobs: string[];
  profileName: string;
  gender: {
    code: string;
    label: string;
  };
  birthDate: string;
  basicInfo: {
    job: {
      code: string;
      label: string;
    };
    address: {
      zonecode: string;
      roadAddress: string;
      jibunAddress: string;
      sido: string;
      sigungu: string;
    };
    height: number;
    weight: number;
    shoeSize: number;
    threeSize: {
      bust: number;
      waist: number;
      hip: number;
    };
    education: string;
    agency: string;
  };
  rank: {
    code: string;
    label: string;
  };
  salary: number;
  introduce: string;
  snsInfo: {
    instagram?: string;
    youtube?: string;
    facebook?: string;
    twitter?: string;
    tiktok?: string;
    linkedin?: string;
  };
  hashtags: {
    id: number;
    name: string;
  }[];
  hobbies: {
    id: number;
    name: string;
  }[];
  filmographies: {
    id: number;
    title: string;
    type: {
      code: string;
      label: string;
    };
    role: {
      code: string;
      label: string;
    };
    releaseYear: string;
    description: string;
    orderNumber: number;
  }[];
  attachments: {
    id: number;
    type: {
      code: string;
      label: string;
    };
    url: string;
    orderNumber: number;
  }[];
  videoLinks: {
    id: number;
    link: string;
    type: {
      code: string;
      label: string;
    };
    orderNumber: number;
  }[];
}

interface ApiResponse {
  code: string;
  status: string;
  message: string;
  data: ProfileShareData;
}
