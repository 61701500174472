import React from "react";

const MarketingOptIn = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white rounded-lg">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
        마케팅 수신 동의
      </h1>

      <section className="mb-8">
        <article className="mb-6">
          <p className="text-gray-600 leading-relaxed">
            회사는 상품 및 이벤트 정보 안내, 마케팅 및 홍보를 목적으로 다음의
            개인정보를 수집 및 이용하고자 합니다. 상품 및 이벤트 정보 안내,
            마케팅 및 홍보에 활용을 원하지 않을 경우 동의하지 않으셔도 됩니다.
          </p>
        </article>

        <article className="mb-6">
          <table className="table-auto border-collapse border">
            <thead>
              <tr>
                <th className="border">수집 및 이용 목적</th>
                <th className="border">수집항목</th>
                <th className="border">보유 및 이용기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border">
                  상품 및 이벤트 정보 안내, 마케팅 및 홍보
                </td>
                <td className="border">이름, 연락처, 이메일</td>
                <td className="border">탈퇴 또는 회원의 삭제 요청시까지</td>
              </tr>
              <tr>
                <td colSpan={3} className="border">
                  마케팅 발신은 알림톡, 문자메시지 또는 이메일로 될 수 있습니다.
                </td>
              </tr>
            </tbody>
          </table>
        </article>

        <article className="mb-6">
          <p className="text-gray-600 leading-relaxed">
            위의 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다.
            그러나 동의를 거부할 경우 상품 및 이벤트 정보 안내, 마케팅 및 홍보를
            받을 수 없습니다.
          </p>
        </article>
      </section>
    </div>
  );
};

export default MarketingOptIn;
