import React from 'react';

interface ArtistDescProps {
    hobbies: {
        id: number;
        name: string;
    }[]
    address: string;
    education: string;
    agency: string;
    introduce: string;
}

const ArtistDesc = ({
    hobbies,
    address,
    education,
    agency,
    introduce
}: ArtistDescProps) => {
    const sections = [
        { id: 0, title: "특기", content: hobbies?.map((item) => item.name).join("/ ") || "" },
        { id: 1, title: "거주지", content: address || "" },
        { id: 2, title: "학력", content: education || "" },
        { id: 3, title: "소속사", content: agency || "" },
    ].filter((section) => section.content);

    return (
        <div className={`${sections.length > 0 && "border-y my-10 py-10 mx-5"}`}>
            <div className='flex flex-col gap-4'>
                {sections.map((section) => (
                    <div key={section.id}>
                        <div className="flex flex-col gap-2">
                            <div className="RCaption14 text-Gray05">{section.title}</div>
                            <div className="MBody16 text-Gray09 whitespace-pre-wrap">{section.content}</div>
                        </div>
                    </div>
                ))}
            </div>
            {introduce &&
                <div className={`flex flex-col gap-2 border-Gray03  ${sections.length > 0 ? "border-t pt-10 mt-10" : "border-y py-10 my-10"}`}>
                    <div className="RCaption14 text-Gray05">자기소개</div>
                    <div className="MBody16 text-Gray09 whitespace-pre-wrap">{introduce}</div>
                </div>
            }
        </div >
    );
};

export default ArtistDesc;