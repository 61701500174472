import React, { useState } from "react";
import TitleWrap from "components/TitleWrap";
import ImgUpload from "components/ImgUpload";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { Toast } from "hooks/useToast";
import { useUploadImagesMutation } from "api/Attachment/Attachment";
import ButtonBackground from "components/ButtonBackground";
import Textarea from "components/Textarea";
import { usePostManagerProfile } from "api/Manage";
import { CreateStaffProfile } from "type/staff";

const CreateManageProfile = () => {
  const navigate = useNavigate();

  const [managerData, setManagerData] = useState<CreateStaffProfile>({
    filmographies: [],
    attachments: [],
    filmographySummary: undefined,
    introduce: undefined,
  });

  const { mutate: uploadImages } = useUploadImagesMutation();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files as FileList;

    if (files.length === 0) {
      return;
    }

    uploadImages(files, {
      onSuccess: (res) => {
        Toast.success("업로드 성공했어요");
        const updateItem = res.data.data.map(
          (
            item: { attachmentId: number; attachmentUrl: string },
            idx: number
          ) => ({
            id: item.attachmentId,
            type: "THUMBNAIL",
            orderNumber: idx + 1,
            url: item.attachmentUrl,
          })
        );

        setManagerData({
          ...managerData,
          attachments: [...managerData.attachments, ...updateItem],
        });
      },
    });
  };

  const valid = (data: CreateStaffProfile) => {
    const hasThumbnail = data?.attachments!.some(
      (attachment) => attachment.type === "THUMBNAIL"
    );

    if (!hasThumbnail) {
      return "프로필 이미지는 필수입니다.";
    }

    return null;
  };

  const { mutate } = usePostManagerProfile();

  const handleSubmit = () => {
    const validError = valid(managerData);

    if (validError !== null) {
      Toast.error(validError);
      return;
    }

    mutate(managerData, {
      onSuccess: () => {
        Toast.success("프로필을 등록했어요");
        navigate("/manage/my", { replace: true });
      },
    });
  };

  const handleTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setManagerData({
      ...managerData,
      filmographySummary: e.target.value,
    });
  };

  return (
    <>
      <HeaderWithBackButton title="프로필 등록" />
      <div className="p-5 pb-24">
        <div className="flex items-start flex-col">
          <div className="mb-10">
            <TitleWrap
              subTitle="4:5 / 10MB 이하"
              title={"대표 프로필 사진 업로드"}
              require
            >
              {managerData?.attachments.length ? (
                <div className="relative w-[172px]">
                  <img
                    className="w-[172px] h-[215px] rounded-[10px] object-cover"
                    src={`${process.env.REACT_APP_MEDIA_URL}${managerData?.attachments[0].url}`}
                    alt="THUMBNAIL"
                  />
                  <XButton
                    onClick={() => {
                      setManagerData({ ...managerData, attachments: [] });
                    }}
                    className="absolute cursor-pointer top-4 right-4"
                  />
                </div>
              ) : (
                <ImgUpload
                  onChange={imgUpload}
                  title="프로필 사진 업로드"
                  accept="image/jpg,image/png,image/jpeg"
                  id={"profileImg"}
                />
              )}
              <div className="mt-4 rounded-[10px] bg-Gray01 p-4">
                <div className="RBody14 text-Gray05">
                  <div>이런 사진은 프로필 등록이 불가능해요.</div>
                  <ul>
                    <li>• 워터마크가 포함된 사진</li>
                    <li>• 과도하게 편집된 사진</li>
                    <li>• 화질이 너무 안 좋은 사진</li>
                    <li>• 다른 사람이 포함된 사진</li>
                    <li>• 담배나 술이 확인되는 사진</li>
                    <li>• 사회통념에 반하는 내용이 들어간 사진</li>
                    <li>
                      • 타인이나 특정 계층을 모욕하거나 비하•비방하는 내용의
                      사진
                    </li>
                  </ul>
                </div>
              </div>
            </TitleWrap>
          </div>

          <TitleWrap title={"경력"} require>
            <Textarea
              value={managerData?.filmographySummary}
              onChange={handleTextarea}
              placeholder="최대 150자"
              className="w-full h-[270px]"
              maxlength={150}
            />
          </TitleWrap>
        </div>
        <ButtonBackground>
          <Button
            onClick={handleSubmit}
            className="w-full h-12 bg-Blue04 text-white border-none"
            text={"등록하기"}
          />
        </ButtonBackground>
      </div>
    </>
  );
};

export default CreateManageProfile;
