import React, { useState } from "react";
import Skeleton from "./Skeleton";
import { ReactComponent as Membership } from "assets/chip-membership.svg";
import { ReactComponent as Checked } from "assets/checkedProfile.svg";
import { ReactComponent as Premium } from "assets/premium.svg";
import { ReactComponent as BookOn } from "assets/icon_bookmark_on.svg";
import { ReactComponent as BookOff } from "assets/icon_bookmark_off.svg";
import Chip from "./Chip";

interface ArtistProfileCardType {
  src?: string;
  title?: string;
  subTitle?: string;
  description?: string;

  isMembership?: boolean;
  isPremium?: boolean;
  selectMode?: boolean;
  selected?: boolean;
  isOffered?: boolean;
  onClick?: () => void;
}

const ArtistListCard = ({
  src,
  title,
  subTitle,
  description,
  selectMode,
  isMembership,

  selected,
  isOffered,
  isPremium,
  onClick,
}: ArtistProfileCardType) => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="relative">
      {isLoading && <Skeleton type="ARTIST_LIST_CARD" />}
      <div
        style={
          isPremium
            ? {
              background:
                "linear-gradient(to bottom, #AB8800, #FFE500, #AB8800, #B37700)",
              borderRadius: "6px",
            }
            : {}
        }
      >
        <div className="relative">
          {MEDIA_URL && (
            <img
              onClick={onClick}
              onLoad={(e) => {
                setIsLoading(false);
              }}
              src={`${MEDIA_URL}${src}`}
              alt="thumbnailUrl"
              className={`w-full aspect-4/5 ${isPremium && "p-1"
                } cursor-pointer relative object-cover rounded-md ${selectMode && selected ? "cursor-pointer" : ""
                }
          ${selectMode && selected ? "opacity-50" : ""}
          `}
            />
          )}
          {selectMode && selected && (
            <Checked className="absolute right-2 top-2 z-50" />
          )}
          {isOffered && (
            <Chip
              selected
              className="absolute bg-Blue01 h-[18px] bottom-1 left-1 !MCaption10 text-Blue04 border-none rounded-md"
              title={"제안"}
            />
          )}
          {isMembership && (
            <Membership
              className="absolute bottom-1 left-1"
            />
          )}
        </div>
      </div>
      {/* {isPremium && <Premium className="absolute left-2 top-2" />} */}
      {/* {!selectMode &&
      <BookOff className="absolute top-[115px] right-0" />
      } */}
      <div className="flex items-center gap-0.5 mt-2">
        <div className="BBody16 text-Gray09 max-w-[81px] text-ellipsis whitespace-nowrap overflow-hidden">
          {title}
        </div>
        {subTitle && <div className="MBody14 text-Gray05">{subTitle}</div>}
      </div>
      {description && (
        <div className="RBody14 text-Gray05 max-w-[114px] text-ellipsis overflow-hidden whitespace-nowrap">
          {description}
        </div>
      )}
    </div>
  );
};

export default ArtistListCard;
