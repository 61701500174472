import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { Outlet, useNavigate } from "react-router-dom";
import TabBar from "./TabBar";
import requestToCastingVote from "utils/requestToCastingVote";
import axios from "axios";

const Layout = () => {
  const navigate = useNavigate();

  requestToCastingVote.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  requestToCastingVote.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (!originalRequest?._retry) {
        originalRequest._retry = true;

        switch (error.response?.data.code) {
          case "ERR4001":
            localStorage.clear();
            navigate("/login?code=4001", { replace: true });
            return axios(originalRequest);
          // case "ERR4002":
          //   navigate("/login?code=4002", { replace: true });
          //   break;
          case "ERR4003":
            navigate("/", { replace: true });
            break;
          case "ERR4004":
            navigate("/membership", { replace: true });
            break;
          // case "ERR4005":
          //   navigate("/points", { replace: true });
          //   break;
          // case "ERR4006":
          //   navigate("/signup/kakao", { replace: true });
          //   break;
          // case "ERR4007":
          //   navigate("/login/kakao?code=4007", { replace: true });
          //   break;
          // case "ERR4008":
          //   navigate("/payment-failed", { replace: true });
          //   break;
          // case "ERR4009":
          //   navigate("/duplicate-phone", { replace: true });
          //   break;
          // case "ERR4010":
          //   navigate("/add-phone", { replace: true });
          //   break;
          // case "ERR4011":
          //   navigate("/invalid-phone", { replace: true });
          //   break;
          // case "ERR4012":
          // case "ERR4013":
          // case "ERR4014":
          // case "ERR4015":
          //   console.error(
          //     "Invalid date information:",
          //     error.response.data.code
          //   );
          //   break;
          // case "ERR4016":
          //   navigate("/multiple-accounts", { replace: true });
          //   break;
          // case "ERR5000":
          //   navigate("/server-error", { replace: true });
          //   break;
        }
      }

      return Promise.reject(error);
    }
  );

  return (
    <>
      <div className="w-full min-h-full bg-Gray00 shadow-[0_0px_20px_rgba(0,0,0,0.1)] relative min-w-[280px] max-w-[400px] mx-auto">
        <Outlet />
        <TabBar />
        <Footer />
      </div>
    </>
  );
};

export default Layout;
