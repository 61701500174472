import Button from "components/Button";
import DateInput from "components/DateInput";
import ImgUpload from "components/ImgUpload";
import Input from "components/Input";
import Radio from "components/Radio";
import TitleWrap from "components/TitleWrap";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, } from "react-router-dom";
import requestToCastingVote from "utils/requestToCastingVote";
import { today } from "utils/onToday";
import { encryptData } from "utils/crypto";
import { Toast } from "hooks/useToast";
import { useKakaoSignupMutation, } from "api/auth";
import { STAFF_JOB_LIST } from "utils/type";
import { AxiosError } from "axios";
import { ERROR } from "../type/common";
import { FillButton } from "components/FillButton";

const KakaoSignup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("");
  const [currentBizFile, setCurrnetBizFile] = useState({
    fileName: "",
    id: 0,
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [passCheck, setPassCheck] = useState("");
  const [data, setData] = useState({
    userId: "",
    password: "",
    name: "",
    phoneNumber: "",
    email: "",
    gender: "",
    birthDate: "",
    zonecode: "",
    roadAddress: "",
    jibunAddress: "",
    sido: "",
    sigungu: "",
    termsOfService: true,
    termsOfServiceAt: "",
    privacyPolicy: true,
    privacyPolicyAt: "",
    thirdPartySharing: true,
    thirdPartySharingAt: "",
    marketingOptIn: false,
    marketingOptInAt: "",
    recommendId: "",
    role: "",
    businessName: "",
    businessNumber: "",
    businessLicenseId: null,
    certNum: 0,
    oAuthType: "kakao",
    kakaoId: "",
  });

  const preDate = location.state;

  useEffect(() => {
    const agree = JSON.parse(localStorage.getItem("agree")!);

    setData({
      ...data,
      ...agree,
      name: location?.state?.userName,
      certNum: preDate?.certNum,
      birthDate: location?.state?.birthDate,
      gender: location?.state?.gender,
      phoneNumber: location?.state?.phoneNumber,
      oAuthType: "kakao",
      kakaoId: location?.state?.kakaoId,
      email: location?.state?.email,
    });
  }, [preDate]);

  const totalStep = 2;

  const selectType = (type: string) => {
    if (selectedType === type) {
      setSelectedType("");
    } else {
      setSelectedType(type);
    }
  };

  const getDots = () => {
    const dots = [];
    for (let i = 1; i <= totalStep; i++) {
      dots.push(i <= currentStep ? "blue" : "gray");
    }
    return dots;
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = (pageNum?: number) => {
    setCurrentStep(currentStep - 1);

    if (pageNum === 2) {
      setData((pre) => ({
        ...pre,
        role: "",
        businessName: "",
      }));
    } else {
      setPassCheck("");
      setData((pre) => ({
        ...pre,
        userId: "",
        password: "",
      }));
    }
  };

  const signupData = {
    ...data,
    password: encryptData(data?.password),
  };

  const { mutate, isLoading } = useKakaoSignupMutation(signupData);

  const fileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const formData = new FormData();
    if (files) {
      const fileRef = files[0];
      formData.append("file", fileRef);
      requestToCastingVote({
        method: "POST",
        url: "auth/upload",
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then((res) => {
          setData({
            ...data,
            businessLicenseId: res.data.data.attachmentId,
          });
          setCurrnetBizFile({
            id: res.data.data.attachmentId,
            fileName: fileRef.name,
          });
        })
        .catch((err) => { });
    }
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateInfo = () => {
    let isValid = true;

    if (!data.email) {
      Toast.error("이메일을 입력해주세요.");
      isValid = false;
    } else if (data.email.length > 50) {
      Toast.error("이메일은 최대 50자까지 입력 가능해요.");
      isValid = false;
    } else if (!emailRegex.test(data.email)) {
      Toast.error("이메일 형식을 확인해주세요.");
      isValid = false;
    } else if (!data.name) {
      Toast.error("이름이 없습니다. 고객센터로 문의해주세요");
      isValid = true;
    } else if (!data.birthDate) {
      Toast.error("생년월일이 없습니다. 고객센터로 문의해주세요");
      isValid = true;
    } else if (!data.gender) {
      Toast.error("성별이 없습니다. 고객센터로 문의해주세요");
      isValid = true;
    }

    return isValid;
  };

  const validateStaffInfo = () => {
    let isValid = true;

    if (!data.role) {
      Toast.error("직업을 선택해주세요.");
      isValid = false;
    } else if (!data.email) {
      Toast.error("이메일을 입력해주세요.");
      isValid = false;
    } else if (data.email.length > 50) {
      Toast.error("이메일은 최대 50자까지 입력 가능해요");
      isValid = false;
    } else if (!emailRegex.test(data.email)) {
      Toast.error("이메일 형식을 확인해주세요.");
      isValid = false;
    } else if (!data.birthDate) {
      Toast.error("생년월일을 입력해주세요.");
      isValid = false;
    } else if (!data.businessName) {
      Toast.error("소속회사를 입력해주세요.");
      isValid = false;
    }

    return isValid;
  };

  return (
    <div className="pb-24 px-5 pt-5 mx-auto flex flex-col max-w-[520px] justify-between gap-10">
      <div className="flex items-center gap-2">
        {getDots().map((color, i) => {
          return (
            <div
              key={i}
              className={`w-2 h-2 rounded-full ${color === "gray" ? "bg-Gray03" : "bg-Blue04"
                }`}
            />
          );
        })}
        <div className="w-5 h-6">{currentStep === 2 ? "🙌" : "🏃‍♀️‍"}</div>
      </div>

      {currentStep === 1 && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            시작하기 전에
            <br />
            직업을 선택해 주세요
          </div>
          <div className="flex flex-col gap-4 mt-10 mb-20">
            <Button
              onClick={() => {
                selectType("ARTIST");
                setData({
                  ...data,
                  role: "ARTIST",
                });
              }}
              className={`w-full border ${selectedType === "ARTIST"
                ? "text-Blue04 border-Blue04"
                : "text-Gray05 border-Gray05"
                }`}
              text={"아티스트"}
            ></Button>
            <Button
              onClick={() => {
                selectType("STAFF");
              }}
              className={`w-full border  ${selectedType === "STAFF"
                ? "text-Blue04 border-Blue04"
                : "text-Gray05 border-Gray05"
                }`}
              text={"Staff (캐스팅 디렉터, 감독, 프로듀서 등등)"}
            ></Button>
          </div>
          <Button
            onClick={() => {
              handleNextStep();
            }}
            disabled={!selectedType}
            className={`w-full bg-Blue04 text-white`}
            text={"다음"}
          ></Button>
        </div>
      )}
      {currentStep === 2 && selectedType === "ARTIST" && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            환영해요!
            <br />
            기본 정보를 입력해 주세요
          </div>
          <div className="flex flex-col gap-10 mt-10 mb-20">
            <TitleWrap require title="이메일">
              <Input
                value={data.email}
                onChange={(e) => {
                  const emailValue = e.target.value;
                  if (emailValue.length <= 50) {
                    setData({
                      ...data,
                      email: emailValue,
                    });
                  }
                }}
                disabled
                className="w-full"
                placeholder="이메일"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap require title="휴대폰번호">
              <Input
                value={data.phoneNumber}
                onChange={(e) => {
                  const phoneValue = e.target.value;
                  setData({
                    ...data,
                    phoneNumber: phoneValue,
                  });
                }}
                disabled
                className="w-full"
                placeholder="휴대폰번호"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap require title="이름">
              <Input
                value={data?.name}
                className="w-full"
                disabled
                placeholder="이름"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap require title="생년월일">
              <DateInput
                disabled
                placeholder="생년월일"
                min="1920-01-01"
                max={today()}
                value={data.birthDate}
                className="w-full"
              />
            </TitleWrap>
            <TitleWrap require title="성별">
              <Button
                className={`
                    w-full border border-Gray03 bg-Gray01 cursor-default
                    `}
                text={
                  data?.gender === "MALE"
                    ? "남성"
                    : data?.gender === "FEMALE"
                      ? "여성"
                      : ""
                }
              ></Button>
            </TitleWrap>
          </div>
          <div className="flex flex-col-reverse gap-4 w-full">
            <Button
              onClick={() => {
                handlePrevStep(1);
              }}
              className={`w-full border-none bg-Gray03 text-white`}
              text={"이전"}
            ></Button>
            <FillButton
              variant="Membership"
              isLoading={isLoading}
              size="B"
              onClick={() => {
                if (validateInfo()) {
                  mutate(signupData, {
                    onSuccess: () => {
                      Toast.success("회원가입을 완료했어요");
                      navigate("/login", { replace: true });
                    },
                    onError: (err: AxiosError<ERROR>) => {
                      Toast.error(err.response?.data.message);
                    },
                  });
                }
              }}
              className="w-full"
              text={"완료"}
            />
          </div>
        </div>
      )}
      {currentStep === 2 && selectedType === "STAFF" && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            환영해요!
            <br />
            기본 정보를 입력해 주세요
          </div>
          <div className="flex flex-col gap-10 mt-10 mb-20">
            <TitleWrap title="직업">
              <div className="flex items-center flex-wrap gap-5">
                {STAFF_JOB_LIST.map((item, i) => {
                  return (
                    <Radio
                      checked={item.role === data.role}
                      value={item?.role}
                      onChange={(e) => {
                        setData({
                          ...data,
                          role: e.target.value,
                        });
                      }}
                      key={item.id}
                      id={item.role}
                      name={item.name}
                      title={item.title}
                    />
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap require title="이메일">
              <Input
                value={data.email}
                onChange={(e) => {
                  setData({
                    ...data,
                    email: e.target.value,
                  });
                }}
                disabled
                placeholder="이메일"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap require title="휴대폰번호">
              <Input
                value={data.phoneNumber}
                onChange={(e) => {
                  const phoneValue = e.target.value;
                  setData({
                    ...data,
                    phoneNumber: phoneValue,
                  });
                }}
                disabled
                className="w-full"
                placeholder="휴대폰번호"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap require title="이름">
              <Input
                value={data.name}
                disabled
                placeholder="이름"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap require title="생년월일">
              <DateInput
                placeholder="생년월일"
                min="1920-01-01"
                max={today()}
                value={data.birthDate}
                disabled
                className="w-full"
              />
            </TitleWrap>
            <TitleWrap title="소속사">
              <Input
                value={data.businessName}
                onChange={(e) => {
                  setData({
                    ...data,
                    businessName: e.target.value,
                  });
                }}
                placeholder="소속회사"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="사업자등록증">
              <ImgUpload
                title="사업자 등록증 업로드"
                onChange={(e) => {
                  fileUpload(e);
                }}
                id={"bizFile"}
              ></ImgUpload>
              {currentBizFile && currentBizFile.fileName && (
                <div className="flex items-center justify-between">
                  <div>{currentBizFile?.fileName}</div>
                  <div
                    onClick={() => {
                      setCurrnetBizFile({
                        fileName: "",
                        id: 0,
                      });
                      setData({ ...data, businessLicenseId: null });
                    }}
                  >
                    X
                  </div>
                </div>
              )}
            </TitleWrap>
          </div>
          <div className="flex flex-col-reverse gap-4 w-full">
            <Button
              onClick={() => {
                handlePrevStep(2);
              }}
              className={`w-full border-none bg-Gray03 text-white`}
              text={"이전"}
            ></Button>
            <FillButton
              variant="Membership"
              size="B"
              isLoading={isLoading}
              onClick={() => {
                if (validateStaffInfo()) {
                  mutate(signupData, {
                    onSuccess: () => {
                      Toast.success("회원가입을 완료했어요");
                      navigate("/login", { replace: true });
                    },
                    onError: (err: AxiosError<ERROR>) => {
                      Toast.error(err.response?.data.message);
                    },
                  });
                }
              }}
              className="w-full"
              text={"완료"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default KakaoSignup;
