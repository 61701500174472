import React from "react";
import { ReactComponent as ArrowRight } from "assets/arrowRight.svg";
import { formatDateWithTime } from "hooks/useTodayWithNextMonth";

interface PointCardProps {
  price?: string;
  point?: string;
  desc?: string;
  paymentDate: string;
  canceled?: boolean;
  onClick?: () => void;
}

const pointCard = ({
  price,
  onClick,
  point,
  desc,
  paymentDate,
  canceled,
}: PointCardProps) => {
  return (
    <div onClick={onClick} className={`${!desc && "cursor-pointer"}`}>
      <div className="RBody14 text-Gray05">
        {formatDateWithTime(paymentDate)}
      </div>
      <div className="flex mt-2 items-center w-full gap-7 justify-between">
        {desc && <div className="BBody16 text-Gray09 flex-1">{desc}</div>}
        {point && <div className="BBody16 text-Gray09">{point}포인트</div>}
        <div
          className={`flex cursor-pointer items-center gap-2 BBody16 ${
            canceled ? "text-Gray09" : "text-Blue04"
          }`}
        >
          <div>{canceled ? "취소완료" : price}</div>
          {!desc && <ArrowRight />}
        </div>
      </div>
    </div>
  );
};

export default pointCard;
