import React from "react";

import { usePointHistory } from "api/Payment/Payment";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import PointCard from "components/PointCard";

const PointUsageHistory = () => {
  const { data: myUse } = usePointHistory({
    size: 24,
  });

  const UsageHistory =
    myUse?.pages.flatMap((item: any) => item.data.content) || [];
  return (
    <div>
      <HeaderWithBackButton title={"사용 내역"} />
      <div className="p-5 pb-24">
        {UsageHistory.length !== 0 ? (
          <div className="flex flex-col gap-4">
            {UsageHistory.map((item) => {
              return (
                <PointCard
                  desc={item.description}
                  price={`${item.point}포인트`}
                  paymentDate={item.createAt}
                  key={item.paymentId}
                />
              );
            })}
          </div>
        ) : (
          <div className="text-Gray05 RBody14">사용내역이 없어요</div>
        )}
      </div>
    </div>
  );
};

export default PointUsageHistory;
