import requestToCastingVote from "utils/requestToCastingVote";

import { useQuery } from "react-query";
import { ERROR } from "type/common";
import { Toast } from "hooks/useToast";
import { AxiosError } from "axios";
import { StaffFeedbackStateResult } from "../type";

const getFeedbackState = async (
  monthsAgo: number
): Promise<StaffFeedbackStateResult> => {
  const { data } = await requestToCastingVote({
    method: "GET",
    url: "statistics/feedback",
    params: { monthsAgo },
  });

  return data;
};

export const useFeedbackState = (monthsAgo: number) =>
  useQuery(["FeedbackState", monthsAgo], () => getFeedbackState(monthsAgo), {
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
