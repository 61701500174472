import React, { useEffect, useState } from "react";

import Button from "components/Button";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import {
  useMyCastingCalls,
  useMyProductionCastingCalls,
  useMyProductions,
} from "api/Mypage/Mypage";
import { ReactComponent as Kebab } from "assets/kebabIcon.svg";
import Thumbnail from "assets/thumnail.png";
import OptionSelectBox from "components/OptionSelectBox";
import { useChangeCastingCallStatusMutation } from "api/CastingCall/CastingCall";
import BottomPopup from "components/BottomPopup";
import useScrollRestoration from "hooks/useScrollRestoration";
import ButtonBackground from "components/ButtonBackground";
import { useInView } from "react-intersection-observer";
import useLocalStorage from "use-local-storage";
import { useScroll } from "hooks/useScroll";
import { TintButton } from "components/TintButton";
import { FillButton } from "components/FillButton";

const StaffMyCasting = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollKey = `scrollIndex${location.pathname}`;

  const [accordionOpenId, setAccordionOpenId] = useState<number | null>(null);
  const [productId, setProductId] = useState(0);
  const [productOption, setProductOption] = useState<number | null>(null);
  const [castingCallOption, setCastingCallOption] = useState<number | null>(
    null
  );

  const [dead, setDead] = useState(false);
  const [deadInfo, setDeadInfo] = useState<{
    id: number | null;
  }>({ id: null });
  const [scrollY, setScrollY] = useLocalStorage(scrollKey, 0);

  const { y } = useScroll();

  useEffect(() => {
    if (scrollY !== 0) setScrollY(y);
  }, [y]);

  useEffect(() => {
    if (scrollY !== 0) window.scrollTo(0, scrollY);
  }, []);

  const { mutate: changeStatus } = useChangeCastingCallStatusMutation();

  // 작품
  const { ref: ProductRef, inView: ProductionInView } = useInView({
    threshold: 0.5,
  });
  const {
    data: ProductionData,
    fetchNextPage: ProductionNext,
    hasNextPage: ProductionHas,
  } = useMyProductions();

  useEffect(() => {
    if (ProductionInView && ProductionHas) {
      ProductionNext();
    }
  }, [ProductionInView]);

  // 작품 내부 공고
  const {
    ref: ProductinWithCastingCallRef,
    inView: ProductinWithCastingCallInView,
  } = useInView({ threshold: 0.5 });
  const {
    data: productWidthCastingCallData,
    fetchNextPage: productWithCastingcallNext,
    hasNextPage: productWithCastingcallHas,
  } = useMyProductionCastingCalls({ id: productId });

  useEffect(() => {
    if (ProductinWithCastingCallInView && productWithCastingcallHas) {
      productWithCastingcallNext();
    }
  }, [ProductinWithCastingCallInView]);

  const optionItem = [
    {
      id: 0,
      title: "수정",
      action: (id: number) => {
        navigate(`/staff/post/edit/${id}`);
      },
    },
    {
      id: 1,
      title: "공고등록",
      action: (id: number, title: string) => {
        navigate("/staff/casting-call", {
          state: {
            prodTitle: title,
            prodId: id,
          },
        });
      },
    },
    {
      id: 2,
      title: "작품보기",
      action: (id: number) => {
        navigate(`/staff/filmo/my/${id}`);
      },
    },
  ];

  const castingCallItem = [
    {
      id: 0,
      title: "공고보기",
      action: (id: number) => navigate(`/casting/my/${id}`),
    },
    {
      id: 1,
      title: "수정",
      action: (id: number, productTitle?: string) =>
        navigate(`/staff/casting-call/edit/${id}`, {
          state: productTitle,
        }),
    },
    {
      id: 2,
      title: "마감",
      action: (id: number, productTitle?: string) => {
        setDeadInfo({ id: id });
        setDead(true);
      },
    },
  ];

  return (
    <div>
      <HeaderWithBackButton title="내 캐스팅">
        <div
          onClick={() => {
            navigate("/staff/post");
          }}
          className={`BBody16 text-Blue04 flex items-center gap-2 cursor-pointer`}
        >
          <div>작품등록</div>
        </div>
      </HeaderWithBackButton>
      {dead && (
        <BottomPopup onClose={() => setDead(false)}>
          <div className="text-Gray09 BBody20">선택한 공고를 마감할까요 ?</div>
          <div className="MBody16 my-4 text-Gray05">
            공고를 마감하면 공고 수정 및 캐스팅을 진행할 수 없어요. 받은 프로필
            피드백도 함께 마감돼요.
          </div>
          <ButtonBackground>
            <FillButton
              onClick={() => {
                setDead(false);
              }}
              className="w-full"
              text={"닫기"}
              variant="Secondary"
              size="L"
            />
            <TintButton
              onClick={() => {
                changeStatus(
                  { castingCallId: deadInfo.id!, status: "CLOSED" },
                  {
                    onSuccess: () => {
                      setDead(false);
                      setDeadInfo({ id: null });
                      window.location.reload();
                    },
                    onError: () => {
                      setDead(false);
                    },
                  }
                );
              }}
              text={"마감하기"}
              className="w-full"
              variant="delete"
              size="L"
            ></TintButton>
          </ButtonBackground>
        </BottomPopup>
      )}
      <div className="pb-24 p-5">
        <div className="flex flex-col gap-4">
          {ProductionData?.pages?.map((item) => {
            return item.data.content.map((prodItem: any) => {
              // 작품
              return (
                <div key={prodItem?.productionId}>
                  <div
                    onClick={() => {
                      if (prodItem.productionId === accordionOpenId) {
                        setAccordionOpenId(null);
                        setProductId(0);
                      } else {
                        setProductId(prodItem.productionId);
                        setAccordionOpenId(prodItem.productionId);
                      }
                    }}
                    className="flex cursor-pointer items-center gap-4 w-full relative"
                  >
                    <img
                      className="w-12 h-12 rounded-[10px] object-cover"
                      src={
                        prodItem.thumbnailUrl !== null
                          ? `${process.env.REACT_APP_MEDIA_URL}${prodItem.thumbnailUrl}`
                          : Thumbnail
                      }
                      alt=""
                    />
                    <div>
                      <div className="MCaption12 text-Gray05">
                        {prodItem.format.label}
                      </div>
                      <div className="BBody16 text-Gray09 max-w-[269px] text-ellipsis overflow-hidden whitespace-nowrap">
                        {prodItem.title}
                      </div>
                    </div>
                    <Kebab
                      onClick={(e) => {
                        e.stopPropagation();
                        if (productOption === prodItem.productionId) {
                          setProductOption(null);
                          setCastingCallOption(null);
                        } else {
                          setCastingCallOption(null);

                          setProductOption(prodItem.productionId);
                        }
                      }}
                      className="absolute right-0 top-4 cursor-pointer"
                    ></Kebab>
                    {productOption === prodItem.productionId && (
                      <OptionSelectBox
                        onClose={() => setProductOption(null)}
                      >
                        {optionItem.map((opItem) => {
                          // 작품 옵션박스
                          return (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                opItem.action(
                                  prodItem?.productionId,
                                  prodItem.title
                                );
                              }}
                              className="hover:bg-Gray03 rounded-[10px] p-1"
                              key={opItem.id}
                            >
                              {opItem.title}
                            </div>
                          );
                        })}
                      </OptionSelectBox>
                    )}
                  </div>
                  {prodItem.productionId === accordionOpenId && (
                    <div className="max-h-[500px] h-full overflow-y-auto">
                      <div className={`flex flex-col`}>
                        {productWidthCastingCallData?.pages[0].data.content
                          .length !== 0 ? (
                          <>
                            {productWidthCastingCallData?.pages.map(
                              (item: any, i) => {
                                return item.data.content.map(
                                  (prodWithCastingCallItem: any) => {
                                    // 작품을 클릭해서 열면 나오는 공고
                                    return (
                                      <div
                                        onClick={() => {
                                          navigate(
                                            `/staff/casting/process/${prodWithCastingCallItem.castingCallId}`,
                                            {
                                              state: {
                                                title:
                                                  prodWithCastingCallItem.title,
                                                castingCallId:
                                                  prodWithCastingCallItem.castingCallId,
                                                status:
                                                  prodWithCastingCallItem
                                                    .status.code,
                                              },
                                            }
                                          );
                                        }}
                                        key={
                                          prodWithCastingCallItem?.castingCallId
                                        }
                                        className="relative hover:bg-Gray03 bg-Gray01 cursor-pointer first:mt-4 mt-0 py-2 px-5 first:border-t border-Gray03"
                                      >
                                        <div className="flex items-center mb-2 justify-between">
                                          <div className="text-Gray05 RBody14">
                                            {prodWithCastingCallItem.status
                                              .code === "CLOSED" ? (
                                              <div>마감</div>
                                            ) : (
                                              <div>
                                                {prodWithCastingCallItem.deadlineDate ||
                                                  "캐스팅 확정 시"}{" "}
                                                마감
                                              </div>
                                            )}
                                          </div>
                                          {prodWithCastingCallItem.status
                                            .code !== "CLOSED" ? (
                                            <div className="flex items-center gap-2 BBody14 text-Gray05">
                                              {castingCallItem?.map(
                                                (opItem) => {
                                                  return (
                                                    <div
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        opItem.action(
                                                          prodWithCastingCallItem.castingCallId,
                                                          prodItem.title
                                                        );
                                                      }}
                                                      className="cursor-pointer"
                                                      key={opItem.id}
                                                    >
                                                      {opItem.title}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          ) : (
                                            <div
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(
                                                  `/casting/my/${prodWithCastingCallItem.castingCallId}`
                                                );
                                              }}
                                              className="BBody14 text-Gray05"
                                            >
                                              공고보기
                                            </div>
                                          )}
                                        </div>
                                        <div
                                          className={`BBody16 max-w-[333px] text-ellipsis overflow-hidden whitespace-nowrap ${prodWithCastingCallItem.status
                                            .code === "CLOSED"
                                            ? "text-Gray05"
                                            : "text-Gray09"
                                            }`}
                                        >
                                          {prodWithCastingCallItem.title}
                                        </div>
                                      </div>
                                    );
                                  }
                                );
                              }
                            )}
                          </>
                        ) : (
                          <div className="RBody16 bg-Gray01 mt-4 py-2 px-5 border-t border-Gray03">
                            캐스팅 중인 공고가 없어요.
                          </div>
                        )}
                        {productWithCastingcallHas && <div className="h-5 bg-transparent" ref={ProductinWithCastingCallRef} />}
                      </div>
                    </div>
                  )}
                </div>
              );
            });
          })}
          {ProductionHas && <div className="h-5 bg-transparent" ref={ProductRef} />}
        </div>
      </div>
    </div>
  );
};

export default StaffMyCasting;
