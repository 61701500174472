import React from 'react';

import HeaderWithBackButton from 'components/HeaderWithBackButton';

import { ReactComponent as Point } from 'assets/icon_point.svg'
import { ReactComponent as Premium } from 'assets/icon_premium.svg'
import { ReactComponent as feedback } from 'assets/icon_feedback.svg'
import { useFeedbackState } from './api/feedbackState';

const StaffFeedbackState = () => {

    const { data } = useFeedbackState(6);

    const currentFeedbackState = [
        { id: 0, icon: Point, title: "포인트", point: data?.data.length === 0 ? 0 : data?.data[0]?.totalPoint },
        { id: 1, icon: feedback, title: "피드백", point: data?.data.length === 0 ? 0 : data?.data[0]?.feedbackCount! + data?.data[0]?.castingFeedbackCount! },
        { id: 2, icon: Premium, title: "프리미엄 피드백", point: data?.data.length === 0 ? 0 : data?.data[0]?.premiumFeedbackCount }
    ]

    return (
        <>
            <HeaderWithBackButton title={'보낸 피드백 현황'} />
            <div className='pt-5 pb-24'>
                <div className='px-5'>
                    <div className='MCaption14 text-Gray05'>{data?.data[0]?.statMonth}</div>
                    <div className='my-4 flex items-start'>
                        {currentFeedbackState?.map((item) => {
                            return (
                                <div key={item.id} className='flex-1 flex flex-col items-center'>
                                    <item.icon className='mb-2' />
                                    <div className='MCaption12'>{item.title}</div>
                                    <div className='BBody16 text-Blue04'>{item.point}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <hr className='h-1.5 bg-Gray01' />
                <div className='px-5 pt-4 flex flex-col gap-7'>
                    {data?.data.slice(1).map((item) => {
                        return (
                            <div key={item.statMonth} className='flex flex-col gap-1.5'>
                                <div className='RCaption14 text-Gray05'>
                                    {item.statMonth}
                                </div>
                                <div className='flex flex-col gap-1.5'>
                                    <div className='flex items-start justify-between'>
                                        <div className='MBody16'>포인트</div>
                                        <div className='BBody16'>{item.totalPoint}</div>
                                    </div>
                                    <div className='flex text-Gray05 MCaption14 items-start justify-between'>
                                        <div>프로필 피드백</div>
                                        <div>{item.feedbackCount + item.castingFeedbackCount}</div>
                                    </div>
                                    <div className='flex text-Gray05 MCaption14 items-start justify-between'>
                                        <div>프리미엄 프로필 피드백</div>
                                        <div>{item.premiumFeedbackCount}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </>
    );
};

export default StaffFeedbackState;