import React, { useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as Search } from "../assets/icon_search.svg";
import EmptyStateImage from "../assets/icon_nolist.png";

import { useGetManageArtistProfileList } from "api/Manage";

import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import MemberSelectCard from "components/MemberSelectCard";
import { Profile } from "./StaffDetail";
import { splitBirth } from "utils/onBirth";
import { FillButton } from "components/FillButton";
import ButtonBackground from "components/ButtonBackground";

interface ManagerSendArtistProfileType {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  setSelectedProfiles?: React.Dispatch<React.SetStateAction<Profile[]>>;
  selectedProfiles?: Profile[];
  buttonText?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ManagerSendArtistProfile = ({
  isActive,
  setIsActive,
  title,
  setSelectedProfiles,
  selectedProfiles,
  buttonText,
  disabled,
  onClick,
}: ManagerSendArtistProfileType) => {
  const navigate = useNavigate();
  const selectArtistModal = useRef<HTMLDivElement>(null);

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const [searchValue, setSearchValue] = useState<string>("");

  const {
    data: artists,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetManageArtistProfileList({});
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const allLists = useMemo(
    () =>
      artists ? artists?.pages?.flatMap((artist) => artist.data.content) : [],
    [artists]
  );

  const filteredProfiles = useMemo(() => {
    return allLists.filter((profile) =>
      profile.profileName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [allLists, searchValue]);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={selectArtistModal}
      className={`${isActive
        ? "fixed top-0 left-2/4 -translate-x-2/4 max-w-[400px] w-full h-full bg-Gray00 z-[1002]"
        : "hidden"
        } `}
    >
      <HeaderWithBackButton
        setIsBackAction={() => {
          setSelectedProfiles!([])
          setIsActive(false)
        }}
        title={""}
      />
      {allLists.length === 0 ? (
        <div className="flex flex-col items-center text-center mt-24">
          <img
            src={EmptyStateImage}
            alt="빈 상태 이미지"
            className="w-16 h-16 mb-4"
          />
          <p className="text-Gray05 MBody16 mb-10">
            아직 프로필이 없어요.
            <br />
            아티스트를 등록하고 캐스팅보트를 시작해보세요.
          </p>
          <FillButton
            onClick={() => navigate("/manage/artist/profile")}
            size="L"
            variant="Membership"
            text="아티스트 등록"
          />
        </div>
      ) : (
        <>
          <div className="px-5 pt-5 pb-4 space-y-7">
            <div className="BBody20 text-Gray09">{title}</div>
            <div className="relative MBody14">
              <input
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                placeholder={"이름 입력"}
                className="w-full border py-3 pr-[44px] rounded-md px-[16px] RBody16"
              />
              <Search className="absolute top-[15px] right-4" />
            </div>
          </div>
          <div className="h-full">
            <div
              style={{ height: `${viewportHeight - (selectedProfiles?.length! > 0 ? 298 : 214)}px` }}
              className="overflow-y-scroll "
            >
              {filteredProfiles?.map((item) => {
                return <MemberSelectCard key={item.profileId} onClick={() => {
                  setSelectedProfiles!((prev) => {
                    if (prev?.some((p) => p.profileId === item.profileId)) {
                      return prev.filter((p) => p.profileId !== item.profileId);
                    } else {
                      return [...prev, item];
                    }
                  });
                }} imageUrl={item.thumbnailUrl} title={item.profileName} subTitle={splitBirth(item.birthDate!)} selected={selectedProfiles!?.some(
                  (p) => p.profileId === item.profileId
                )} />

              })}
              <div ref={ref} />
            </div>
          </div>
        </>
      )}
      {selectedProfiles?.length! > 0 &&
        <ButtonBackground>
          <FillButton
            disabled={disabled}
            onClick={onClick}
            size="B"
            variant="Membership"
            text={buttonText ? buttonText : "선택"}
            className="w-full"
          />
        </ButtonBackground>
      }
    </div>
  );
};

export default ManagerSendArtistProfile;
