import React from "react";
import Button from "./Button";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";

interface StaffCardType {
  onClick?: () => void;
  image?: string;
  name?: string;
  gender?: string;
  children?: React.ReactNode;
  role?: string;
  isPending?: boolean;
  agency?: string;
  isCasting?: boolean;
}

const StaffCard = ({
  onClick,
  image,
  children,
  name,
  role,
  agency,
  isCasting,
  isPending,
}: StaffCardType) => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
  return (
    <div
      onClick={onClick}
      className="cursor-pointer border border-[#E0E3EB] gap-4 rounded-[10px] p-4 w-full flex items-start"
    >
      {MEDIA_URL && (
        <img
          src={`${MEDIA_URL}${image}`}
          alt="Thumnail"
          className="w-[60px] h-[60px] object-cover rounded-full"
        />
      )}
      <div className="flex-1">
        <div className="flex items-center justify-between BBody16">
          <div className="flex items-center gap-2">
            <div>{name}</div>
            {isPending && (
              <div className="MCaption12 flex flex-col justify-center text-Gray05 border h-7 border-Gray03 px-2 text-center rounded-full">
                승인대기
              </div>
            )}
            {isCasting && (
              <div className="MCaption12 flex flex-col justify-center text-Blue04 border h-7 border-Blue04 px-2 text-center rounded-full">
                캐스팅중
              </div>
            )}
          </div>
          {/* <Bookmark /> */}
        </div>
        <div className="flex items-center text-Gray05 gap-2 my-2 BBody14">
          <div>{role}</div>
          <div>|</div>
          <div className="flex-1 text-ellipsis overflow-hidden whitespace-nowrap">
            {agency}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default StaffCard;
