import React, { useEffect, useState } from "react";

import { useStaffCastingSuggest } from "api/CastingOffer/CastingOffer";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useInView } from "react-intersection-observer";
import BottomPopup from "components/BottomPopup";
import ButtonBackground from "components/ButtonBackground";
import { LineButton } from "components/LineButton";
import { format_mmdd } from "utils/onDateFormat";
import ArtistCastingOfferCard from "./OfferProfile/ArtistCastingOfferCard";

const StaffSendSuggest = () => {
  const [sendDetail, setSendDetail] = useState(false);
  const [sendItem, setSendItem] = useState<CastingOffer>();

  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const { data, fetchNextPage, hasNextPage, isLoading } = useStaffCastingSuggest();
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const allLists =
    data?.pages.flatMap((item: any) => item.data.content) || [];

  const groupByDate = (list: any[]) => {
    return list.reduce((acc, item) => {
      const date = item.createdAt.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };

  const groupedByDate = groupByDate(allLists);

  const initialCastingOffer: CastingOffer = {
    castingOfferId: null,
    description: "",
    deadlineDate: "",
    createdAt: "",
    readAt: null,
    acceptedAt: null,
    rejectedAt: null,
    status: {
      code: "",
      label: "",
    },
    castingCallId: null,
    castingCallTitle: null,
    staffId: null,
    staffName: "",
    staffRole: {
      code: "",
      label: "",
    },
    staffAgency: null,
    staffThumbnailUrl: "",
    profileId: null,
    profileName: "",
    thumbnailUrl: "",
  };

  const { profileName, castingCallTitle, description } = sendItem || {};

  return (
    <>
      <HeaderWithBackButton title={"보낸 제안"} />
      <div className="pt-5 pb-24">
        <>
          {allLists.length === 0 ? (
            <div className="mt-36 items-center text-center">
              <div className="text-Gray05 RBody14">보낸 제안이 없어요.</div>
            </div>
          ) : (
            <div>
              {Object.keys(groupedByDate).map((date) => (
                <div key={date} className="mb-4">
                  <div className="text-Gray05 RCaption14 px-5">{format_mmdd(date)}</div>
                  <div>
                    {groupedByDate[date].map((item: CastingOffer) => {
                      return (
                        <ArtistCastingOfferCard
                          onClick={() => {
                            setSendDetail(true);
                            setSendItem(item)
                          }}
                          key={item.castingOfferId}
                          imageUrl={item?.thumbnailUrl}
                          title={`[${item.profileName}]님께 ${item.castingCallTitle ? `[${item.castingCallTitle}] 캐스팅` : ""
                            } 제안을 보냈어요`}

                          subTitle={""}
                          isFeedback={false} />
                      )
                    })}
                  </div>
                </div>
              ))}
              {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
            </div>
          )}
        </>
      </div>
      {sendDetail && (
        <BottomPopup
          onClose={() => {
            setSendDetail(false);
            setSendItem(initialCastingOffer);
          }}
        ><div className="BBody20 break-keep">
            [{profileName}]님께
            {castingCallTitle ? `[${castingCallTitle}] 캐스팅 제안을 보냈어요!🙌` : " 제안을 보냈어요!🙌"}
          </div>

          {description && (
            <div className="mt-4 w-full max-h-[248px] overflow-scroll rounded-[10px] bg-Gray01 break-words whitespace-pre-line RBody16 p-4">
              <div>{description}</div>
            </div>
          )}
          <ButtonBackground>
            <LineButton
              onClick={() => {
                setSendDetail(false);
                setSendItem(initialCastingOffer);
              }}
              className="w-full"
              variant="normal"
              size="L"
              text="닫기"
            />
          </ButtonBackground>
        </BottomPopup>
      )}
    </>
  );
};
export default StaffSendSuggest;


interface CastingOffer {
  castingOfferId: number | null;
  description: string;
  deadlineDate: string;
  createdAt: string;
  readAt: string | null;
  acceptedAt: string | null;
  rejectedAt: string | null;
  status: {
    code: string;
    label: string;
  };
  castingCallId: number | null;
  castingCallTitle: string | null;
  staffId: number | null;
  staffName: string;
  staffRole: {
    code: string;
    label: string;
  };
  staffAgency: string | null;
  staffThumbnailUrl: string;
  profileId: number | null;
  profileName: string;
  thumbnailUrl: string;
};
