import React from 'react';

interface ArtistFilmographiesProps {
    filmographies: {
        id: number;
        title: string;
        type: {
            code: string;
            label: string;
        };
        role: {
            code: string;
            label: string;
        };
        releaseYear: string;
        description: string;
        orderNumber: number;
    }[]
}

const ArtistFilmographies = ({ filmographies }:
    ArtistFilmographiesProps
) => {

    const groupedByYear = filmographies.reduce((acc: WorkByYear, item) => {
        const year = item.releaseYear;
        if (!acc[year]) {
            acc[year] = [];
        }

        acc[year].push(item);

        acc[year].sort((a: any, b: any) => a.orderNumber - b.orderNumber);

        return acc;
    }, {});

    return (
        <div className='flex px-5 flex-col gap-2'>
            <div className="RCaption14 text-Gray05">필모그래피</div>
            <div className="flex flex-col gap-4">
                {Object.keys(groupedByYear)
                    .sort((a, b) => Number(b) - Number(a))
                    ?.map((year) => (
                        <div
                            className="flex items-start gap-4"
                            key={year}
                        >
                            <div className="RBody14 text-Gray09">
                                {year}
                            </div>
                            <div className="flex flex-col items-baseline gap-4">
                                {groupedByYear[year].map((item: any) => (
                                    <div key={item.id}>
                                        <div className="RBody14 text-Gray05">
                                            {item.type.label}
                                        </div>
                                        <div className="BBody16 mt-2 mb-1">
                                            {item.title}
                                        </div>
                                        <div className="BBody14 flex gap-1 text-Gray05 items-center">
                                            <div>{item?.role?.label}</div>•
                                            <div>{item?.description}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ArtistFilmographies;

interface Work {
    id: number;
    title: string;
    type: {
        code: string;
        label: string;
    };
    role: {
        code: string;
        label: string;
    };
    releaseYear: string;
    description: string;
    orderNumber: number;
}

type WorkByYear = Record<string, Work[]>;

