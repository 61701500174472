import { AxiosError, AxiosResponse } from "axios";
import { Toast } from "hooks/useToast";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { ERROR } from "type/common";
import {
	ActiveMembershipResponse,
	CancelPaymentRequest,
	CancelPaymentResponse,
	MembershipHistoryParams,
	paymentHistoryParams,
	PointHistoryParams,
	subscribeParams,
	UpdateCardParams,
	WelcomePayPoint,
	WelcomePaySubscribeParams,
	WelcomePaySubscribeResponse,
} from "type/payment";
import requestToCastingVote from "utils/requestToCastingVote";

// 포인트 조회
export const fetchMemberPoint = () => {
	return requestToCastingVote({
		method: "GET",
		url: "/memberpoint",
	}).then((res) => res.data);
};
export const useMemberPoint = () => {
	return useQuery(["memberPoint"], fetchMemberPoint, {
		onError: (error: AxiosError<ERROR>) => {
			Toast.error(error.response?.data.message);
		},
	});
};

// 결제 내역 조회
const fectchPaymentHistory = (params?: paymentHistoryParams) => {
	return requestToCastingVote({
		method: "GET",
		url: "/payments/completed-cancelled",
		params,
	}).then((res) => res.data);
};
export const usePaymentHistory = (params?: paymentHistoryParams) => {
	return useInfiniteQuery(
		["paymentHistory", params],
		({ pageParam = 0 }) =>
			fectchPaymentHistory({
				...params,
				page: pageParam,
				size: params?.size,
			}),
		{
			getNextPageParam: (lastPage) => {
				return lastPage?.data.last ? undefined : lastPage.data.pageable.pageNumber + 1;
			},
			onError: (error: AxiosError<ERROR>) => {
				Toast.error(error.response?.data.message);
			},
		}
	);
};

// 포인트 사용 내역 조회
export const fetchPointHistory = (params?: PointHistoryParams) => {
	return requestToCastingVote({
		method: "GET",
		url: "/points/histories",
		params,
	}).then((res) => res.data);
};

export const usePointHistory = (params?: PointHistoryParams) => {
	return useInfiniteQuery(
		["pointHistory", params],
		({ pageParam = 0 }) =>
			fetchPointHistory({
				...params,
				page: pageParam,
				size: params?.size,
			}),
		{
			getNextPageParam: (lastPage) => {
				return lastPage?.data.last ? undefined : lastPage.data.pageable.pageNumber + 1;
			},
			onError: (error: AxiosError<ERROR>) => {
				Toast.error(error.response?.data.message);
			},
		}
	);
};

// 웰컴 페이 멤버십 구독
const fectchSubscribe = (data: subscribeParams): Promise<AxiosResponse<WelcomePaySubscribeResponse>> => {
	return requestToCastingVote({
		method: "POST",
		url: "payments/welcomepay/subscribe",
		data: data,
	}).then((res) => res.data);
};
export const useSubscribe = () => {
	return useMutation<AxiosResponse<WelcomePaySubscribeResponse>, AxiosError<ERROR>, subscribeParams>(
		fectchSubscribe,
		{
			onError: (error) => {
				Toast.error(error.response?.data.message);
			},
		}
	);
};

// 웰컴 페이 멤버십 구독 카드 수정
const fetchCardUpdate = (data: UpdateCardParams): Promise<AxiosResponse<WelcomePaySubscribeResponse>> => {
	return requestToCastingVote({
		method: "PATCH",
		url: "/payments/welcomepay/billing",
		data,
	}).then((res) => res.data);
};
export const useCardUpdate = () => {
	return useMutation<AxiosResponse<WelcomePaySubscribeResponse>, AxiosError<ERROR>, UpdateCardParams>(
		fetchCardUpdate,
		{
			onSuccess: () => {
				Toast.success("결제수단이 변경되었어요");
			},
			onError: (error: AxiosError<ERROR>) => {
				Toast.error(error.response?.data.message);
			},
		}
	);
};

// 웰컴 페이 멤버십 구독 해지
const fetchCancelSubscription = (): Promise<AxiosResponse<WelcomePaySubscribeResponse>> => {
	return requestToCastingVote({
		method: "DELETE",
		url: "/payments/welcomepay/billing",
	}).then((res) => res.data);
};

export const useCancelSubscription = () => {
	return useMutation<AxiosResponse<WelcomePaySubscribeResponse>, AxiosError<ERROR>>(fetchCancelSubscription, {
		onError: (error: AxiosError<ERROR>) => {
			Toast.error(error.response?.data.message);
		},
	});
};
// 멤버십 결제 이력
const fetchMembershipHistory = (params?: MembershipHistoryParams) => {
	return requestToCastingVote({
		method: "GET",
		url: "/membership/histories",
		params,
	}).then((res) => res.data);
};
export const useMembershipHistory = (params?: MembershipHistoryParams) => {
	return useInfiniteQuery(
		["membershipHistory", params],
		({ pageParam = 0 }) => fetchMembershipHistory({ ...params, page: pageParam, size: 20 }),
		{
			getNextPageParam: (lastPage) => {
				return lastPage?.data.last ? undefined : lastPage.data.pageable.pageNumber + 1;
			},
			onError: (error: AxiosError<ERROR>) => {
				Toast.error(error.response?.data.message);
			},
		}
	);
};

// 사용중인 멤버십
const fetchActiveMembership = () => {
	return requestToCastingVote({ method: "GET", url: "/membership" }).then((res) => res.data);
};
export const useActiveMembership = () => {
	return useQuery<ActiveMembershipResponse, AxiosError<ERROR>>("activeMembership", fetchActiveMembership, {
		onError: (error: AxiosError<ERROR>) => {
			Toast.error(error.response?.data.message);
		},
	});
};

// 결제 취소
export const cancelPayment = async (requestData: CancelPaymentRequest) => {
	const response = await requestToCastingVote({
		method: "POST",
		url: "/payments/welcomepay/cancel",
		data: requestData,
	});
	return response.data;
};
export const useCancelPayment = () => {
	return useMutation<CancelPaymentResponse, AxiosError<ERROR>, CancelPaymentRequest>(cancelPayment, {
		onError: (error) => {
			Toast.error(error.response?.data.message);
		},
	});
};

// 포인트 결제 초기 데이터 생성
const fetchPoint = async (data: WelcomePaySubscribeParams) => {
	const response = await requestToCastingVote({
		method: "POST",
		url: "payments/welcomepay",
		data,
	});
	return response.data;
};
export const usePoint = (requestData?: WelcomePaySubscribeParams) => {
	return useMutation<WelcomePayPoint, AxiosError<ERROR>, WelcomePaySubscribeParams>(fetchPoint, {
		onError: (error) => {
			Toast.error(error.response?.data.message);
		},
	});
};

const fetchMembershipType = async (membershipTypeId: number) => {
	const response = await requestToCastingVote({
		method: "GET",
		url: "/membershiptype",
		params: { membershipTypeId },
	});
	return response.data;
};
export const useMembershipTypeQuery = (membershipTypeId: number) => {
	return useQuery(["membershipType", membershipTypeId], () => fetchMembershipType(membershipTypeId), {
		enabled: !!membershipTypeId,
	});
};

// 남은 포인트 체크
const fetchRemainPoint = async (point: number): Promise<void> => {
	const response = await requestToCastingVote({
		method: "GET",
		url: "/memberpoint/check",
		params: { point },
	});

	return response.data;
};
export const useRemainPoint = (point: number) => {
	return useQuery(["remainPoint", point], () => fetchRemainPoint(point), {
		enabled: !!point,
	});
};

// 스탭 유형, 프로필 보내기 유형별 포인트 조회
const fetchRolePoint = async (role: string, type: string) => {
	const response = await requestToCastingVote({
		method: "GET",
		url: "/role/point",
		params: { role, type },
	});
	return response.data.data as {
		role: {
			code: string;
			label: string;
		};
		type: {
			code: string;
			label: string;
		};
		point: number;
	};
};
export const useRolePoint = (role: string, type: string) => {
	return useQuery(["rolePoint", role, type], () => fetchRolePoint(role, type), {
		enabled: !!role && !!type,
	});
};
