import ButtonBackground from "components/ButtonBackground";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { LineButton } from "components/LineButton";
import { useLocation, useNavigate } from "react-router";
import requestToCastingVote from "../utils/requestToCastingVote";
import { Toast } from "hooks/useToast";

const MemberFindId = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const kakaoLink = async () => {
    await requestToCastingVote({
      method: "POST",
      url: "/auth/kakao/link",
      data: state,
    }).then((res) => {
      navigate("/login?code=4100", { replace: true });
      Toast.success("연동 되었어요");
    });
  };

  return (
    <div>
      <HeaderWithBackButton title={"카카오 계정 연동"} />
      <div className="px-5">
        <div className="py-10">
          <div className="text-Gray05 RBody16">
            휴대폰 번호로 가입된 아이디예요.
          </div>
        </div>
        <div className="w-full bg-Gray01 py-3 text-center text-Gray09 MBody16 rounded-md">
          {state?.userId !== "" ? state.userId : "가입된 계정이 없습니다."}
        </div>
      </div>
      <ButtonBackground>
        <LineButton
          className="w-full"
          variant="normal"
          size="L"
          onClick={() => {
            kakaoLink();
          }}
          text="연동하기"
        ></LineButton>
      </ButtonBackground>
    </div>
  );
};

export default MemberFindId;
