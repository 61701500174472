import React from 'react';

interface ArtistCastingOfferCardProps {
    imageUrl: string;
    isOffered?: boolean;
    title: string;
    subTitle: string;
    isFeedback: boolean;

    onClick?: () => void;
}

const ArtistCastingOfferCard = ({
    imageUrl,
    isOffered,
    title,
    subTitle,
    isFeedback,

    onClick
}: ArtistCastingOfferCardProps) => {
    const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

    return (
        <div onClick={onClick} className='flex items-start gap-2 px-5 py-2 cursor-pointer'>
            <img className='w-7 h-7 rounded-full object-cover'
                src={MEDIA_URL + imageUrl}
                alt="오퍼 이미지 썸네일"
            />
            <div className='flex flex-col gap-1 items-start'>
                <div className={`flex items-start space-x-1`}>
                    {isOffered &&
                        <div className='bg-Blue01 MCaption10 inline-flex h-[18px] py-0.5 px-1 text-Blue04 items-center rounded'>제안</div>
                    }
                    {title &&
                        <div className={`MBody16 flex-1 ${isFeedback ? "text-Blue04" : "text-Gray09"}`}>{title}</div>
                    }
                </div>
                {subTitle &&
                    <div className='MCaption14 text-Gray05'>{subTitle}</div>
                }
            </div>
        </div>
    );
};

export default ArtistCastingOfferCard;