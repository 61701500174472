import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { authInfo, loginCheck } from "store/auth/atom";

import { ReactComponent as KakaoBLK } from "assets/kakao_blk.svg";

import Profile from "assets/cv_logo_profile.png";
import Header from "components/Header";
import {
  MY_ROLE,
  MEMBER_APPROVED,
  MEMBERSHIP_ACTIVE,
  HAS_PROFILE,
} from "utils/onRoleStatus";
import { useAuthInfo } from "api/auth";
import { useMemberPoint } from "api/Payment/Payment";
import Chip from "components/Chip";
import { FillButton } from "components/FillButton";
import { Toast } from "hooks/useToast";

const Mypage = () => {
  const navigate = useNavigate();
  const { data: authData } = useAuthInfo();
  const { data: myPoint } = useMemberPoint();

  const isActive = MEMBERSHIP_ACTIVE();
  const isApproved = MEMBER_APPROVED();
  const role = MY_ROLE();
  const hasProfile = HAS_PROFILE();

  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
  const setLogin = useSetRecoilState(loginCheck);
  const auth = useRecoilValue(authInfo);

  const membership = MEMBERSHIP_ACTIVE();

  const [userInfo, setUserInfo] = useState<{
    id: string;
    username: string;
    role: string;
  }>({ id: "", username: "", role: "" });

  useEffect(() => {
    localStorage.removeItem("castingTab");

    const updatedUserInfo = localStorage.getItem("user");

    let userInfo = updatedUserInfo ? JSON.parse(updatedUserInfo) : null;
    if (userInfo && userInfo.id) {
      setLogin(true);
      setUserInfo({
        id: userInfo.id,
        username: userInfo.username,
        role: userInfo.role,
      });
    } else {
      setLogin(false);
    }
  }, []);

  const jobRole = MY_ROLE();

  const artist = [
    { id: 0, title: "내 프로필", path: "/artist/my" },
    { id: 1, title: "보낸 프로필", path: "/artist/send" },
    { id: 2, title: "받은 제안", path: "/artist/suggest" },
  ];

  const manager = [
    {
      id: 0,
      title: "아티스트 관리",
      path: hasProfile ? "/manage/artist" : "/staff/my",
    },
    { id: 1, title: "보낸 프로필", path: "/artist/send" },
    { id: 2, title: "받은 제안", path: "/artist/suggest" },
  ];

  const managerSecond = [
    { id: 0, title: "내 프로필", path: "/staff/my" },
    { id: 1, title: "받은 프로필", path: "/staff/received" },
    { id: 2, title: "보낸 제안", path: "/staff/suggest" },
    { id: 3, title: "보낸 피드백 현황", path: "/staff/feedback" },
  ]

  const staff = [
    { id: 0, title: "내 프로필", path: "/staff/my" },
    {
      id: 1, title: "내 캐스팅", path: hasProfile ? "/staff/casting/my" : "/staff/my",
    },
    { id: 2, title: "참여 캐스팅", path: "/staff/reference" },
    { id: 3, title: "받은 프로필", path: "/staff/received" },
    { id: 4, title: "보낸 제안", path: "/staff/suggest" },
    { id: 5, title: "보낸 피드백 현황", path: "/staff/feedback" },
  ];


  return (
    <>
      <Header title="더보기" />
      <div className="pb-[70px] pt-2 mx-auto">
        <div>
          <div className="flex items-center justify-between mb-4 px-5">
            <div className="flex items-center gap-2">
              {MEDIA_URL && (
                <img
                  src={
                    auth?.thumbnailUrl ? MEDIA_URL + auth?.thumbnailUrl : Profile
                  }
                  alt={"profileThumbnail"}
                  className="w-7 h-7 rounded-full object-cover"
                />
              )}
              <div className="BBody18 text-Gray09">{auth?.name}</div>
              {!isApproved && (
                <Chip className="!cursor-default" title={"승인대기"} />
              )}
            </div>
            {(role === "ROLE_MANAGER" || role === "ROLE_ARTIST") &&
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <div
                      onClick={() => {
                        navigate("/point");
                      }}
                      className="text-Blue04 cursor-pointer !BBody18"
                    >
                      {myPoint && myPoint.data && myPoint.data.toLocaleString()}
                    </div>
                    <FillButton
                      onClick={() => {
                        if (role === "ROLE_ARTIST" && !isActive) {
                          Toast.error("멤버십 가입이 필요해요");
                          navigate("/membership");
                        } else {
                          navigate("/point/charge");
                        }
                      }}
                      variant="Membership"
                      size="S"
                      text="충전"
                    />
                  </div>
                </div>
              </div>
            }
          </div>
          {jobRole === "ROLE_ARTIST" && (
            <div
              onClick={() => {
                membership
                  ? navigate("/membership/my")
                  : navigate("/membership");
              }}
              className="cursor-pointer px-5 MBody18"
            >
              멤버십
            </div>
          )}
          {jobRole === "ROLE_MANAGER" && (
            <>
              <hr className="w-full h-px bg-Gray01 my-4" />
              <div className="flex flex-col px-5 MBody18 gap-4">
                {manager.map((item, i) => {
                  return (
                    <div key={item.id}>
                      <div
                        onClick={() => {
                          navigate(item.path);
                        }}
                        className="MBody18 cursor-pointer"
                      >
                        {item.title}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <hr className="w-full h-px bg-Gray01 my-4" />
          <div className="flex flex-col px-5 MBody18 gap-4">
            {(() => {
              switch (jobRole) {
                case "ROLE_ARTIST":
                  return artist.map((item, i) => (
                    <div key={item.id}>
                      <div
                        onClick={() => {
                          navigate(item.path);
                        }}
                        className="MBody18 cursor-pointer"
                      >
                        {item.title}
                      </div>
                    </div>
                  ));
                case "ROLE_MANAGER":
                  return managerSecond.map((item) => (
                    <div key={item.id}>
                      <div
                        onClick={() => {
                          navigate(item.path);
                        }}
                        className="MBody18 cursor-pointer"
                      >
                        {item.title}
                      </div>
                    </div>
                  ));
                default:
                  return staff.map((item, i) => (
                    <div key={item.id}>
                      <div
                        onClick={() => {
                          navigate(item.path);
                        }}
                        className="MBody18 cursor-pointer"
                      >
                        {item.title}
                      </div>
                    </div>
                  ));
              }
            }
            )()}
          </div>
          <hr className="w-full h-px bg-Gray01 my-4" />
          <div className="flex MBody18 px-5 flex-col gap-4">
            <div
              onClick={() => {
                navigate("/mypage");
              }}
              className="cursor-pointer"
            >
              내 정보
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://daffy-periwinkle-38f.notion.site/1344e358e81b80f9bbd3d7c0a76f3398?pvs=4",
                  "_blank",
                  "noopener, noreferrer"
                );
              }}
              className="cursor-pointer"
            >
              고객센터
            </div>
          </div>
          {jobRole === "ROLE_ARTIST" && (
            <>
              <hr className="w-full h-px bg-Gray01 my-4" />
              <div className="MBody18 px-5">
                <div
                  onClick={() => {
                    window.open(
                      "https://open.kakao.com/o/gA5L052g",
                      "_blank",
                      "noopener, noreferrer"
                    );
                  }}
                  className="flex items-center gap-1.5 cursor-pointer"
                >
                  <KakaoBLK />
                  <div>오픈채팅 바로가기</div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Mypage;
