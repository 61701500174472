import React from "react";

interface ButtonBgProps {
  children?: React.ReactNode;
  className?: string;
}

const ButtonBackground = ({ children, className }: ButtonBgProps) => {
  return (
    <div
      className={`animate-bottom transition-all max-w-[400px] w-full flex items-center gap-2 bg-Gray00 fixed bottom-0 left-2/4 -translate-x-2/4 pb-8 px-5 z-[999] ${className}`}
    >
      {children}
    </div>
  );
};

export default ButtonBackground;
