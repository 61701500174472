import React from "react";

const ThirdPartySharing = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white rounded-lg">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
        개인정보의 제3자 제공에 대한 동의
      </h1>

      <section className="mb-8">
        <article className="mb-6">
          <p className="text-gray-600 leading-relaxed">
            회사는 회원의 개인정보를 개인정보처리방침에서 고지한 제3자 제공 범위
            내에서 제공하며, 회원의 사전 동의 없이 동 범위를 초과하여 제3자에게
            제공하지 않습니다.
          </p>
        </article>

        <article className="mb-6">
          <ul className="list-inside pl-4 text-gray-600">
            <li>
              ■ 제공받는 자: 캐스팅 디렉터·스탭·권한자·관계자(보다 자세한 사항은
              각 공고에 기재된 캐스팅 디렉터, 스탭 등의 정보를 확인 바랍니다)
            </li>
            <li>■ 제공목적: 서비스 제공(캐스팅, 피드백 관련)</li>
            <li>
              ■ 제공항목: 성명, 생년월일, 성별, 프로필, 내/외국인 여부, 연락처,
              전자우편, 주소, 보호자의 이름·연락처
            </li>
            <li>■ 보유 및 이용기간: 캐스팅 이용 목적이 완료되는 시까지</li>
            <li>■ 동의를 거부할 권리 및 동의를 거부할 경우의 불이익</li>
          </ul>
        </article>

        <article className="mb-6">
          <p className="text-gray-600 leading-relaxed">
            회원은 개인정보 제3자 제공 동의를 거부할 권리가 있습니다. 다만,
            동의를 거부하는 경우 캐스팅의 제한을 받을 수 있습니다.
            <br />
            회사가 위와 같이 개인정보를 제3자에게 제공하는 것에 동의합니다.
          </p>
        </article>
      </section>
    </div>
  );
};

export default ThirdPartySharing;
