import React, { useEffect, useMemo, useState } from "react";
import {
  useMyCastingCalls,
  useMyProductionCastingCalls,
  useMyProductions,
} from "api/Mypage/Mypage";
import Button from "components/Button";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { ReactComponent as SelectCastingCall } from "assets/selecCastingCall.svg";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { useReceivedProfiles } from "api/DeliveryProfile/DeliveryProfile";
import BottomButtonPopup from "components/BottomButtonPopup";
import BottomPopup from "components/BottomPopup";
import { Toast } from "hooks/useToast";
import { useShortlistCasting } from "api/CastingStaff/CastingStaff";
import Thumnail from "assets/thumnail.png";
import { useInView } from "react-intersection-observer";
import ArtistListCard from "components/ArtistListCard";
import { splitBirth } from "utils/onBirth";
import ButtonBackground from "components/ButtonBackground";
import { innderHeightValue } from "store/auth/atom";
import { useRecoilValue } from "recoil";
import useLocalStorage from "use-local-storage";
import { useScroll } from "hooks/useScroll";

const ReceivedProfile = () => {
  const navigate = useNavigate();
  const innderHeight = useRecoilValue(innderHeightValue);
  const location = useLocation();

  const scrollKey = `scrollIndex${location.pathname}`;

  const [height, setHeight] = useState(innderHeight - 165);
  const [scrollY, setScrollY] = useLocalStorage(scrollKey, 0);
  const [selectedList, setSelectedList] = useState<number[]>([]);
  const [selectMode, setSelectMode] = useState(false);
  const [selectCastingCall, setSelectCastingCall] = useState(false);
  const [accordionOpenId, setAccordionOpenId] = useState<number | null>(null);
  const [productId, setProductId] = useState<number | null>(null);
  const [selectedCastingCall, setSelectedCastingCall] = useState<number | null>(
    null
  );

  const { y } = useScroll();

  useEffect(() => {
    setScrollY(y);
  }, [y]);

  useEffect(() => {
    if (scrollY !== 0) window.scrollTo(0, scrollY);
  }, []);

  // 받은 프로필
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const {
    data: received,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useReceivedProfiles();
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);
  const receivedLists = useMemo(
    () =>
      received
        ? received?.pages?.flatMap((profile) => profile.data.content)
        : [],
    [received]
  );


  // 작품
  const { ref: ProductRef, inView: ProductionInView } = useInView({
    threshold: 0.5,
  });
  const {
    data: production,
    fetchNextPage: ProductionNext,
    hasNextPage: ProductuinHas,
  } = useMyProductions({ status: "ACTIVE" });

  useEffect(() => {
    if (ProductionInView && ProductuinHas) {
      ProductionNext();
    }
  }, [ProductionInView]);
  const productLists = useMemo(
    () =>
      production
        ? production?.pages?.flatMap((prod) => prod.data.content)
        : [],
    [production]
  );

  // 작품 내부 공고
  const {
    ref: ProductinWithCastingCallRef,
    inView: ProductinWithCastingCallInView,
  } = useInView({ threshold: 0.5 });
  const {
    data: productWidthCastingCallData,
    fetchNextPage: productWithCastingcallNext,
    hasNextPage: productWithCastingcallHas,
  } = useMyProductionCastingCalls({ id: productId!, status: "ACTIVE" });

  useEffect(() => {
    if (ProductinWithCastingCallInView && productWithCastingcallHas) {
      productWithCastingcallNext();
    }
  }, [ProductinWithCastingCallInView]);

  const mutate = useShortlistCasting();

  const handleShortlist = () => {
    const data = {
      castingCallId: selectedCastingCall,
      deliveryProfileIds: selectedList,
    };
    mutate.mutate(data, {
      onSuccess: (res) => {
        Toast.success("프로필을 이동했어요.");
        refetch();
        setSelectCastingCall(false);
      },
    });
  };

  return (
    <>
      <HeaderWithBackButton title="받은 프로필">
        <div
          onClick={() => {
            setSelectMode((selectMode) => !selectMode);
            if (selectMode) {
              setSelectedList([]);
            }
          }}
          className="text-Blue04 min-w-[65px] BBody14 cursor-pointer"
        >
          {received?.pages[0].data.content.length !== 0 && (
            <>{selectMode ? "취소" : "후보자 선정"}</>
          )}
        </div>
      </HeaderWithBackButton>
      <div className="px-5 pt-5 pb-24">
        {receivedLists.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">받은 프로필이 없어요.</div>
          </div>
        ) : (
          <div className="grid grid-cols-3 gap-y-4 gap-x-2">
            {receivedLists.map((receivedItem: any) => {
              return (
                <ArtistListCard
                  onClick={() => {
                    if (selectMode) {
                      if (
                        selectedList.includes(receivedItem.deliveryProfileId)
                      ) {
                        setSelectedList(
                          selectedList.filter(
                            (id) => id !== receivedItem.deliveryProfileId
                          )
                        );
                      } else {
                        setSelectedList([
                          ...selectedList,
                          receivedItem.deliveryProfileId,
                        ]);
                      }
                    } else {
                      navigate(
                        `/staff/received/${receivedItem.deliveryProfileId}`
                      );
                    }
                  }}
                  selected={selectedList.includes(
                    receivedItem.deliveryProfileId
                  )}
                  isPremium={receivedItem?.type?.code === "PREMIUM_DELIVERY"}
                  selectMode={selectMode}
                  subTitle={`${splitBirth(receivedItem.birthDate)}`}
                  key={receivedItem?.deliveryProfileId}
                  src={receivedItem.thumbnailUrl}
                  title={receivedItem.profileName}
                  isOffered={receivedItem.isOffered}
                />
              );
            })}
            {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
          </div>
        )}
        {selectCastingCall && (
          <BottomPopup
            className="!h-full"
            onClose={() => setSelectCastingCall(false)}
          >
            <div className="BBody20">공고선택</div>
            <div className="pt-4">
              <div
                style={{ height: `${height}px` }}
                className="flex flex-col gap-4 overflow-y-auto pb-[64px]"
              >
                {productLists.map((item: any) => {
                  // 작품
                  return (
                    <div key={item.productionId}>
                      <div
                        key={item.productionId}
                        onClick={() => {
                          if (item.productionId === accordionOpenId) {
                            setAccordionOpenId(null);
                            setProductId(0);
                          } else {
                            setSelectedCastingCall(null);
                            setProductId(item.productionId);
                            setAccordionOpenId(item.productionId);
                          }
                        }}
                        className="flex cursor-pointer items-center gap-4 w-full relative"
                      >
                        <img
                          className="w-12 h-12 rounded"
                          src={
                            item.thumbnailUrl
                              ? `${process.env.REACT_APP_MEDIA_URL}${item.thumbnailUrl}`
                              : Thumnail
                          }
                          alt=""
                        />
                        <div>
                          <div className="text-Gray05 MCaption12">
                            {item.format.label}
                          </div>
                          <div className="MBody18 text-Gray09 max-w-[223px] text-ellipsis overflow-hidden whitespace-nowrap">
                            {item.title}
                          </div>
                        </div>
                      </div>
                      <div>
                        {item.productionId === accordionOpenId && (
                          <div
                            className={`max-h-[500px] overflow-y-auto bg-Gray01 mt-4 py-2 px-5 border-t border-Gray03`}
                          >
                            {productWidthCastingCallData?.pages[0].data
                              .content?.length !== 0 ? (
                              <div className="flex flex-col gap-4">
                                {productWidthCastingCallData?.pages.map(
                                  (item: any, i) => {
                                    return item.data.content.map(
                                      (pItem: any) => {
                                        // 작품을 클릭해서 열면 나오는 공고
                                        return (
                                          <div
                                            key={pItem?.castingCallId}
                                            onClick={() => {
                                              setSelectedCastingCall(
                                                pItem?.castingCallId
                                              );
                                            }}
                                            className="cursor-pointer"
                                          >
                                            <div
                                              className={`BBody16 mb-2 max-w-[333px] text-ellipsis overflow-hidden whitespace-nowrap ${pItem.castingCallId ===
                                                selectedCastingCall
                                                ? "text-Blue04"
                                                : "text-Gray09 "
                                                }`}
                                            >
                                              {pItem.title}
                                            </div>
                                            <div className="text-Gray05 RBody14">
                                              {pItem.deadlineDate ||
                                                "캐스팅 확정 시"}{" "}
                                              마감
                                            </div>
                                          </div>
                                        );
                                      }
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              <div className="RBody16">
                                캐스팅 중인 공고가 없어요.
                              </div>
                            )}
                            <div ref={ProductinWithCastingCallRef} />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
                }
                <div ref={ProductRef} />
              </div>
            </div>
            <ButtonBackground>
              <div className="flex gap-4 w-full">
                <Button
                  onClick={() => {
                    setSelectCastingCall(false);
                  }}
                  text={"닫기"}
                  className="w-full text-Gray05 flex-1 bg-Gray03 "
                />
                <Button
                  disabled={selectedCastingCall === null}
                  onClick={() => {
                    handleShortlist();
                  }}
                  text={"이동하기"}
                  className="w-full bg-Blue04 flex-1 text-Gray00 MBody16"
                />
              </div>
            </ButtonBackground>
          </BottomPopup>
        )}
        {selectMode && (
          <BottomButtonPopup
            onClose={() => {
              setSelectMode(false);
            }}
          >
            <div
              onClick={() => {
                if (selectedList.length !== 0) {
                  setSelectCastingCall(true);
                  setSelectedCastingCall(null);
                  setAccordionOpenId(null);
                } else {
                  Toast.error("프로필을 선택해주세요");
                }
              }}
              className="flex flex-col pt-2 justify-center items-center MBody14 cursor-pointer text-Gray00"
            >
              <SelectCastingCall />
              <div>공고선택</div>
            </div>
          </BottomButtonPopup>
        )}
      </div >
    </>
  );
};

export default ReceivedProfile;
