import useOutSideClick from "hooks/onOutSideClick";
import React, { useRef } from "react";
import { createPortal } from "react-dom";
import { ReactComponent as XButton } from "assets/xbutton.svg";

interface ModalType {
  className?: string;
  children?: React.ReactNode;
  onClose?: () => void;
  onClick?: () => void;
}

function ModalContainer({ children }: { children: React.ReactNode }) {
  return createPortal(<>{children}</>, document.getElementById("modal")!);
}

const ImageModal = ({ className, children, onClose, onClick }: ModalType) => {
  const modalRef = useRef(null);

  const handleClose = () => {
    onClose?.();
  };

  useOutSideClick(modalRef, handleClose);
  return (
    <ModalContainer>
      <div className="fixed w-full h-full top-0 left-0 bg-Gray09 z-[9999]">
        <div className="flex text-Gray00 w-full px-5 pt-5 items-center justify-between">
          <div />
          <XButton className="cursor-pointer" onClick={onClick} />
        </div>
        <div
          ref={modalRef}
          className={`rounded-xl absolute max-w-screen w-full left-2/4 top-2/4 transform -translate-x-2/4 -translate-y-2/4 ${className} flex items-center justify-center`}
        >
          {children}
        </div>
      </div>
    </ModalContainer>
  );
};

export default ImageModal;
