import { AxiosError, AxiosResponse } from "axios";
import { Toast } from "hooks/useToast";
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from "react-query";
import { useLocation } from "react-router";
import { useSetRecoilState } from "recoil";
import { authInfo, loginCheck } from "store/auth/atom";
import {
  AuthInfoResponse,
  ChangePasswordPayload,
  FindUserIdPayload,
  KakaoSignup,
  Login,
  LoginCheckData,
  Signup,
  SignupData,
  VerifyCertNumberPayload,
  WithdrawalPayload,
} from "type/auth";
import { ERROR } from "type/common";
import requestToCastingVote from "utils/requestToCastingVote";

// 회원 가입
const signup = ({
  userId,
  certNum,
  password,
  name,
  phoneNumber,
  email,
  gender,
  birthDate,
  zonecode,
  roadAddress,
  sido,
  jibunAddress,
  sigungu,
  termsOfService,
  termsOfServiceAt,
  privacyPolicy,
  privacyPolicyAt,
  thirdPartySharing,
  thirdPartySharingAt,
  marketingOptIn,
  marketingOptInAt,
  recommendId,
  role,
  businessLicenseId,
  businessName,
  businessNumber,
}: Signup) => {
  return requestToCastingVote({
    method: "POST",
    url: "auth/signup",
    data: {
      userId,
      password,
      name,
      certNum,
      phoneNumber,
      email,
      gender,
      birthDate,
      zonecode,
      roadAddress,
      jibunAddress,
      sido,
      sigungu: sigungu,
      termsOfService: termsOfService,
      termsOfServiceAt: termsOfServiceAt,
      privacyPolicy: privacyPolicy,
      privacyPolicyAt: privacyPolicyAt,
      thirdPartySharing: thirdPartySharing,
      thirdPartySharingAt: thirdPartySharingAt,
      marketingOptIn: marketingOptIn,
      marketingOptInAt: marketingOptInAt,
      recommendId: recommendId,
      role: role,
      businessName: businessName,
      businessNumber: businessNumber,
      businessLicenseId: businessLicenseId,
    },
  });
};
export const useSignupMutation = (
  params: Signup
): UseMutationResult<AxiosResponse<SignupData>, AxiosError<ERROR>, Signup> => {
  const signupMutation = useMutation<
    AxiosResponse<SignupData>,
    AxiosError<ERROR>,
    Signup
  >(["signup"], {
    mutationFn: signup,
    onError: (error) => {
      Toast.error(error.response?.data.message);
    },
  });

  return signupMutation;
};

// 카카오 회원가입
const kakaoSignup = ({
  userId,
  certNum,
  password,
  name,
  phoneNumber,
  email,
  gender,
  birthDate,
  zonecode,
  roadAddress,
  sido,
  jibunAddress,
  sigungu,
  termsOfService,
  termsOfServiceAt,
  privacyPolicy,
  privacyPolicyAt,
  thirdPartySharing,
  thirdPartySharingAt,
  marketingOptIn,
  marketingOptInAt,
  recommendId,
  role,
  businessLicenseId,
  businessName,
  businessNumber,
  oAuthType,
  kakaoId,
}: KakaoSignup) => {
  return requestToCastingVote({
    method: "POST",
    url: "auth/kakao/signup",
    data: {
      userId,
      password,
      name,
      certNum,
      phoneNumber,
      email,
      gender,
      birthDate,
      zonecode,
      roadAddress,
      jibunAddress,
      sido,
      sigungu: sigungu,
      termsOfService: termsOfService,
      termsOfServiceAt: termsOfServiceAt,
      privacyPolicy: privacyPolicy,
      privacyPolicyAt: privacyPolicyAt,
      thirdPartySharing: thirdPartySharing,
      thirdPartySharingAt: thirdPartySharingAt,
      marketingOptIn: marketingOptIn,
      marketingOptInAt: marketingOptInAt,
      recommendId: recommendId,
      role: role,
      businessName: businessName,
      businessNumber: businessNumber,
      businessLicenseId: businessLicenseId,
      oAuthType,
      kakaoId,
    },
  });
};
export const useKakaoSignupMutation = (
  params: KakaoSignup
): UseMutationResult<
  AxiosResponse<SignupData>,
  AxiosError<ERROR>,
  KakaoSignup
> => {
  const signupMutation = useMutation<
    AxiosResponse<SignupData>,
    AxiosError<ERROR>,
    KakaoSignup
  >(["signup"], {
    mutationFn: kakaoSignup,
    onError: (error) => {
      Toast.error(error.response?.data.message);
    },
  });

  return signupMutation;
};

// 로그인
const login = ({ userId, password, remember }: Login) => {
  return requestToCastingVote({
    method: "POST",
    url: "auth/login",
    data: {
      userId: userId,
      password: password,
      remember: remember,
    },
  });
};
export const useLoginMutation = (
  params: Login
): UseMutationResult<
  AxiosResponse<LoginCheckData>,
  AxiosError<ERROR>,
  Login
> => {
  const loginMutation = useMutation<
    AxiosResponse<LoginCheckData>,
    AxiosError<ERROR>,
    Login
  >({
    mutationFn: login,
    onError: (error) => {
      Toast.error(error.response?.data.message);
    },
  });

  return loginMutation;
};

//마이페이지
const fetchAuthInfo = (): Promise<AxiosResponse<AuthInfoResponse>> => {
  return requestToCastingVote({ method: "GET", url: "/auth/info" });
};
export const useAuthInfo = (): UseQueryResult<
  AxiosResponse<AuthInfoResponse>,
  AxiosError<ERROR>
> => {
  const location = useLocation();
  const setAuth = useSetRecoilState(authInfo);
  const setLogin = useSetRecoilState(loginCheck);
  const updatedUserInfo = localStorage.getItem("user");
  let userInfo = updatedUserInfo ? JSON.parse(updatedUserInfo) : null;
  const login = userInfo?.id && location.search !== "?code=4001";
  return useQuery("authInfo", fetchAuthInfo, {
    enabled: !!login,
    onSuccess: (data) => {
      const AUTH = data?.data?.data;
      const ID = AUTH.memberId;
      const NAME = AUTH.name;
      const ROLE = AUTH.role.label;
      const userInfo = {
        id: ID,
        username: NAME,
        role: ROLE,
      };
      localStorage.clear();
      const userJson = JSON.stringify(userInfo);
      localStorage.setItem("user", userJson);
      setAuth(AUTH);
      setLogin(true);
    },
    onError: (err) => {
      localStorage.clear();
      Toast.error(err.response?.data.message);
    },
  });
};

// 인증 번호 요청 함수
const requestCertNumber = async (phoneNumber: string) => {
  const response = await requestToCastingVote({
    method: "POST",
    url: "/cert/request",
    data: { phoneNumber },
  });
  return response.data;
};
export const useRequestCertNumber = () => {
  return useMutation({
    mutationFn: requestCertNumber,
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};

// 인증 번호 검증 함수
const verifyCertNumber = async ({
  phoneNumber,
  certNum,
}: VerifyCertNumberPayload) => {
  const response = await requestToCastingVote({
    method: "POST",
    url: "/cert/verify",
    data: { phoneNumber, certNum },
  });
  return response.data;
};
export const useVerifyCertNumber = () => {
  return useMutation({
    mutationFn: verifyCertNumber,
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};

// 아이디 찾기 함수
const findUserId = (payload: FindUserIdPayload) => {
  return requestToCastingVote({
    method: "POST",
    url: "auth/find/userId",
    data: payload,
  });
};

export const useFindUserId = () => {
  return useMutation(findUserId, {
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};

// 비밀번호 변경 함수
const changePassword = (payload: ChangePasswordPayload) => {
  return requestToCastingVote({
    method: "POST",
    url: "/auth/change/password",
    data: payload,
  });
};
export const useChangePassword = () => {
  return useMutation({
    mutationFn: changePassword,
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};

// 회원탈퇴
const deleteMember = (payload: WithdrawalPayload) => {
  return requestToCastingVote({
    method: "DELETE",
    url: "/members",
    data: payload,
  });
};

export const useDeleteMember = () => {
  return useMutation(deleteMember, {
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};
