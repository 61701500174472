import { memo, useEffect, useState } from "react";

interface TimerProps {
  onTimerEnd: () => void;
  className?: string;
}

export const Timer = memo(({ onTimerEnd, className }: TimerProps) => {
  const MINUTES_IN_MS = 1 * 60 * 1000;
  const INTERVAL = 1000;
  const [timeLeft, setTimeLeft] = useState<number>(MINUTES_IN_MS);

  const minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(
    2,
    "0"
  );
  const seconds = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, "0");

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimerEnd();
      return;
    }
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - INTERVAL);
    }, INTERVAL);

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft, onTimerEnd]);

  return (
    <div className={`text-Blue04 RBody16 ${className}`}>
      {minutes}:{seconds}
    </div>
  );
});
