import React from "react";

interface ContentWrapType {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
}

const ContentWrap = ({ title, subTitle, children }: ContentWrapType) => {
  return (
    <div className="w-full">
      <div className="flex items-center">
        <div className="BBody18 text-Gray09">{title}</div>
        {subTitle && (
          <div className="RCaption10 text-Blue04 ml-1">{subTitle}</div>
        )}
      </div>
      <div className="pt-4 flex flex-col items-start gap-10 min-w-full">
        {children}
      </div>
    </div>
  );
};

export default ContentWrap;
