import { useEffect, useState } from "react";
import BottomPopup from "components/BottomPopup";
import ButtonBackground from "components/ButtonBackground";
import { LineButton } from "components/LineButton";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useInView } from "react-intersection-observer";
import { StaffCastingOfferData } from "type/castingOffer";
import { useGetManageOffersSend } from "api/Manage";

interface ModalProps {
  item: StaffCastingOfferData;
  onClose: () => void;
}

const ManageSend = () => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useGetManageOffersSend({});
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const [selectedItem, setSelectedItem] =
    useState<StaffCastingOfferData | null>(null);
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  if (isLoading) return null;

  const suggestList =
    data?.pages.flatMap((item: any) => item.data.content) || [];

  const groupByDate = (list: any[]) => {
    return list.reduce((acc, item) => {
      const date = item.createdAt.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };

  const groupedByDate = groupByDate(suggestList);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}월 ${date.getDate()}일`;
  };

  const SuggestionModal = ({ item, onClose }: ModalProps) => (
    <BottomPopup onClose={onClose}>
      <div className="BBody20 text-Gray09">
        {item.profileName}님께 {item.castingCallTitle} 캐스팅 제안을 보냈어요
      </div>
      <div className="bg-Gray01 mt-4 RBody16 w-full rounded-[10px] p-4">
        {item.description}
      </div>
      <ButtonBackground>
        <LineButton
          onClick={onClose}
          className="w-full"
          variant="normal"
          size="L"
          text="닫기"
        />
      </ButtonBackground>
    </BottomPopup>
  );

  if (suggestList.length === 0) {
    return (
      <div className="min-h-screen bg-white">
        <HeaderWithBackButton title="보낸 제안" />
        <div className="mt-36 items-center text-center">
          <div className="text-Gray05 RBody14">보낸 제안이 없어요</div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <HeaderWithBackButton title="보낸 제안" />
      <div className="p-5">
        {Object.keys(groupedByDate).map((date) => (
          <div key={date} className="mb-6">
            <div className="text-gray-500 text-sm mb-2">{formatDate(date)}</div>
            <div>
              {groupedByDate[date].map((item: StaffCastingOfferData) => (
                <div
                  key={item.castingOfferId}
                  onClick={() => setSelectedItem(item)}
                  className="flex items-start justify-between cursor-pointer"
                >
                  <div className="flex gap-2">
                    <img
                      src={MEDIA_URL + item.thumbnailUrl}
                      alt="profile"
                      className="w-7 h-7 rounded-full object-cover bg-gray-200"
                    />
                    <div>
                      <div className="text-sm">
                        {item.profileName}님께 {item.castingCallTitle} 캐스팅
                        제안을 보냈어요
                      </div>
                    </div>
                  </div>
                  <svg
                    className="w-5 h-5 transform rotate-180"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M15 18l-6-6 6-6" />
                  </svg>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}

      {selectedItem && (
        <SuggestionModal
          item={selectedItem}
          onClose={() => setSelectedItem(null)}
        />
      )}
    </div>
  );
};

export default ManageSend;
