import React, { useState } from "react";

import { useProfileShare } from "api/Share/Share";
import { useSearchParams } from "react-router-dom";
import Skeleton from "components/Skeleton";
import ScrollToTop from "components/ScrollToTop";
import ArtistInfoCard from "./ArtistDetail/_components/ArtistInfoCard";
import ArtistAttachments from "./ArtistDetail/_components/ArtistAttachments";
import ArtistDesc from "./ArtistDetail/_components/ArtistDesc";
import ArtistFilmographies from "./ArtistDetail/_components/ArtistFilmographies";

const ArtistShared = () => {
  const [searchParams] = useSearchParams();
  const [tabId, setTabId] = useState(0);

  const profileTab = [
    { id: 0, title: "프로필" },
  ]

  const value = searchParams.get("value");
  const { data: ArtistData, isFetching } = useProfileShare(value!);

  console.log(ArtistData?.expiredAt)

  function formatDateTime(dateTimeString: string): string {
    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${year}년${month}월${day}일 ${hours}시${minutes}분까지`;
  }

  const expire = formatDateTime(ArtistData?.expiredAt!);

  return (
    <>
      {isFetching ? (
        <Skeleton type="PROFILE_DETAIL" />
      ) : (
        <div className="pb-24">
          <ScrollToTop />
          <div className="MCaption12 text-Blue04 pl-5 py-4">
            공유된 링크는 30일 동안 유효해요 ({expire})
          </div>
          <div className="w-full">
            <ArtistInfoCard
              artistName={ArtistData?.profileName!}
              artistImageUrl={ArtistData?.thumbnailUrl!}
              jobs={ArtistData?.jobs!}
              birth={ArtistData?.birthDate!}
              height={ArtistData?.basicInfo.height!}
              weight={ArtistData?.basicInfo.weight!}
              instagram={ArtistData?.snsInfo.instagram!}
              youtube={ArtistData?.snsInfo.youtube!}
              isSharedPage
            />
            <div className="border-b border-Gray-3 mt-4">
              <div className="px-5 flex items-start gap-4 BCaption14">
                {profileTab.map((item) => {
                  return (
                    <div key={item.id}
                      onClick={() => { setTabId(item.id) }}
                      className={`pb-4 cursor-pointer px-1 ${item.id === tabId ? "border-b-2 border-Gray09 text-Gray09" : "border-none text-Gray04"}`}>
                      {item.title}
                    </div>
                  )
                })}
              </div>
            </div>
            {tabId === 0 ? (
              <div>
                <ArtistAttachments
                  attachments={ArtistData?.attachments!}
                  videoLinks={ArtistData?.videoLinks!} />
                <ArtistDesc
                  hobbies={ArtistData?.hobbies!}
                  address={ArtistData?.basicInfo.address.sido!}
                  education={ArtistData?.basicInfo.education!}
                  agency={ArtistData?.basicInfo.agency!}
                  introduce={ArtistData?.introduce!}
                />
                {ArtistData?.filmographies?.length! > 0 &&
                  <ArtistFilmographies filmographies={ArtistData?.filmographies!} />
                }
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ArtistShared;
