import { useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { ReactComponent as Share } from "../assets/share.svg";
import { ReactComponent as ArrowLeft } from "assets/arrow_wh_right.svg";
import ScrollToTop from "components/ScrollToTop";
import Modal from "components/Modal";
import Button from "components/Button";
import { handleCopyClipBoard } from "utils/onCopyClipBoard";
import Insta from "../assets/Instagram_black.png";
import Youtube from "../assets/YouTube_black.png";
import YouTube from "react-youtube";
import BottomPopup from "components/BottomPopup";
import {
  useDeleteManageArtistProfile,
  useGetManageArtistProfileDetail,
} from "api/Manage";
import { useNavigate, useParams } from "react-router";
import { Toast } from "hooks/useToast";
import ImageModal from "components/ImageModal";
import { useShareLink } from "api/Share/Share";
import { LineButton } from "components/LineButton";
import { FillButton } from "components/FillButton";
import { TintButton } from "components/TintButton";
import ButtonBackground from "components/ButtonBackground";

const ArtistDetail = () => {
  const navigate = useNavigate();
  const params = useParams() as { artistId: string };

  const { data: artist } = useGetManageArtistProfileDetail(
    Number(params.artistId)
  );
  const ArtistData = artist?.data;

  const [swiper, setSwiper] = useState<SwiperClass>();
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [swiperModal, setSwiperModal] = useState<SwiperClass>();
  const [profileId, setProfileId] = useState<number | null>(null);
  const [imageDetail, setImageDetail] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [sendProfile, setSendProfile] = useState(false);

  const handleOpenNewTab = (url: string) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };
  const deleteProfile = useDeleteManageArtistProfile();

  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  const handlePrev = () => {
    swiperModal?.slidePrev();
  };
  const handleNext = () => {
    swiperModal?.slideNext();
  };

  const calculateAge = () => {
    const currentYear = new Date().getFullYear();
    const calculatedAge =
      currentYear - Number(ArtistData?.birthDate?.split("-")[0]);
    return calculatedAge;
  };

  const groupedByYear = ArtistData?.filmographies.reduce((acc: any, item) => {
    const year = item.releaseYear;
    if (!acc[year]) {
      acc[year] = [];
    }

    acc[year].push(item);

    acc[year].sort((a: any, b: any) => a.orderNumber - b.orderNumber);

    return acc;
  }, {});

  const filteredAtt =
    ArtistData &&
    ArtistData.attachments
      .filter((attachment) => attachment.type.code !== "ADDITIONAL_VIDEO")
      .sort((a, b) => a.orderNumber - b.orderNumber);

  const opt = {
    height: "203",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const handleDeleteProfile = () => {
    setSendProfile(true);
  };

  const handleCloseModal = () => {
    setSendProfile(false);
  };

  const handleRequestDeleteProfile = () => {
    if (!artist) return;

    const profileId = ArtistData?.profileId!;

    deleteProfile.mutate(
      { profileId },
      {
        onSuccess: () => {
          Toast.success("프로필이 삭제되었습니다.");
          navigate("/manage/artist", { replace: true });
        },
        onError: () => {
          Toast.error("프로필 삭제에 실패했습니다. 다시 시도해주세요.");
        },
      }
    );
  };

  const artistInfo = [
    { id: 0, type: "거주지", data: ArtistData?.basicInfo.address.sido },
    { id: 1, type: "학력", data: ArtistData?.basicInfo.education },
    { id: 2, type: "매니저/소속사", data: ArtistData?.basicInfo.agency },
  ];

  const filteredArtistInfo = artistInfo.filter(
    (item) => item.data != null && item.data !== ""
  );

  const { data: link } = useShareLink({
    id: profileId,
    type: "PROFILE",
    period: "MONTH",
  });

  return (
    <div>
      <HeaderWithBackButton title="">
        <Share
          onClick={() => {
            setCopyModal(true);
            setProfileId(ArtistData?.profileId!);
          }}
          className="cursor-pointer"
        />
      </HeaderWithBackButton>
      <div className="flex gap-2 mb-4 pt-5 px-5">
        <LineButton
          size="M"
          className="w-full"
          variant="delete"
          onClick={handleDeleteProfile}
          text="프로필 삭제"
        />
        <LineButton
          size="M"
          className="w-full"
          variant="normal"
          onClick={() =>
            navigate(`/manage/artist/${ArtistData?.profileId}/edit`)
          }
          text="프로필 수정"
        />
      </div>

      <div className="w-full h-3 bg-Gray01 my-5" />
      <div className="flex px-5 pb-24 flex-col justify-center items-start">
        <ScrollToTop />
        {copyModal && (
          <Modal
            onClose={() => {
              setProfileId(null);
              setCopyModal(false);
            }}
            title="공유하기"
            subTitle="공유된 링크는 30일 동안 유효해요."
            closeButton
          >
            <Button
              onClick={() => {
                handleCopyClipBoard(
                  `${process.env.REACT_APP_URL}/artist/share?value=${link?.data.value}&type=PROFILE`
                );
                setCopyModal(false);
              }}
              className="w-full border border-Gray03 text-Gray05"
              text={"링크 복사하기"}
            />
          </Modal>
        )}
        {imageDetail && (
          <ImageModal onClose={() => setImageDetail(false)} className="w-full">
            <Swiper
              initialSlide={swiperIndex}
              onSwiper={(e: SwiperClass) => {
                setSwiperModal(e);
              }}
              slidesPerView={1}
              spaceBetween={8}
              className="w-full relative"
            >
              {filteredAtt?.map((item, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    onClick={() => {
                      setImageDetail(false);
                    }}
                    className="relative my-auto mx-auto"
                  >
                    {MEDIA_URL && (
                      <div className="w-full h-auto">
                        <img
                          src={`${MEDIA_URL}${item.url}`}
                          alt="IMAGES"
                          className="mx-auto w-full object-contain max-h-[90vh]"
                        />
                      </div>
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <ArrowLeft
              onClick={() => {
                handlePrev();
              }}
              className="absolute top-2/4 left-5 z-50 rotate-180 cursor-pointer"
            />
            <ArrowLeft
              onClick={() => {
                handleNext();
              }}
              className="absolute top-2/4 right-5 z-50 cursor-pointer"
            />
          </ImageModal>
        )}

        <div className="w-full flex flex-col gap-2">
          <div>
            <div className="flex items-center gap-2">
              <div className="BBody20">{ArtistData?.profileName}</div>
              {ArtistData?.snsInfo.instagram && (
                <img
                  onClick={() => {
                    if (
                      ArtistData?.snsInfo.instagram.startsWith(
                        "https://www.instagram.com/"
                      )
                    ) {
                      handleOpenNewTab(ArtistData?.snsInfo.instagram!);
                    } else {
                      handleOpenNewTab(
                        `https://www.instagram.com/${ArtistData?.snsInfo
                          .instagram!}`
                      );
                    }
                  }}
                  className="cursor-pointer"
                  src={Insta}
                  alt="instagram"
                />
              )}
              {ArtistData?.snsInfo.youtube && (
                <img
                  onClick={() => {
                    handleOpenNewTab(ArtistData?.snsInfo.youtube!);
                  }}
                  className="cursor-pointer "
                  src={Youtube}
                  alt="youtube"
                />
              )}
            </div>
            {ArtistData?.jobs?.length! > 0 && (
              <div className="BBody14 text-Gray05 flex flex-wrap items-center">
                {ArtistData?.jobs.join(", ")}
              </div>
            )}
          </div>

          <div className="flex mb-2 items-center RBody16 gap-2">
            <div>{`${
              ArtistData?.birthDate?.split("-")[0]
            }년생 (${calculateAge()}세)`}</div>
            <div>{`${ArtistData?.basicInfo.height}cm`}</div>
            <div>{`${ArtistData?.basicInfo.weight}kg`}</div>
          </div>
          <div className="flex flex-col space-y-2">
            {filteredAtt?.length ? (
              <div className="relative">
                <div className="absolute right-4 top-4 MBody14 bg-Gray08/80 z-50 text-Gray00 rounded-full h-7 px-2 py-1">
                  <span>{swiperIndex + 1}</span>
                  <span>{"/"}</span>
                  <span>{filteredAtt?.length}</span>
                </div>
                <Swiper
                  onActiveIndexChange={(e: SwiperClass) =>
                    setSwiperIndex(e.realIndex)
                  }
                  onSwiper={(e: SwiperClass) => {
                    setSwiper(e);
                  }}
                  spaceBetween={8}
                  slidesPerView={1}
                >
                  {filteredAtt?.map((item, idx) => {
                    return (
                      <SwiperSlide
                        onClick={() => {
                          setImageDetail(true);
                          setSwiperIndex(idx);
                        }}
                        key={idx}
                      >
                        <div className="w-full">
                          {MEDIA_URL && (
                            <img
                              src={`${MEDIA_URL}${item.url}`}
                              alt="IMAGES"
                              className="w-full aspect-4/5 object-cover rounded-[10px]"
                            />
                          )}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            ) : null}
            {ArtistData?.attachments?.map((item) => {
              if (item.type.code === "ADDITIONAL_VIDEO") {
                return (
                  <video
                    key={item.id}
                    controls
                    className="w-full h-[186px] rounded-[10px]"
                  >
                    {MEDIA_URL && <source src={`${MEDIA_URL}${item.url}`} />}
                  </video>
                );
              }
              return null;
            })}
            {ArtistData?.videoLinks?.map((item) => {
              let link = "";

              if (item.link.startsWith("https://www.youtube.com")) {
                link = item.link.split("watch?v=")[1];
              } else {
                link = item.link.split("?")[0].split("/")[3];
              }
              return (
                <YouTube
                  key={item.orderNumber}
                  className="w-full"
                  opts={opt}
                  iframeClassName="youtubeRounded"
                  videoId={link}
                />
              );
            })}
          </div>
        </div>
        <div className="flex-1 w-full">
          <div className="flex flex-col gap-10 items-start">
            {ArtistData?.hobbies?.length! > 0 && (
              <>
                <hr className="w-full h-px mt-10 bg-Gray03" />
                <div className="flex flex-col w-[360px]">
                  <div className="RBody14 text-Gray05">특기</div>
                  <div className="MBody16 flex items-center flex-wrap">
                    {ArtistData?.hobbies.map((item) => item.name).join("/ ")}
                  </div>
                </div>
              </>
            )}
            {ArtistData?.hobbies.length! === 0 &&
              filteredArtistInfo?.length !== 0 && (
                <hr className="w-full h-px mt-10 bg-Gray03" />
              )}
            {artistInfo.map((item) => {
              if (item?.data)
                return (
                  <div key={item.id} className="flex flex-col">
                    <div className="RBody14 text-Gray05">{item.type}</div>
                    <div className="MBody16">{item.data}</div>
                  </div>
                );
            })}
          </div>
          {ArtistData?.introduce !== "" && (
            <>
              <hr className="w-full h-px my-10 bg-Gray03" />
              <div className="w-full flex flex-col gap-2">
                <div className="RBody16 text-Gray05">자기소개</div>
                <div className="MBody16 max-h-[336px] whitespace-pre-wrap overflow-y-scroll">
                  {ArtistData?.introduce}
                </div>
              </div>
            </>
          )}
          {ArtistData?.filmographies?.length !== 0 && (
            <>
              <hr className="w-full h-px my-10 bg-Gray03" />
              <div className="w-full flex flex-col gap-2">
                <div className="RBody16 text-Gray05">필모그래피</div>
                {ArtistData ? (
                  <div className="flex flex-col gap-4">
                    {Object.keys(groupedByYear)
                      .sort((a, b) => Number(b) - Number(a))
                      ?.map((year) => (
                        <div className="flex items-start gap-4" key={year}>
                          <div className="RBody14 text-Gray09">{year}</div>
                          <div className="flex flex-col items-baseline gap-4">
                            {groupedByYear[year].map((item: any) => (
                              <div key={item.id}>
                                <div className="RBody14 text-Gray05">
                                  {item?.type?.label}
                                </div>
                                <div className="BBody16 mt-2 mb-1">
                                  {item.title}
                                </div>
                                <div className="BBody14 flex gap-1 text-Gray05 items-center">
                                  <div>{item?.role?.label}</div>•
                                  <div>{item?.description}</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {sendProfile && (
        <BottomPopup onClose={handleCloseModal} className="pb-24">
          <div className="flex flex-col gap-4">
            <div className="BBody20">프로필을 삭제할까요?</div>
            <div className="RBody16 text-Gray05">
              삭제하면 더 이상 캐스팅을 진행하거나 아티스트 탭에서 프로필을
              확인할 수 없어요. <br />
              스탭 회원의 받은 프로필 내역에서는 프로필이 지워지지 않아요.
            </div>
            <ButtonBackground>
              <FillButton
                variant="Secondary"
                size="L"
                className="w-full"
                onClick={handleCloseModal}
                text="닫기"
              />
              <TintButton
                variant="delete"
                size="L"
                className="w-full"
                onClick={handleRequestDeleteProfile}
                text="삭제하기"
              />
            </ButtonBackground>
          </div>
        </BottomPopup>
      )}
    </div>
  );
};

export default ArtistDetail;
