import React, { useState, useEffect } from "react";

interface RangeInputType {
  min: number;
  max: number;
  step?: number;
  priceGap: number;
  onChange?: (range: { minValue: number; maxValue: number }) => void;
}

const RangeInput: React.FC<RangeInputType> = ({
  min,
  max,
  step = 5,
  priceGap,
  onChange,
}) => {
  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.min(Number(e.target.value), maxValue - priceGap);
    setMinValue(value);
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(Number(e.target.value), minValue + priceGap);
    setMaxValue(value);
  };

  useEffect(() => {
    onChange?.({ minValue, maxValue });
  }, [minValue, maxValue]);

  return (
    <div className="relative flex items-center w-full">
      <div className="absolute RBody14 -top-[40px] text-Blue04 left-0">
        {min === minValue && max === maxValue
          ? "전체"
          : `${minValue}~${maxValue}`}
      </div>
      <div className="absolute w-full h-1 bg-Gray03 rounded-full" />
      {min === minValue && max === maxValue ? (
        ""
      ) : (
        <div
          className="absolute h-1 bg-Blue04 rounded-full"
          style={{
            left: `${((minValue - min) / (max - min)) * 100}%`,
            right: `${100 - ((maxValue - min) / (max - min)) * 100}%`,
          }}
        />
      )}
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={minValue}
        onChange={handleMinChange}
        className={`rangeBar ${
          min === minValue && max === maxValue
            ? "[&::-webkit-slider-thumb]:bg-Gray00 [&::-webkit-slider-thumb]:border [&::-webkit-slider-thumb]:border-Gray03"
            : "[&::-webkit-slider-thumb]:bg-Blue04"
        }`}
      />
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={maxValue}
        onChange={handleMaxChange}
        className={`rangeBar ${
          min === minValue && max === maxValue
            ? "[&::-webkit-slider-thumb]:bg-Gray00 [&::-webkit-slider-thumb]:border [&::-webkit-slider-thumb]:border-Gray03"
            : "[&::-webkit-slider-thumb]:bg-Blue04"
        }`}
      />
      <div className="RCaption14 text-Gray05">
        <div className="absolute top-1 mt-2 left-1">
          <div className="w-px h-2 mx-auto bg-Gray03" />
          <div>{min}</div>
        </div>
        <div className="absolute top-1 mt-2 left-2/4 flex text-Gray05 flex-col justify-center -translate-x-2/4">
          <div className="w-px h-2 mx-auto bg-Gray03" />
          <div>{max / 2}</div>
        </div>
        <div className="absolute top-1 mt-2 -right-[4px] text-Gray05">
          <div className="w-px h-2 mx-auto bg-Gray03" />
          <div>{max}</div>
        </div>
      </div>
    </div>
  );
};

export default RangeInput;
