import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

interface OptionSelectBoxTYpe {
  className?: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const OptionSelectBox = ({
  className,
  children,
  onClose,
}: OptionSelectBoxTYpe) => {
  const boxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      ref={boxRef}
      className={`absolute border bg-Gray00 border-Gray03 z-50 rounded-[10px] right-0 top-[22px] p-2 shadow-[0px_4px_10px_0px_rgba(22,44,86,0.08)] ${className}`}
    >
      <div className="optionSelectBoxDiv">{children}</div>
    </div>
  );
};

export default OptionSelectBox;
