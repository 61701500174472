import React, { useEffect, useState } from "react";

interface CircularTimerProps {
  stayTime: number;
  onTimePassed: () => void;
  size?: number;
  className?: string;
}

const ReviewTimer: React.FC<CircularTimerProps> = ({
  stayTime,
  className,
  onTimePassed,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();
    const interval = setInterval(() => {
      const elapsedTime = (Date.now() - startTime) / 1000;
      const percentage = Math.min((elapsedTime / stayTime) * 100, 100);

      setProgress(percentage);

      if (percentage >= 100) {
        clearInterval(interval);
        if (onTimePassed) {
          onTimePassed();
        }
      }
    }, 50);
    return () => clearInterval(interval);
  }, [stayTime]);

  return (
    <div
      className={`fixed max-w-[400px] left-2/4 -translate-x-2/4 bottom-0 visible bg-Gray03 w-full h-4 z-50 ${className}`}
    >
      <div
        className="h-full bg-Blue04 transition-all duration-100"
        style={{
          width: `${progress}%`,
        }}
      ></div>
    </div>
  );
};

export default ReviewTimer;
