import React from "react";
import { usePaymentHistory } from "api/Payment/Payment";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import PointCard from "components/PointCard";
import { useNavigate } from "react-router";

const PointChargingHistory = () => {
  const navigate = useNavigate();

  const { data: myCharge } = usePaymentHistory({
    paymentCategory: "POINT",
    size: 24,
  });

  const ChargingHistory =
    myCharge?.pages.flatMap((item: any) => item.data.content) || [];
  return (
    <div>
      <HeaderWithBackButton title={"충전 내역"} />
      <div className="p-5 pb-24">
        {ChargingHistory.length !== 0 ? (
          <div className=" flex flex-col gap-4">
            {ChargingHistory.map((item) => {
              return (
                <PointCard
                  onClick={() => {
                    navigate(`/point/charge/${item.paymentId}`, {
                      state: {
                        cardName: item.cardName,
                        orderAmount: item.orderAmount,
                        orderId: item.orderId,
                        paymentDate: item.paymentDate,
                        paymentId: item.paymentId,
                        price: item.price,
                        canceled: item?.paymentStatus?.code === "CANCELED",
                      },
                    });
                  }}
                  canceled={item?.paymentStatus?.code === "CANCELED"}
                  price={`${item.price}원`}
                  point={item.price}
                  paymentDate={item.paymentDate}
                  key={item.paymentId}
                />
              );
            })}
          </div>
        ) : (
          <div className="text-Gray05 RBody14">충전내역이 없어요</div>
        )}
      </div>
    </div>
  );
};

export default PointChargingHistory;
