import React, { useEffect } from "react";

import Thumnail from "assets/thumnail.png";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import CastingRecruitCard from "components/CastingRecruitCard";
import { useNavigate, useParams } from "react-router-dom";
import { useProductionDetail } from "api/Production/Production";
import { useFetchProductionCastingCalls } from "api/CastingCall/CastingCall";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useInView } from "react-intersection-observer";

const StaffFilmoDetail = () => {
  const navigate = useNavigate();
  const { staffId } = useParams();
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const { data: filmo } = useProductionDetail(Number(staffId));
  const castingId = filmo?.data.data.productionId;
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useFetchProductionCastingCalls({ productionId: Number(castingId) });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const filmoData = filmo?.data.data;

  const filmInfo = [
    {
      id: 0,
      title: "분야",
      desc: filmoData?.format.label,
    },
    {
      id: 1,
      title: "감독",
      desc: filmoData?.directorName,
    },
    {
      id: 2,
      title: "제작사",
      desc: filmoData?.companyName,
    },
    {
      id: 3,
      title: "캐스팅",
      desc: filmoData?.castingDirectorName,
    },
  ];

  return (
    <>
      <HeaderWithBackButton title={""} />
      <div className="pt-5 px-5 pb-24">
        <div className="flex mb-4 items-center justify-between">
          <div className="BBody20">{filmoData?.title}</div>
          {/* <Bookmark /> */}
        </div>
        <div className="flex items-start gap-4">
          <div className="w-full flex-col gap-2 flex MBody14">
            {filmInfo.map((item) => {
              return (
                <div key={item.id} className="flex items-start gap-2">
                  <div className="w-[74px] text-Gray05">{item.title}</div>
                  <div className="flex-1">{item.desc}</div>
                </div>
              );
            })}
          </div>
          <img
            src={
              filmoData?.thumbnailUrl !== null
                ? `${process.env.REACT_APP_MEDIA_URL}${filmoData?.thumbnailUrl}`
                : Thumnail
            }
            className="max-w-[109px] object-cover rounded-[10px] flex-1 h-[136px]"
            alt="filmoDetailImage"
          />
        </div>
        <div>
          <div className="RBody16 whitespace-pre-wrap my-4 mb-10">
            {filmoData?.description}
          </div>
          <div>
            <div className="BBody18 mb-4">모집중인 캐스팅</div>
            <div className="flex flex-wrap gap-4">
              {data?.pages[0].data.content.length === 0 ? (
                <div className="mt-10 w-full text-center">
                  <div className="text-Gray05 RBody14">
                    모집중인 캐스팅이 없어요.
                  </div>
                </div>
              ) : (
                <>
                  {data?.pages?.map((item) =>
                    item.data.content.map((cast: any) => (
                      <CastingRecruitCard
                        key={cast.castingCallId}
                        title={cast.title}
                        type={cast?.castingCallRole.label}
                        gender={cast.gender.label}
                        age={`${cast.minAge}세 ~ ${cast.maxAge}세`}
                        onClick={() => {
                          navigate(`/casting/${cast.castingCallId}`, {
                            state: {
                              directorName: filmoData?.directorName,
                              companyName: filmoData?.companyName,
                              castingDirectorName:
                                filmoData?.castingDirectorName,
                            },
                          });
                        }}
                      />
                    ))
                  )}
                </>
              )}
            </div>
          </div>
          {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
        </div>
      </div>
    </>
  );
};

export default StaffFilmoDetail;
