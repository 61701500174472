import React, { useEffect } from "react";
import { useMainPick } from "api/Main";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useInView } from "react-intersection-observer";
import ArtistListCard from "components/ArtistListCard";
import { splitBirth } from "utils/onBirth";
import { useNavigate } from "react-router";

const ArtistPick = () => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const { data, fetchNextPage, hasNextPage } = useMainPick();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <>
      <HeaderWithBackButton title={"캐스팅보트 P!ck"} />
      <div className="pb-24 p-5">
        <div className="grid grid-cols-3 gap-y-4 gap-x-2">
          {data?.pages?.map((pagesData) => {
            return pagesData?.data?.content?.map((item: any, index: number) => {
              return (
                <ArtistListCard
                  key={index}
                  onClick={() => {
                    navigate(`/artist/${item?.profileId}`);
                  }}
                  isMembership
                  src={item.thumbnailUrl}
                  title={item.profileName}
                  subTitle={`${splitBirth(item?.birthDate)}`}
                  description={
                    item.productionTitle ? item.productionTitle : "미공개작품"
                  }
                />
              );
            });
          })}
        </div>
        {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
      </div>
    </>
  );
};

export default ArtistPick;
