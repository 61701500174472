import React, { useEffect, useState } from "react";

import HeaderWithBackButton from "components/HeaderWithBackButton";
import TitleWrap from "components/TitleWrap";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import { CreateCastingCallData } from "type/staff";
import DateInput from "components/DateInput";
import { today } from "utils/onToday";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCreateCastingCallMutation } from "api/CastingCall/CastingCall";
import { Toast } from "hooks/useToast";
import ImgUpload from "components/ImgUpload";

import { ReactComponent as XButton } from "assets/xbutton.svg";
import { CASTING_LIST, TYPE_LIST } from "utils/type";
import { useStaffProfileList } from "api/Member/Member";
import {
  useUploadFilesMutation,
  useUploadImagesMutation,
} from "api/Attachment/Attachment";
import ButtonBackground from "components/ButtonBackground";
import { ROLE_LABELS, TYPE_LABELS } from "components/SelectBox";

const CreateCastingCall = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const productionId = Number(searchParams.get("id"));
  const [castingCallData, setCastingCallData] = useState<CreateCastingCallData>(
    {
      productionId: null,
      type: "",
      title: "",
      description: "",
      deadlineDate: "",
      deadlineType: "",
      location: "",
      format: "",
      startDate: "",
      endDate: "",
      role: "",
      episodeInfo: "",
      minAge: null,
      maxAge: null,
      ageType: "",
      gender: "",
      preferentialTreatment: "",
      qualification: "",
      numberOfPositions: 0,
      fee: 0,
      attachments: [],
      stakeholders: [],
    }
  );

  const { data: staffList } = useStaffProfileList();

  const gender = [
    { id: 0, name: "남자", gender: "MALE" },
    { id: 1, name: "여자 ", gender: "FEMALE" },
    { id: 2, name: "성별무관", gender: "ALL" },
  ];

  useEffect(() => {
    setCastingCallData({
      ...castingCallData,
      productionId: state?.prodId ? state?.prodId : null,
    });
  }, [state?.prodId]);

  const staffProfileList = staffList?.data.data.content;

  const { mutate: uploadImages } = useUploadImagesMutation();
  const { mutate: uploadFile } = useUploadFilesMutation();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadImages(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.data.map(
            (
              item: {
                attachmentId: number;
                attachmentUrl: string;
              },
              idx: number
            ) => ({
              id: item.attachmentId,
              type: "THUMBNAIL",
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setCastingCallData({
            ...castingCallData,
            attachments: [...castingCallData.attachments!, ...updateItem],
          });
        },
      });
    }
  };
  const fileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadFile(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.data.map(
            (
              item: {
                attachmentId: number;
                attachmentUrl: string;
              },
              idx: number
            ) => ({
              id: item.attachmentId,
              type: "FILE",
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setCastingCallData({
            ...castingCallData,
            attachments: [...castingCallData.attachments!, ...updateItem],
          });
        },
      });
    }
  };

  const handleDeleteImage = (id: number) => {
    const updatedAttachments = castingCallData.attachments?.filter(
      (item) => item.id !== id
    );
    setCastingCallData({
      ...castingCallData,
      attachments: updatedAttachments,
    });
  };

  const handleDeleteFile = (id: number) => {
    const updatedAttachments = castingCallData.attachments?.filter(
      (item) => item.id !== id
    );
    setCastingCallData({
      ...castingCallData,
      attachments: updatedAttachments,
    });
  };

  const { mutate } = useCreateCastingCallMutation();

  const valid = (castingCallData: CreateCastingCallData) => {
    if (!castingCallData.title) return "공고제목은 필수입니다.";
    if (!state && !castingCallData.format) return "분야를 선택해주세요.";
    if (!castingCallData.gender) return "성별을 선택해주세요.";
    if (!castingCallData.minAge) return "최소나이 입력해주세요.";
    if (!castingCallData.maxAge) return "최대나이 입력해주세요.";
    return null;
  };

  const decisionMakers = castingCallData?.stakeholders!.filter(
    (stakeholder) => stakeholder.role === "DECISION_MAKER"
  );
  const participants = castingCallData?.stakeholders!.filter(
    (stakeholder) => stakeholder.role === "PARTICIPANT"
  );

  const removeStakeholder = (memberId: number) => {
    setCastingCallData((prevData) => ({
      ...prevData,
      stakeholders: prevData.stakeholders!.filter(
        (stakeholder) => stakeholder.memberId !== memberId
      ),
    }));
  };

  const result = staffProfileList?.filter(
    (a) =>
      !castingCallData?.stakeholders?.some((b) => a.memberId === b.memberId)
  );

  return (
    <>
      <HeaderWithBackButton title={state ? state?.prodTitle : "공고등록"} />
      <div className="py-5 px-5 pb-24">
        <div className="flex flex-col gap-10">
          <TitleWrap require title={"공고 제목"}>
            <Input
              type={"text"}
              value={castingCallData.title}
              onChange={(e) => {
                setCastingCallData({
                  ...castingCallData,
                  title: e.target.value,
                });
              }}
              placeholder="공고 제목"
            />
          </TitleWrap>
          {!state && (
            <>
              <TitleWrap title={"대표 사진 업로드"}>
                {castingCallData.attachments?.some(
                  (i) => i.type === "THUMBNAIL"
                ) ? (
                  castingCallData.attachments?.map((item, i) => {
                    if (item.type === "THUMBNAIL")
                      return (
                        <div key={item.id} className="relative">
                          <img
                            className="w-full h-[360px] object-cover"
                            src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                            alt="THUMBNAIL"
                          />
                          <XButton
                            onClick={() => {
                              handleDeleteImage(item.id);
                            }}
                            className="absolute cursor-pointer top-4 right-4"
                          />
                        </div>
                      );
                  })
                ) : (
                  <ImgUpload
                    accept="image/jpg,image/png,image/jpeg"
                    onChange={(e) => {
                      imgUpload(e);
                    }}
                    title="사진 업로드"
                    id={"THUMBNAIL"}
                  ></ImgUpload>
                )}
              </TitleWrap>
              <TitleWrap title={"분야"} require>
                <Dropdown
                  width="w-[360px]"
                  innerText={
                    TYPE_LABELS[castingCallData?.format || ""] || "선택"
                  }
                >
                  <div>
                    {TYPE_LIST.map((item) => {
                      return (
                        <li
                          onClick={() => {
                            setCastingCallData({
                              ...castingCallData,
                              format: item.role,
                            });
                          }}
                          key={item.id}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </div>
                </Dropdown>
              </TitleWrap>
            </>
          )}
          <div className="flex items-center gap-4">
            <TitleWrap require title={"배역"}>
              <Dropdown
                width="w-[360px]"
                innerText={ROLE_LABELS[castingCallData.role || ""] || "선택"}
              >
                <div>
                  {CASTING_LIST.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          setCastingCallData({
                            ...castingCallData,
                            role: item.role,
                          });
                        }}
                        key={item.id}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                </div>
              </Dropdown>
            </TitleWrap>
          </div>
          <div className="flex items-center gap-4">
            <TitleWrap title={"성별"} require>
              <Dropdown
                width="w-[172px]"
                innerText={
                  castingCallData.gender === "MALE"
                    ? "남자"
                    : castingCallData.gender === "FEMALE"
                    ? "여자"
                    : castingCallData.gender === "ALL"
                    ? "성별무관"
                    : "구분"
                }
              >
                <div>
                  {gender.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          setCastingCallData({
                            ...castingCallData,
                            gender: item.gender,
                          });
                        }}
                        key={item.id}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </div>
              </Dropdown>
            </TitleWrap>

            <TitleWrap require title={"나이"}>
              <div className="flex items-center">
                <Input
                  inputMode="numeric"
                  value={castingCallData.minAge}
                  onChange={(e) => {
                    const value = Number(e.target.value.replace(/[^0-9]/g, ""));
                    setCastingCallData({ ...castingCallData, minAge: value });
                  }}
                  onKeyDown={(e) => {
                    if (["e", "E", "+", "-", "."].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  type={"text"}
                />
                ~
                <Input
                  inputMode="numeric"
                  value={castingCallData.maxAge}
                  onChange={(e) => {
                    const value = Number(e.target.value.replace(/[^0-9]/g, ""));
                    setCastingCallData({ ...castingCallData, maxAge: value });
                  }}
                  type={"text"}
                />
              </div>
            </TitleWrap>
          </div>
          <hr />
          <TitleWrap title={"상세내용"}>
            <Textarea
              value={castingCallData.description}
              onChange={(e) => {
                setCastingCallData({
                  ...castingCallData,
                  description: e.target.value,
                });
              }}
              className="w-full h-[210px]"
              placeholder="상세내용"
              maxlength={500}
            />
          </TitleWrap>
          <TitleWrap
            subTitle="PDF파일만 올려주세요(최대 1개)"
            title={"첨부파일"}
          >
            {castingCallData.attachments?.some((i) => i.type === "FILE") ? (
              castingCallData.attachments?.map((item, idx) => {
                if (item.type === "FILE")
                  return (
                    <div key={item.id}>
                      <div
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}${item?.url}`,
                            "_blank",
                            "noopener, noreferrer"
                          );
                        }}
                        className="text-Blue04 cursor-pointer MBody16 py-3 text-center bg-Blue01 rounded-[10px] w-full"
                      >
                        첨부파일 미리보기
                      </div>
                      <div
                        onClick={() => {
                          handleDeleteFile(item.id);
                        }}
                        className="text-Blue04 border border-Blue04 cursor-pointer MBody16 mt-2 py-3 text-center bg-Gray00 rounded-[10px] w-full"
                      >
                        첨부파일 삭제
                      </div>
                    </div>
                  );
              })
            ) : (
              <ImgUpload
                onChange={(e) => {
                  fileUpload(e);
                }}
                title="파일 업로드"
                accept=".pdf"
                id={"file"}
              ></ImgUpload>
            )}
          </TitleWrap>
          <TitleWrap title={"마감일자"}>
            <DateInput
              value={castingCallData.deadlineDate}
              onChange={(e) => {
                setCastingCallData({
                  ...castingCallData,
                  deadlineDate: e.target.value,
                });
              }}
              min={today()}
              className="w-full"
            />
          </TitleWrap>
          <div className="flex items-start gap-4">
            <TitleWrap title={"후보 확정 권한 추가"}>
              <Dropdown width="w-[172px]" innerText={"선택"}>
                <div>
                  {result?.map((item, i) => {
                    return (
                      <li
                        onClick={() => {
                          const skateholder = {
                            memberId: item.memberId,
                            role: "DECISION_MAKER",
                            name: item.name,
                          };
                          setCastingCallData({
                            ...castingCallData,
                            stakeholders: [
                              ...castingCallData.stakeholders!,
                              skateholder,
                            ],
                          });
                        }}
                        key={item.memberId}
                        className="flex items-center max-w-[138px] gap-1 overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                        <div className="MBody16 text-Gray09">{item.name}</div>
                        <div className="MBody14 text-Gray05">{item.agency}</div>
                      </li>
                    );
                  })}
                </div>
              </Dropdown>
              <div
                className={`flex items-center flex-wrap gap-1 ${
                  decisionMakers.length !== 0 && "mt-2"
                }`}
              >
                {decisionMakers?.map((item, i) => {
                  return (
                    <div
                      onClick={() => {
                        removeStakeholder(item.memberId!);
                      }}
                      key={item.memberId}
                      className="flex cursor-pointer items-center py-1.5 px-2 bg-Gray02 rounded-[10px]"
                    >
                      <div className="MCaption12">{item.name}</div>
                      <XButton className="w-2.5 h-2.5" />
                    </div>
                  );
                })}
              </div>
            </TitleWrap>

            <TitleWrap title={"참조 관계자 추가"}>
              <Dropdown width="w-[172px]" innerText={"선택"}>
                <div>
                  {result?.map((item, i) => {
                    return (
                      <li
                        onClick={() => {
                          const skateholder = {
                            memberId: item.memberId,
                            name: item.name,
                            role: "PARTICIPANT",
                          };
                          setCastingCallData({
                            ...castingCallData,
                            stakeholders: [
                              ...castingCallData.stakeholders!,
                              skateholder,
                            ],
                          });
                        }}
                        key={item.memberId}
                        className="flex items-center max-w-[138px] gap-1 overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                        <div className="MBody16 text-Gray09">{item.name}</div>
                        <div className="MBody14 text-Gray05">{item.agency}</div>
                      </li>
                    );
                  })}
                </div>
              </Dropdown>
              <div
                className={`flex items-center flex-wrap gap-1 ${
                  participants.length !== 0 && "mt-2"
                }`}
              >
                {participants?.map((item, i) => {
                  return (
                    <div
                      onClick={() => {
                        removeStakeholder(item.memberId!);
                      }}
                      key={item.memberId}
                      className="flex cursor-pointer items-center py-1.5 px-2 bg-Gray02 rounded-[10px]"
                    >
                      <div className="MCaption12">{item.name}</div>
                      <XButton className="w-2.5 h-2.5" />
                    </div>
                  );
                })}
              </div>
            </TitleWrap>
          </div>
        </div>
        <ButtonBackground>
          <Button
            onClick={() => {
              const validError = valid(castingCallData);
              if (validError !== null) {
                Toast.error(validError);
              } else {
                mutate(castingCallData, {
                  onSuccess: (response) => {
                    navigate(`/casting/${response.data.data}`, {
                      replace: true,
                    });
                    Toast.success("캐스팅 공고가 등록되었어요");
                  },
                });
              }
            }}
            className="w-full h-12 bg-Blue04 text-white border-none"
            text={`등록하기`}
          ></Button>
        </ButtonBackground>
      </div>
    </>
  );
};

export default CreateCastingCall;
