import React from "react";
import { useEffect } from "react";

const WelcomePayClose = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://tstdpay.paywelcome.co.kr/stdjs/INIStdPay_close.js";
    script.charset = "UTF-8";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div>테스트</div>;
};

export default WelcomePayClose;
