import React, { useState } from "react";
import Chip from "components/Chip";
import Input from "components/Input";
import TitleWrap from "components/TitleWrap";
import { ReactComponent as ProfileInsta } from "assets/profileInsta.svg";
import { ReactComponent as ProfileYoutube } from "assets/profileYoutube.svg";
import Button from "components/Button";
import Textarea from "components/Textarea";
import Dropdown from "components/Dropdown";

import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import { ReactComponent as Delete } from "assets/delete.svg";
import { ReactComponent as CloseButton } from "assets/icon_close.svg";
import ImgUpload from "components/ImgUpload";
import YouTube from "react-youtube";
import { useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { Toast } from "hooks/useToast";
import { useCreateArtistProfileMutation } from "api/Profile/Profile";
import {
  useUploadImagesMutation,
  useUploadVideosMutation,
} from "api/Attachment/Attachment";
import { CASTING_LIST, JOB_LIST, TYPE_LIST } from "utils/type";
import { ARTIST_PROFILE } from "type/artist";
import DateInput from "components/DateInput";
import { today } from "utils/onToday";
import { useRecoilValue } from "recoil";
import { authInfo } from "store/auth/atom";
import ButtonBackground from "components/ButtonBackground";
import ContentWrap from "components/ContentWrap";
import { ROLE_LABELS, TYPE_LABELS } from "components/SelectBox";

const CreateArtistProfile = () => {
  const navigate = useNavigate();
  const auth = useRecoilValue(authInfo);
  const [hobbyItem, setHobbyItem] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");

  const [artistProfile, setArtistProfile] = useState<ARTIST_PROFILE>({
    name: "",
    job: "",
    jobs: [],
    sido: "",
    birthDate: auth?.birthDate,
    gender: auth?.gender?.code!,
    height: null,
    weight: null,
    education: "",
    rank: "NORMAL",
    agency: "",
    introduce: "",
    instagram: "",
    youtube: "",
    // hashtags: [],
    hobbies: [],
    filmographies: [],
    attachments: [],
    videoLinks: [],
  });
  const addFilmographyItem = () => {
    setArtistProfile((prevState) => ({
      ...prevState,
      filmographies: [
        ...prevState.filmographies!,
        {
          title: "",
          type: "",
          role: "",
          releaseYear: null,
          description: "",
          orderNumber: prevState.filmographies!.length,
        },
      ],
    }));
  };

  const removeFilmographyItem = (idxToRemove: number) => {
    setArtistProfile((pre) => ({
      ...pre,
      filmographies: pre.filmographies!.filter((_, i) => i !== idxToRemove),
    }));
  };

  const { mutate: uploadImages } = useUploadImagesMutation();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const files = e.target.files;

    if (files) {
      if (type === "ADDITIONAL_IMAGE") {
        const additionalImagesCount = artistProfile.attachments!.filter(
          (attachment) => attachment.type === "ADDITIONAL_IMAGE"
        ).length;

        const remainingSlots = 15 - additionalImagesCount;

        if (files.length > remainingSlots) {
          Toast.error(`최대 15개 업로드할 수 있어요.`);
          return;
        }
      }

      uploadImages(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");

          const uploadedItems = res.data.data.map(
            (item: { attachmentId: number; attachmentUrl: string }) => ({
              id: item.attachmentId,
              type: type,
              url: item.attachmentUrl,
            })
          );

          const updatedAttachments = [
            ...artistProfile.attachments!,
            ...uploadedItems,
          ];

          const thumbnail = updatedAttachments.filter(
            (item) => item.type === "THUMBNAIL"
          );
          const additionalImages = updatedAttachments.filter(
            (item) => item.type === "ADDITIONAL_IMAGE"
          );

          const reorderedThumbnail = thumbnail.map((item) => ({
            ...item,
            orderNumber: 0,
          }));

          const reorderedAdditionalImages = additionalImages.map(
            (item, index) => ({
              ...item,
              orderNumber: index + 1,
            })
          );

          const reorderedAttachments = [
            ...reorderedThumbnail,
            ...reorderedAdditionalImages,
          ];

          setArtistProfile({
            ...artistProfile,
            attachments: reorderedAttachments,
          });
        },
      });
    }
  };

  const { mutate: uploadVideos } = useUploadVideosMutation();

  const videoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const additionalVideosCount = artistProfile.attachments!.filter(
        (attachment) => attachment.type === "ADDITIONAL_VIDEO"
      ).length;

      const remainingSlots = 3 - additionalVideosCount;

      if (files.length > remainingSlots) {
        Toast.error("최대 3개까지 업로드 가능해요");
        return;
      }

      uploadVideos(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.data.map(
            (
              item: {
                attachmentId: number;
                attachmentUrl: string;
              },
              idx: number
            ) => ({
              id: item.attachmentId,
              type: "ADDITIONAL_VIDEO",
              orderNumber: additionalVideosCount + idx + 1, // 기존 개수 이후 순서 지정
              url: item.attachmentUrl,
            })
          );

          setArtistProfile({
            ...artistProfile,
            attachments: [...artistProfile.attachments!, ...updateItem],
          });
        },
      });
    }
  };

  const opt = {
    height: "171",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const { mutate } = useCreateArtistProfileMutation(artistProfile);

  const addVideoLink = (newLink: string, type: string) => {
    if (
      newLink.startsWith("https://www.youtube.com") ||
      newLink.startsWith("https://youtu.be/")
    ) {
      setArtistProfile((prevProfile) => {
        const currentVideoCount = prevProfile.videoLinks.length;

        if (currentVideoCount >= 3) {
          Toast.error("최대 3개까지 업로드 가능해요");
          return prevProfile;
        }

        const newOrderNumber = currentVideoCount;

        return {
          ...prevProfile,
          videoLinks: [
            ...prevProfile.videoLinks,
            {
              link: newLink,
              type: type,
              orderNumber: newOrderNumber,
            },
          ],
        };
      });
    } else {
      alert("잘못된 유튜브 링크입니다.");
    }
  };

  const handleDeleteImage = (id: number) => {
    const updatedAttachments = artistProfile.attachments!.filter(
      (item) => item.id !== id
    );
    setArtistProfile({ ...artistProfile, attachments: updatedAttachments });
  };

  const handleDeleteVideo = (link: string) => {
    const updatedVideos = artistProfile.videoLinks.filter(
      (item) => item.link !== link
    );
    setArtistProfile({ ...artistProfile, videoLinks: updatedVideos });
  };

  const valid = (data: ARTIST_PROFILE) => {
    const hasThumbnail = data?.attachments!.some(
      (attachment) => attachment.type === "THUMBNAIL"
    );
    if (!hasThumbnail) return "대표 프로필 이미지는 필수입니다.";
    if (data.jobs?.length === 0) return "직업을 선택해주세요.";
    if (!data.name) return "활동명을 입력해주세요.";
    if (data.height === 0) return "키를 입력해주세요.";
    if (data.weight === 0) return "몸무게를 입력해주세요.";
    if (
      artistProfile?.filmographies!?.some((filmography) => {
        return (
          !filmography.title ||
          !filmography.type ||
          !filmography.role ||
          filmography.releaseYear! === 0 ||
          !filmography.description
        );
      })
    )
      return "필모그래피는 빈칸없이 입력해주세요";
    return null;
  };

  const submitProfile = () => {
    mutate(artistProfile, {
      onSuccess: (data) => {
        if (data.data.code === "200") {
          Toast.success("프로필을 등록했어요");
          navigate("/artist", { replace: true });
          window.location.reload();
        }
      },
    });
  };

  return (
    <div>
      <HeaderWithBackButton title={`프로필 등록`} />
      <div className="pb-24 py-5">
        <div className="px-5 flex flex-col gap-10 items-start w-full">
          <ContentWrap title="사진 업로드">
            <TitleWrap
              title={"대표 프로필 사진 업로드"}
              subTitle="4:5 / 10MB 이하"
              require
            >
              {artistProfile?.attachments!.some(
                (item) => item.type === "THUMBNAIL"
              ) ? (
                artistProfile
                  ?.attachments!.filter((item) => item.type === "THUMBNAIL")
                  .map((item, idx) => (
                    <div key={item.id} className="relative">
                      <img
                        className="w-full h-[418px] object-cover rounded-[10px]"
                        src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                        alt="THUMBNAIL"
                      />
                      <CloseButton
                        onClick={() => {
                          handleDeleteImage(item.id);
                        }}
                        className="absolute cursor-pointer top-0 right-0"
                      />
                    </div>
                  ))
              ) : (
                <ImgUpload
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={(e) => {
                    imgUpload(e, "THUMBNAIL");
                  }}
                  title="프로필 사진 업로드"
                  id={"profileImg"}
                ></ImgUpload>
              )}
            </TitleWrap>
            <TitleWrap
              title={"추가 사진 업로드"}
              subTitle={`최대 15장 (${artistProfile?.attachments!.filter(
                (item) => item.type === "ADDITIONAL_IMAGE"
              )
                ? artistProfile?.attachments!.filter(
                  (item) => item.type === "ADDITIONAL_IMAGE"
                ).length
                : "0"
                }/15)`}
            >
              <ImgUpload
                multiple
                accept="image/jpg,image/png,image/jpeg"
                onChange={(e) => {
                  imgUpload(e, "ADDITIONAL_IMAGE");
                }}
                title="추가 사진 업로드"
                id={"ADDITIONAL_IMAGE"}
              ></ImgUpload>
              <div className="grid grid-cols-3 gap-1 items-center">
                {artistProfile
                  ?.attachments!.filter(
                    (item) => item.type === "ADDITIONAL_IMAGE"
                  )
                  .map((item, idx) => (
                    <div
                      key={item.id}
                      className={`relative  ${idx < 3 && "mt-2"} `}
                    >
                      <img
                        className="w-[117px] h-[117px] object-cover rounded-[10px]"
                        src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                        alt="ADDITIONAL_IMAGE"
                      />
                      <CloseButton
                        onClick={() => {
                          handleDeleteImage(item.id);
                        }}
                        className="absolute cursor-pointer top-0 right-0"
                      />
                    </div>
                  ))}
              </div>
            </TitleWrap>
          </ContentWrap>
          <ContentWrap
            title={"영상 업로드"}
            subTitle="연기영상/자유영상을 첨부하면 캐스팅 확률이 높아져요!"
          >
            <TitleWrap
              title={"영상 파일 첨부"}
              className="mb-4"
              subTitle="50mb/최대 3개"
            >
              <ImgUpload
                title="영상 파일 업로드"
                accept=".avi, .mov, .mp4"
                onChange={(e) => {
                  videoUpload(e);
                }}
                id={"ProfileVideo"}
              ></ImgUpload>
              {artistProfile?.attachments!.some(
                (item) => item.type === "ADDITIONAL_VIDEO"
              )
                ? artistProfile
                  ?.attachments!.filter(
                    (item) => item.type === "ADDITIONAL_VIDEO"
                  )
                  .map((item, idx) => (
                    <div key={item.id} className="relative mt-2 w-full">
                      <video
                        controls
                        className="w-full h-[186px] rounded-[10px]"
                      >
                        <source
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                        ></source>
                      </video>
                      <CloseButton
                        onClick={() => {
                          handleDeleteImage(item.id);
                        }}
                        className="absolute cursor-pointer top-0 right-0"
                      />
                    </div>
                  ))
                : ""}
            </TitleWrap>
            <TitleWrap title={"유튜브 링크"}>
              <div className="flex items-center gap-2 w-full">
                <Input
                  value={youtubeLink}
                  onChange={(e) => {
                    setYoutubeLink(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (youtubeLink !== "") {
                      if (e.key === "Enter") {
                        addVideoLink(youtubeLink, "YOUTUBE");
                        setYoutubeLink("");
                      }
                    }
                  }}
                  className="!w-[292px]"
                  placeholder="링크"
                  type={"text"}
                ></Input>
                <Button
                  onClick={() => {
                    if (youtubeLink !== "") {
                      addVideoLink(youtubeLink, "YOUTUBE");
                      setYoutubeLink("");
                    }
                  }}
                  className="min-w-[60px] flex-1 text-Blue04 bg-Blue01 !MBody16 border-none"
                  text="추가"
                ></Button>
              </div>
              {artistProfile.videoLinks.map((item, idx) => {
                let link = "";

                if (item.link.startsWith("https://www.youtube.com")) {
                  link = item.link.split("watch?v=")[1];
                } else {
                  link = item.link.split("?")[0].split("/")[3];
                }
                return (
                  <div key={item.orderNumber} className="relative mt-2">
                    <YouTube
                      className={"w-full"}
                      key={item.orderNumber}
                      opts={opt}
                      iframeClassName="youtubeRounded"
                      videoId={link}
                    />
                    <CloseButton
                      onClick={() => {
                        handleDeleteVideo(item.link);
                      }}
                      className="absolute cursor-pointer top-0 right-0"

                    />
                  </div>
                );
              })}
            </TitleWrap>
            {/* <div className="MBody14 w-full text-Gray09 my-4">
                💡드래그앤드롭으로 영상 순서를 바꿀 수 있어요
              </div> */}
          </ContentWrap>
        </div>
        <hr className="w-full h-3 bg-Gray01 my-10" />
        <div className="px-5 flex flex-col gap-10 items-start w-full">
          <ContentWrap title="기본정보">
            <TitleWrap title={"직업"} require>
              <div className="flex items-center flex-wrap gap-2">
                {JOB_LIST.map((item) => {
                  return (
                    <Chip
                      selected={artistProfile?.jobs!.includes(item?.title)}
                      onClick={() => {
                        if (artistProfile?.jobs!.includes(item?.title)) {
                          setArtistProfile({
                            ...artistProfile,
                            jobs: artistProfile.jobs!.filter(
                              (job: any) => job !== item.title
                            ),
                          });
                        } else if (artistProfile?.jobs!.length < 5) {
                          setArtistProfile({
                            ...artistProfile,
                            jobs: [...artistProfile?.jobs!, item.title],
                          });
                        }
                      }}
                      key={item.id}
                      title={item.title}
                    ></Chip>
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title={"활동명"} require>
              <Input
                value={artistProfile?.name}
                onChange={(e) => {
                  setArtistProfile({ ...artistProfile, name: e.target.value });
                }}
                className="w-[589px]"
                placeholder="활동명"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title={"생년월일"}>
              <DateInput
                value={artistProfile?.birthDate}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    birthDate: e.target.value,
                  });
                }}
                min="1920-01-01"
                max={today()}
                className="w-[589px]"
                placeholder="생년월일"
              ></DateInput>
            </TitleWrap>
            <TitleWrap title={"성별"}>
              <div className="flex items-center gap-4 w-full">
                <Button
                  onClick={() => {
                    setArtistProfile({ ...artistProfile, gender: "FEMALE" });
                  }}
                  className={`
                           w-full border ${artistProfile?.gender === "FEMALE"
                      ? "border-Blue04 text-Blue04 bg-Gray00"
                      : "border-Gray03 bg-Gray00 text-Gray05"
                    } cursor-default
                           `}
                  text={"여성"}
                />
                <Button
                  onClick={() => {
                    setArtistProfile({
                      ...artistProfile,
                      gender: "MALE",
                    });
                  }}
                  className={`
                    w-full border RBody16 cursor-default
                     ${artistProfile?.gender === "MALE"
                      ? "border-Blue04 text-Blue04 bg-Gray00"
                      : "border-Gray03 bg-Gray00 text-Gray05"
                    }`}
                  text={"남성"}
                />
              </div>
            </TitleWrap>
            <TitleWrap title={"SNS"}>
              <Input
                value={artistProfile?.instagram}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    instagram: e.target.value,
                  });
                }}
                className="w-[589px] h-12 mb-4 pl-[52px]"
                placeholder="인스타그램 ID"
                type={"text"}
              >
                <ProfileInsta className="left-2.5 top-2.5 absolute" />
              </Input>
              <Input
                value={artistProfile?.youtube}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    youtube: e.target.value,
                  });
                }}
                className="w-[589px] h-12 mb-4 pl-[52px]"
                placeholder="유튜브 채널 URL (영상링크 x /채널 링크를 올려 주세요)"
                type={"text"}
              >
                <ProfileYoutube className="left-2.5 top-2.5 absolute" />
              </Input>
            </TitleWrap>
            {/* <TitleWrap title={"키워드"}>
              <div className="flex items-center flex-wrap gap-2">
                {keyword.map((item) => {
                  return (
                    <Chip
                      selected={artistProfile?.hashtags.includes(item)}
                      onClick={() => {
                        if (artistProfile?.hashtags.includes(item)) {
                          setArtistProfile({
                            ...artistProfile,
                            hashtags: [
                              ...artistProfile?.hashtags.filter(
                                (hashTag: string) => hashTag !== item
                              ),
                            ],
                          });
                        } else {
                          setArtistProfile({
                            ...artistProfile,
                            hashtags: [...artistProfile?.hashtags, item],
                          });
                        }
                      }}
                      key={item}
                      title={item}
                    ></Chip>
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title={"직접입력"} subTitle="최대 10자">
              <div className="flex items-center gap-2">
                <Input
                  value={keywordItem}
                  onChange={(e) => {
                    setKeywordItem(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (keywordItem !== "") {
                      if (e.key === "Enter") {
                        setArtistProfile({
                          ...artistProfile,
                          hashtags: [
                            ...artistProfile.hashtags,
                            keywordItem.trim(),
                          ],
                        });
                        setKeywordItem("");
                      }
                    }
                  }}
                  className="!w-[292px]"
                  placeholder="키워드 추가"
                  type={"text"}
                ></Input>
                <Button
                  onClick={() => {
                    if (keywordItem !== "") {
                      setArtistProfile({
                        ...artistProfile,
                        hashtags: [...artistProfile.hashtags, keywordItem],
                      });
                      setKeywordItem("");
                    }
                  }}
                  className="w-[60px] text-Blue04 bg-Blue01 !MBody16 border-none"
                  text="추가"
                ></Button>
              </div>
              <div className="flex items-center flex-wrap mt-4 gap-2">
                {artistProfile.hashtags
                  .filter((hashtags: string) => !keyword.includes(hashtags))
                  .map((hashtag: string, index: React.Key | null | undefined) => (
                    <Chip
                      closeButton
                      selected
                      key={index}
                      onClick={() => {
                        setArtistProfile({
                          ...artistProfile,
                          hashtags: artistProfile.hashtags.filter(
                            (x: any) => x !== hashtag
                          ),
                        });
                      }}
                      title={hashtag}
                    ></Chip>
                  ))}
              </div>
            </TitleWrap> */}
            <hr className="h-px w-full text-Gray03" />
            <div className="flex items-center gap-5">
              <TitleWrap title={"키"} require>
                <Input
                  value={artistProfile?.height}
                  onChange={(e) => {
                    setArtistProfile({
                      ...artistProfile,
                      height: Number(e.target.value.replace(/[^0-9]/g, "")),
                    });
                  }}
                  onKeyDown={(e) => {
                    if (["e", "E", "+", "-", "."].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  className="w-[285px]"
                  placeholder="cm"
                  inputMode="numeric"
                  type={"number"}
                ></Input>
              </TitleWrap>
              <TitleWrap title="몸무게" require>
                <Input
                  value={artistProfile?.weight}
                  onChange={(e) => {
                    setArtistProfile({
                      ...artistProfile,
                      weight: Number(e.target.value.replace(/[^0-9]/g, "")),
                    });
                  }}
                  onKeyDown={(e) => {
                    if (["e", "E", "+", "-", "."].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  className="w-[285px]"
                  placeholder="kg"
                  type={"number"}
                  inputMode="numeric"
                ></Input>
              </TitleWrap>
            </div>
            <TitleWrap title="학력">
              <Input
                value={artistProfile?.education}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    education: e.target.value,
                  });
                }}
                className="w-[589px]"
                placeholder="최종학력"
                type={"text"}
              ></Input>
              {/* <div className="flex items-center">
                  <Checkbox id="학력" title={"비공개"}></Checkbox>
                  <div className="group relative">
                    <Tooltip />
                    <div className="absolute group-hover:block hidden RBody14 rounded-[10px] p-2 border box-content border-Gray03 text-Gray09 top-6 bg-white left-0 w-[308px]">
                      아티스트 유저에게는 가려지고 스탭 유저에게만 공개돼요
                    </div>
                  </div>
                </div> */}
            </TitleWrap>
            <TitleWrap title="소속사">
              <Input
                value={artistProfile?.agency}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    agency: e.target.value,
                  });
                }}
                className="w-[589px]"
                placeholder="소속사"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="특기" subTitle="한개씩 입력해주세요">
              <div>
                <div className="flex items-center gap-2">
                  <Input
                    value={hobbyItem}
                    onChange={(e) => {
                      setHobbyItem(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (hobbyItem !== "") {
                        if (e.key === "Enter") {
                          setArtistProfile({
                            ...artistProfile,
                            hobbies: [
                              ...artistProfile.hobbies!,
                              hobbyItem.trim(),
                            ],
                          });
                          setHobbyItem("");
                        }
                      }
                    }}
                    className="!w-[292px]"
                    placeholder="영어/제주도 사투리/태권도/현대무용 등"
                    type={"text"}
                  ></Input>
                  <Button
                    onClick={() => {
                      if (hobbyItem !== "") {
                        setArtistProfile({
                          ...artistProfile,
                          hobbies: [
                            ...artistProfile.hobbies!,
                            hobbyItem.trim(),
                          ],
                        });
                        setHobbyItem("");
                      }
                    }}
                    className="min-w-[60px] flex-1 text-Blue04 bg-Blue01 !MBody16 border-none"
                    text="추가"
                  ></Button>
                </div>
                {artistProfile.hobbies.length > 0 && (
                  <div className="flex items-center flex-wrap mt-4 gap-2">
                    {artistProfile.hobbies!.map(
                      (item: string, idx: React.Key | null | undefined) => {
                        return (
                          <Chip
                            closeButton
                            key={idx}
                            selected
                            onClick={() => {
                              setArtistProfile({
                                ...artistProfile,
                                hobbies: artistProfile.hobbies!.filter(
                                  (x: any) => x !== item
                                ),
                              });
                            }}
                            title={item}
                          ></Chip>
                        );
                      }
                    )}
                  </div>
                )}
                {/* <div
                  className={`text-Gray05 RBody14 ${
                    artistProfile?.hobbies.length !== 0 && "mt-4"
                  }`}
                >
                  예시) 영어 / 중국어 / 스페인어 / 불어 / 러시아어 / 일본어 /
                  경상도 사투리 / 제주도 사투리 / 승마 / 태권도 / 수영 / 주짓수
                  / 호신술 / 운전 / 발레 / 현대무용 / 한국무용 / 피아노 등
                </div> */}
              </div>
            </TitleWrap>
            <TitleWrap title="거주지">
              <Input
                value={artistProfile?.sido}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    sido: e.target.value,
                  });
                }}
                className="w-[589px]"
                placeholder="시까지 입력"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="자기소개" subTitle="최대 2000자">
              <Textarea
                value={artistProfile?.introduce}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const phoneNumberPattern =
                    /01[0-9][ {}\[\]\/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]*?[0-9]{3,4}[ {}\[\]\/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]*?[0-9]{4}/g;
                  if (phoneNumberPattern.test(inputValue)) {
                    Toast.error(`휴대폰번호는 입력할 수 없습니다.`);
                    e.target.value = inputValue.replace(phoneNumberPattern, "");
                  }

                  setArtistProfile({
                    ...artistProfile,
                    introduce: e.target.value,
                  });
                }}
                placeholder="개인정보(휴대폰번호, 주소 등) 작성을 유의해주세요."
                className="w-full h-[270px]"
                maxlength={2000}
              />
            </TitleWrap>
          </ContentWrap>
        </div>
        <hr className="w-full h-3 bg-Gray01 my-10" />
        <div className="px-5">
          <div className="flex pb-4 justify-between items-center">
            <div className="BBody20 text-Gray09">필모그래피</div>
            <IconPlus
              onClick={() => {
                addFilmographyItem();
              }}
              className="cursor-pointer"
            />
          </div>
          {artistProfile.filmographies!.map((item, idx) => {
            return (
              <div key={idx} className="flex flex-col gap-4">
                <div
                  onClick={() => {
                    removeFilmographyItem(idx);
                  }}
                  className="mt-4 flex items-center gap-2 cursor-pointer"
                >
                  <hr className="border-t border-dashed bg-Gray03 flex-1" />
                  <Delete />
                  <hr className="border-t border-dashed bg-Gray03 flex-1" />
                </div>
                <div className={`flex items-center gap-5`}>
                  <TitleWrap title={"제작년도"} subTitle="0000로 적어주세요">
                    <Input
                      value={artistProfile?.filmographies![idx].releaseYear}
                      onChange={(e) => {
                        const updatedFilmographies = [
                          ...artistProfile.filmographies!,
                        ];
                        updatedFilmographies[idx].releaseYear = Number(
                          e.target.value.replace(/[^0-9]/g, "").slice(0, 4)
                        );
                        setArtistProfile({
                          ...artistProfile,
                          filmographies: updatedFilmographies,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-", "."].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      placeholder="제작년도"
                      type={"number"}
                      inputMode="numeric"
                    ></Input>
                  </TitleWrap>
                  <TitleWrap title="분야">
                    <Dropdown
                      innerText={
                        TYPE_LABELS[
                        artistProfile.filmographies![idx].type || ""
                        ] || "선택"
                      }
                      width="w-[170px]"
                    >
                      <div>
                        {TYPE_LIST.map((item) => {
                          return (
                            <li
                              onClick={() => {
                                const updatedFilmographies = [
                                  ...artistProfile.filmographies!,
                                ];
                                updatedFilmographies[idx].type = item.role;
                                setArtistProfile({
                                  ...artistProfile,
                                  filmographies: updatedFilmographies,
                                });
                              }}
                              key={item.id}
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </div>
                    </Dropdown>
                  </TitleWrap>
                </div>
                <TitleWrap title="작품 제목" subTitle="최대 36자">
                  <Input
                    value={artistProfile?.filmographies![idx].title}
                    onChange={(e) => {
                      const updatedFilmographies = [
                        ...artistProfile.filmographies!,
                      ];
                      updatedFilmographies[idx].title = e.target.value;
                      setArtistProfile({
                        ...artistProfile,
                        filmographies: updatedFilmographies,
                      });
                    }}
                    className="w-[589px]"
                    placeholder="작품 제목"
                    type={"text"}
                  ></Input>
                </TitleWrap>
                <div className="flex items-center gap-5">
                  <TitleWrap title="배역">
                    <Dropdown
                      innerText={
                        ROLE_LABELS[
                        artistProfile.filmographies![idx].role || ""
                        ] || "선택"
                      }
                      width="w-[170px]"
                    >
                      <div>
                        {CASTING_LIST.map((item) => {
                          return (
                            <li
                              onClick={() => {
                                const updatedFilmographies = [
                                  ...artistProfile.filmographies!,
                                ];
                                updatedFilmographies[idx].role = item.role;
                                setArtistProfile({
                                  ...artistProfile,
                                  filmographies: updatedFilmographies,
                                });
                              }}
                              key={item.id}
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </div>
                    </Dropdown>
                  </TitleWrap>
                  <TitleWrap title={"배역명"}>
                    <Input
                      value={artistProfile?.filmographies![idx].description}
                      onChange={(e) => {
                        const updatedFilmographies = [
                          ...artistProfile.filmographies!,
                        ];
                        updatedFilmographies[idx].description = e.target.value;
                        setArtistProfile({
                          ...artistProfile,
                          filmographies: updatedFilmographies,
                        });
                      }}
                      className="w-[285px]"
                      placeholder="배역명"
                      type={"text"}
                    ></Input>
                  </TitleWrap>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ButtonBackground>
        <Button
          onClick={() => {
            const validError = valid(artistProfile);
            if (validError !== null) {
              Toast.error(validError);
            } else {
              submitProfile();
            }
          }}
          className="w-full h-12 bg-Blue04 text-white border-none"
          text={`등록하기`}
        ></Button>
      </ButtonBackground>
    </div>
  );
};

export default CreateArtistProfile;
