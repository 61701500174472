import React from "react";

import { ReactComponent as Back } from "assets/headerBack.svg";
import { useNavigate } from "react-router-dom";

import New from "assets/img_new.png";
import Letter from "assets/img_letter.png";
import Cd from "assets/img_cd.png";
import { LineButton } from "components/LineButton";
import { FillButton } from "components/FillButton";
import { TintButton } from "components/TintButton";
import { useCancelSubscription } from "api/Payment/Payment";
import { Toast } from "hooks/useToast";

const MembershipCancel = () => {
  const navigate = useNavigate();

  const { mutate, isLoading } = useCancelSubscription();

  const cancelInfo = [
    {
      id: 0,
      icon: Cd,
      title: "메이저 캐스팅 디렉터 컨텍",
      subTitle: "지옥에서 온 판사, 베테랑2, 셀러브리티 등",
    },
    {
      id: 1,
      icon: New,
      title: "매일 업데이트되는 캐스팅 정보",
      subTitle: "어디서도 볼 수 없는",
    },
    {
      id: 2,
      icon: Letter,
      title: "직접 보내는 프로필",
      subTitle: "확인 불가능한 딜리버리가 아닌",
    },
  ];

  return (
    <div className="w-full pb-[103px] min-w-[280px] max-w-[400px] min-h-screen px-5 bg-Gray01 mx-auto">
      <div className="mx-auto px-5 py-2 fixed bg-Gray01 left-2/4 top-0 z-50 -translate-x-2/4 max-w-[400px] w-full BBody20 text-Gray09 ">
        <div className="flex justify-between items-center">
          <Back
            className="cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
      </div>
      <div className="w-full pt-[74px]">
        <div className="BBody20 text-Gray09">멤버십 혜택을 해지할까요?</div>
      </div>
      <div className="my-10 flex flex-col gap-10">
        {cancelInfo.map((item) => {
          return (
            <div className="flex items-center gap-2" key={item.id}>
              <img className="w-16 h-16" src={item.icon} alt="icon" />
              <div className="flex items-start flex-col">
                <div className="RCaption12 text-Gray05">{item.subTitle}</div>
                <div className="BBody16 text-Gray09">{item.title}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="text-Gray05 RCaption14 mb-2 text-center mx-auto max-w-[252px]">
        지금 해지하셔도 결제 주기 마지막 날
        {/* 인{" "}
        <span className="BCaption14">mm월 dd일</span> */}
        까지 정상 이용 가능해요.
      </div>
      <div className="RCaption12 text-Gray04 text-center">
        다음 결제일부터 정기 결제가 진행되지 않아요.
      </div>
      <div className="max-w-[400px] flex items-center gap-4 w-full fixed bottom-0 left-2/4 -translate-x-2/4 pb-[35px] px-5">
        <TintButton
          text="해지하기"
          onClick={() => {
            mutate(undefined, {
              onSuccess: () => {
                navigate("/", { replace: true });
                Toast.success("해지가 완료되었어요.");
              },
            });
          }}
          isLoading={isLoading}
          className="w-full"
          variant="delete"
          size={"L"}
        ></TintButton>
        <LineButton
          onClick={() => {
            navigate(-1);
          }}
          className="w-full"
          variant="normal"
          size="L"
          text="유지하기"
        ></LineButton>
      </div>
    </div>
  );
};

export default MembershipCancel;
