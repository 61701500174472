import React from "react";
import { ReactComponent as ArrowRight } from "assets/arrowRight.svg";

import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useMyInfo } from "api/Mypage/Mypage";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { authInfo, loginCheck } from "store/auth/atom";
import { onLogOut } from "utils/onLogOut";
import { useNavigate } from "react-router";

const MyPage = () => {
  const navigate = useNavigate();
  const setLogin = useSetRecoilState(loginCheck);

  const { data } = useMyInfo();
  const infoData = data?.data.data;

  const info = [
    { id: 0, title: "이름", desc: infoData?.name },
    { id: 1, title: "이메일", desc: infoData?.email },
    { id: 2, title: "휴대폰번호", desc: infoData?.phoneNumber },
    { id: 3, title: "소속사", desc: "-" },
    { id: 4, title: "비밀번호", desc: "-" },
  ];

  const resetList = useResetRecoilState(authInfo);

  return (
    <>
      <HeaderWithBackButton title={"내 정보"} />
      <div className="pb-24 pt-5 px-5">
        <div className="flex flex-col gap-4 w-full items-start">
          {info.map((item, i) => {
            return (
              <div
                key={item.id}
                className="flex w-full max-w-[360px] items-center"
              >
                <div className="w-[89px] text-Gray05 mr-2.5">{item.title}</div>
                <div className="flex-1 MBody16 text-Gray09 text-ellipsis whitespace-nowrap overflow-hidden">
                  {item.desc}
                </div>
                {/* {item.id !== 0 && <ArrowRight />} */}
              </div>
            );
          })}
        </div>
        <hr className="my-7" />
        <div className="flex items-start flex-col MBody15 text-Gray05 gap-4">
          <div
            className="cursor-pointer"
            onClick={() => {
              resetList();
              onLogOut();
              setLogin(false);
            }}
          >
            로그아웃
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/withdrawal");
            }}
          >
            탈퇴하기
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPage;
