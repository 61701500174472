import React, { useEffect, useMemo } from "react";

import { useGetManagerArtistProfileList } from "api/Manage";
import ArtistListCard from "components/ArtistListCard";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router";
import { splitBirth } from "utils/onBirth";

interface StaffArtistsProfilesProps {
  memberId: number;
  isManager: boolean;
}

const StaffArtistsProfiles = ({
  memberId,
  isManager,
}: StaffArtistsProfilesProps) => {
  // 매니자 상세페이지 아티스트 리스트

  const navigate = useNavigate();
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const { data, fetchNextPage, refetch, hasNextPage } =
    useGetManagerArtistProfileList({
      managerId: memberId,
      isManager: isManager
    });

  useEffect(() => {
    if (memberId) {
      refetch();
    }
  }, [memberId, refetch]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const artists = useMemo(
    () => (data ? data?.pages?.flatMap((data) => data.data.content) : []),
    [data]
  );

  return (
    <>
      {artists.length === 0 ? (
        <div className="mt-36 items-center text-center">
          <div className="text-Gray05 RBody14">
            등록된 소속 아티스트가 없어요
          </div>
        </div>
      ) : (
        <>
          <div className="gap-x-2 gap-y-4 grid grid-cols-3">
            {artists.map((item) => {
              return (
                <ArtistListCard
                  key={item.profileId}
                  onClick={() => navigate(`/artist/${item.profileId}`)}
                  title={item?.profileName}
                  src={item?.thumbnailUrl}
                  subTitle={`${splitBirth(item.birthDate!)}`}
                />
              );
            })}
          </div>
          {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
        </>
      )}
    </>
  );
};

export default StaffArtistsProfiles;
