import NoProfile from "../assets/noProfile.png";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { LineButton } from "components/LineButton";
import { useMyPage } from "api/Mypage/Mypage";
import { MY_ROLE } from "utils/onRoleStatus";
import { useGetManageArtistProfileList } from "api/Manage";
import { useCallback, useMemo } from "react";
import ReactVirtuosoGrid from "components/ReactVirtuosoGrid";
import { Profile } from "type/common";
import { splitBirth } from "utils/onBirth";
import ArtistListCard from "components/ArtistListCard";

const ManageMy = () => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  const role = MY_ROLE() as string;
  const navigate = useNavigate();
  const { data, isLoading, isError } = useMyPage();
  const ManagerData = data?.data.data;

  const {
    data: artists,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: artistLoading,
  } = useGetManageArtistProfileList({});
  const allLists = useMemo(
    () =>
      artists ? artists?.pages?.flatMap((artist) => artist.data.content) : [],
    [artists]
  );

  const loadMore = useCallback(async () => {
    if (!isFetchingNextPage && hasNextPage) {
      await fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  if (isLoading || artistLoading || isError) return null;

  const handleModify = () => {
    if (ManagerData === null) {
      navigate("/manage/profile");
      return;
    }

    navigate("/manage/profile/edit");
  };

  const getRole = (role?: string) => {
    switch (role) {
      case "ROLE_MANAGER":
        return "매니저";
      case "ROLE_CASTING_DIRECTOR":
        return "캐스팅 디렉터";
      case "ROLE_DIRECTOR":
        return "연출";
      case "ROLE_PRODUCER":
        return "제작";
      case "ROLE_AGENCY":
        return "광고";
      case "ROLE_WRITER":
        return "작가";
      default:
        return "";
    }
  };

  const getYear = (birth: string) => {
    return new Date(birth).getFullYear();
  };

  return (
    <div>
      <HeaderWithBackButton title="내 프로필" />

      {ManagerData === null ? (
        <div className="text-center mx-auto px-5 flex-col pt-24">
          <img
            className="w-[60px] h-[60px] mx-auto"
            src={NoProfile}
            alt="NoProfile"
          />
          <div className="mt-4 mb-10 MBody16 text-Gray05 whitespace-normal">
            아직 프로필이 없어요.
            <br />
            프로필을 등록하고 캐스팅보트를 시작해보세요 .
          </div>
          <Button
            className="px-4 py-3 text-Gray00 bg-Blue04 border-none"
            text={"프로필 등록"}
            onClick={handleModify}
          />
        </div>
      ) : (
        <div className="flex px-5 pb-24 flex-col">
          <div>
            <div className="w-full items-start flex">
              <img
                src={`${MEDIA_URL}${ManagerData?.thumbnailUrl}`}
                alt={ManagerData?.profileName}
                className="w-[160px] h-[196px] rounded-[10px] object-cover"
              />

              <div className="pl-4">
                <div className="flex items-center justify-between gap-2 BBody20 text-Gray09">
                  <div>{ManagerData?.profileName}</div>
                </div>
                <div className="my-2 MCaption14 flex items-start flex-wrap text-Gray05">
                  <div className="flex items-center">
                    <div>{getRole(role)}</div>
                    <div>•</div>
                    <div>{ManagerData?.basicInfo.agency}</div>
                  </div>
                </div>
                <div className="RCaption12 text-Gray05">
                  {ManagerData?.filmographySummary}
                </div>
              </div>
            </div>
            <LineButton
              onClick={handleModify}
              className="w-full mt-4 "
              variant="normal"
              size="L"
              text="프로필 수정"
            />
          </div>

          <div className="w-full py-4">
            {allLists && (
              <ReactVirtuosoGrid
                isLoading={isLoading}
                useWindowScroll
                data={isLoading ? Array.from({ length: 1 }) : allLists}
                overscan={100}
                hasNextPage={hasNextPage}
                listClassName="gap-x-4 gap-y-4 grid grid-cols-2"
                isFetchingNextPage={isFetchingNextPage}
                fetchNextPage={loadMore}
                itemContent={(index, item: Profile) => (
                  <ArtistListCard
                    key={item.deliveryProfileId}
                    onClick={() => {
                      navigate(`/manage/artist/detail/${item.profileId}`);
                    }}
                    title={item?.profileName}
                    src={item?.thumbnailUrl}
                    subTitle={`${splitBirth(item.birthDate!)}`}
                  />
                )}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageMy;
