import { VariantProps, cva } from "class-variance-authority";
import React, { ButtonHTMLAttributes, FC } from "react";

interface TintButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof TintButtonVariants> {
  className?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  text?: string;
}

export const TintButtonVariants = cva(
  `
  disabled:opacity-20 text-center rounded-md
  `,
  {
    variants: {
      variant: {
        normal: "bg-Blue01 text-Blue04",
        delete: "bg-[#FFEFEF] text-Error",
      },
      size: {
        S: "MCaption12 h-7 px-2.5",
        M: "MCaption14 h-[38px] px-4",
        L: "MBody16 h-12 px-4",
      },
    },
  }
);

export const TintButton: FC<TintButtonProps> = ({
  variant,
  size,
  children,
  className,
  isLoading,
  disabled,
  text,
  ...props
}) => {
  return (
    <button
      disabled={isLoading || disabled}
      className={TintButtonVariants({ variant, size, className })}
      {...props}
    >
      {isLoading ? (
        <div className="w-4 h-4 mx-auto border-2 border-t-transparent border-Gray00 rounded-full animate-spin"></div>
      ) : (
        text
      )}
    </button>
  );
};
