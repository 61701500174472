import React, { useState } from "react";
import Chip from "components/Chip";
import Input from "components/Input";
import TitleWrap from "components/TitleWrap";
import { ReactComponent as ProfileInsta } from "assets/profileInsta.svg";
import { ReactComponent as ProfileYoutube } from "assets/profileYoutube.svg";
import Button from "components/Button";
import Textarea from "components/Textarea";
import Dropdown from "components/Dropdown";
import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import { ReactComponent as Delete } from "assets/delete.svg";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import ImgUpload from "components/ImgUpload";
import YouTube from "react-youtube";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { Toast } from "hooks/useToast";
import {
  useUploadImagesMutation,
  useUploadVideosMutation,
} from "api/Attachment/Attachment";
import { CASTING_LIST, JOB_LIST, TYPE_LIST } from "utils/type";
import DateInput from "components/DateInput";
import { today } from "utils/onToday";
import ButtonBackground from "components/ButtonBackground";
import ContentWrap from "components/ContentWrap";

import { usePostManageArtistAddProfile } from "api/Manage";
import type { ManageArtistProfile as ManageArtistProfileType } from "api/Manage";
import { useNavigate } from "react-router";

export const roleMap: Record<string, string> = {
  LEADING_ACTOR: "주연",
  LEADING_SUPPORTING_ACTOR: "주조연",
  SUPPORTING_ACTOR: "조연",
  SUPPORTING_BIT_PART: "조단역",
  BIT_PART: "단역",
  DOUBLE: "대역",
  IMAGE_BIT_PART: "이미지",
  CAMEO: "특별출연",
  EPISODE: "에피소드",
  ETC: "기타",
};

interface AddSetProfileProps extends ManageArtistProfileType {
  addHobbies?: {
    name: string;
    isNew: boolean;
  }[];
  removeHobbyIds?: number[];
  addJobs?: string[];
  removeJobs?: string[];
  addFilmographies?: {
    isNew?: boolean;
    id?: number;
    title?: string;
    type?: string;
    role?: string;
    releaseYear?: number | null;
    description?: string;
    orderNumber?: number;
  }[];
  removeFilmographyIds?: number[];
  addAttachments?: {
    id?: number;
    type?: string;
    orderNumber?: number;
  }[];
  removeAttachments?: number[];
  addVideoLinks?: {
    id?: number;
    isNew?: boolean;
    link?: string;
    type?: string;
    orderNumber?: number;
  }[];
  removeVideoLinks?: number[];
}

const ManageArtistProfile = () => {
  const navigate = useNavigate();
  const [hobbyItem, setHobbyItem] = useState<{
    name: string;
    id?: number;
    isNew: boolean;
  }>({ name: "", isNew: false });
  const [youtubeLink, setYoutubeLink] = useState("");
  const [artistProfile, setArtistProfile] = useState<AddSetProfileProps>({
    name: "",
    birthDate: "",
    gender: "FEMALE",
    job: "",
    jobs: [],
    sido: "",
    height: 0,
    weight: 0,
    education: "",
    rank: "NORMAL",
    agency: "",
    introduce: "",
    instagram: "",
    youtube: "",
    hashtags: [],
    addJobs: [],
    removeJobs: [],
    addHobbies: [],
    hobbies: [],
    removeHobbyIds: [],
    addAttachments: [],
    removeAttachments: [],
    filmographies: [],
    addFilmographies: [],
    removeFilmographyIds: [],
    attachments: [],
    videoLinks: [],
    addVideoLinks: [],
    removeVideoLinks: [],
  });

  const { mutate: uploadImages } = useUploadImagesMutation();
  const { mutate: uploadVideos } = useUploadVideosMutation();
  const addManageProfile = usePostManageArtistAddProfile();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const files = e.target.files;
    if (files) {
      if (type === "ADDITIONAL_IMAGE") {
        const imagesCount = artistProfile.attachments!.filter(
          (attachment) => attachment.type === "ADDITIONAL_IMAGE"
        ).length;

        const remainingSlots = 15 - imagesCount;

        if (files.length > remainingSlots) {
          Toast.error("최대 15개 업로드할 수 있어요.");
          return;
        }
      }

      uploadImages(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");

          const uploadedItems = res.data.data.map(
            (item: { attachmentId: number; attachmentUrl: string }) => ({
              id: item.attachmentId,
              isNew: true,
              type: type,
              url: item.attachmentUrl,
            })
          );

          const existingThumbnails = artistProfile.attachments!.filter(
            (attachment) => attachment.type === "THUMBNAIL"
          );

          const existingAdditionalImages = artistProfile.attachments!.filter(
            (attachment) => attachment.type === "ADDITIONAL_IMAGE"
          );

          const newThumbnails = uploadedItems.filter(
            (item) => item.type === "THUMBNAIL"
          );

          const newAdditionalImages = uploadedItems.filter(
            (item) => item.type === "ADDITIONAL_IMAGE"
          );

          const reorderedThumbnails = [
            ...existingThumbnails,
            ...newThumbnails,
          ].map((item) => ({
            ...item,
            orderNumber: 0,
          }));

          const reorderedAdditionalImages = [
            ...existingAdditionalImages,
            ...newAdditionalImages,
          ].map((item, idx) => ({
            ...item,
            orderNumber: idx + 1,
          }));

          const reorderedAttachments = [
            ...reorderedThumbnails,
            ...reorderedAdditionalImages,
          ];

          const reorderedUploadedItems = uploadedItems.map((item) => {
            if (item.type === "THUMBNAIL") {
              return { ...item, orderNumber: 0 };
            }
            const additionalImageIndex =
              reorderedAdditionalImages.findIndex(
                (additionalItem) => additionalItem.id === item.id
              ) + 1;
            return { ...item, orderNumber: additionalImageIndex };
          });

          setArtistProfile({
            ...artistProfile,
            addAttachments: [
              ...artistProfile.addAttachments!,
              ...reorderedUploadedItems,
            ],
            attachments: reorderedAttachments,
          });
        },
      });
    }
  };

  const onDrop = async (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files) {
      const additionalImagesCount = artistProfile.attachments!.filter(
        (attachment) => attachment.type === "ADDITIONAL_IMAGE"
      ).length;

      const remainingSlots = 15 - additionalImagesCount;

      if (files.length > remainingSlots) {
        Toast.error("최대 15개까지 업로드 가능해요");
        return;
      }

      uploadImages(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.data.map(
            (
              item: {
                attachmentId: number;
                attachmentUrl: string;
              },
              idx: number
            ) => ({
              id: item.attachmentId,
              isNew: true,
              type: "ADDITIONAL_IMAGE",
              orderNumber: idx,
              url: item.attachmentUrl,
            })
          );
          setArtistProfile({
            ...artistProfile,
            addAttachments: [...artistProfile.addAttachments!, ...updateItem],
            attachments: [...artistProfile.attachments!, ...updateItem],
          });
        },
      });
    }
  };

  const onDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  };

  const videoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const additionalVideosCount = artistProfile.attachments!.filter(
        (attachment) => attachment.type === "ADDITIONAL_VIDEO"
      ).length;

      const remainingSlots = 3 - additionalVideosCount;

      if (files.length > remainingSlots) {
        Toast.error("최대 3개까지 업로드 가능해요");
        return;
      }
      uploadVideos(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.data.map(
            (
              item: {
                attachmentId: number;
                attachmentUrl: string;
              },
              idx: number
            ) => ({
              id: item.attachmentId,
              isNew: true,
              type: "ADDITIONAL_VIDEO",
              orderNumber: additionalVideosCount + idx + 1,
              url: item.attachmentUrl,
            })
          );
          setArtistProfile({
            ...artistProfile,
            addAttachments: [...artistProfile.addAttachments!, ...updateItem],
            attachments: [...artistProfile.attachments!, ...updateItem],
          });
        },
      });
    }
  };

  const opt = {
    height: "171",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const addFilmographyItem = () => {
    setArtistProfile((prevState) => ({
      ...prevState,
      filmographies: [
        ...prevState.filmographies!,
        {
          title: "",
          type: "",
          role: "",
          releaseYear: null,
          description: "",
          orderNumber: prevState.filmographies!.length,
        },
      ],
    }));
  };

  const addVideoLink = (newLink: string, type: string) => {
    if (
      newLink.startsWith("https://www.youtube.com") ||
      newLink.startsWith("https://youtu.be/")
    ) {
      setArtistProfile((prevProfile) => {
        const currentVideoCount = prevProfile.videoLinks.length;

        if (currentVideoCount >= 3) {
          Toast.error("최대 3개까지 업로드 가능해요");
          return prevProfile;
        }

        const newOrderNumber = currentVideoCount;
        const newVideoLink = {
          isNew: true,
          link: newLink,
          type: type,
          orderNumber: newOrderNumber,
        };

        return {
          ...prevProfile,
          addVideoLinks: [...prevProfile.addVideoLinks!, newVideoLink],
          videoLinks: [...prevProfile.videoLinks!, newVideoLink],
        };
      });
    } else {
      alert("잘못된 유튜브 링크입니다.");
    }
  };

  const handleDeleteImage = (id: number, isNew?: boolean) => {
    const updatedAttachments = artistProfile.attachments!.filter(
      (item) => item.id !== id
    );
    const updatedAddAttachments = artistProfile.addAttachments!.filter(
      (item) => item.id !== id
    );
    setArtistProfile({
      ...artistProfile,
      attachments: updatedAttachments,
      addAttachments: updatedAddAttachments,
      removeAttachments: isNew
        ? artistProfile.removeAttachments
        : [...(artistProfile.removeAttachments || []), id],
    });
  };

  const handleDeleteVideo = (id: number, isNew?: boolean) => {
    const updatedVideoLinks = artistProfile.videoLinks.filter(
      (item) => item.id !== id
    );
    const updatedAddVideoLinks = artistProfile.addVideoLinks!.filter(
      (item) => item.id !== id
    );

    setArtistProfile({
      ...artistProfile,
      videoLinks: updatedVideoLinks,
      addVideoLinks: updatedAddVideoLinks,
      removeVideoLinks: isNew
        ? artistProfile.removeVideoLinks
        : [...(artistProfile.removeVideoLinks || []), id],
    });
  };

  const removeFilmographyItem = (idxToRemove: number) => {
    setArtistProfile((pre) => ({
      ...pre,
      filmographies: pre.filmographies!.filter((_, i) => i !== idxToRemove),
    }));
  };

  const toggleJob = (job: string) => {
    const updatedJobs = (artistProfile.jobs || []).includes(job)
      ? (artistProfile.jobs || []).filter((item) => item !== job)
      : (artistProfile.jobs || []).length < 5
        ? [...(artistProfile.jobs || []), job]
        : artistProfile.jobs;

    const updatedAddJobs = (artistProfile.addJobs || []).includes(job)
      ? (artistProfile.addJobs || []).filter((a) => a !== job)
      : [...(artistProfile.addJobs || []), job];

    setArtistProfile({
      ...artistProfile,
      jobs: updatedJobs,
      addJobs: updatedAddJobs,
    });
  };

  const valid = (data: AddSetProfileProps) => {
    const hasThumbnail = data?.attachments!.some(
      (attachment) => attachment.type === "THUMBNAIL"
    );
    if (!hasThumbnail) return "대표 프로필 이미지는 필수입니다.";
    if (data.jobs!.length === 0) return "직업을 선택해주세요.";
    if (!data.name) return "활동명을 입력해주세요.";
    if (data.birthDate === "") return "생년월일를 입력해주세요.";
    if (data.height === 0) return "키를 입력해주세요.";
    if (data.weight === 0) return "몸무게를 입력해주세요.";
    if (
      artistProfile?.filmographies!?.some((filmography) => {
        return (
          !filmography.title ||
          !filmography.type ||
          !filmography.role ||
          filmography.releaseYear! === 0 ||
          !filmography.description
        );
      })
    )
      return "필모그래피는 빈칸없이 입력해주세요";
    return null;
  };

  const submitProfile = () => {
    addManageProfile.mutate(artistProfile, {
      onSuccess: () => {
        Toast.success("프로필을 등록했어요.");
        navigate("/manage/artist", { replace: true });
      },
      onError: () => {
        Toast.error("프로필 등록에 실패했어요. 다시 시도해주세요.");
      },
    });
  };

  const additionalImagesLength = artistProfile.attachments!.filter(
    (attachment) => attachment.type === "ADDITIONAL_IMAGE"
  ).length;

  return (
    <div>
      <HeaderWithBackButton title={`프로필 등록`} />
      <div className="pb-24 pt-5">
        <div className="px-5 flex flex-col gap-10 items-start w-full">
          <ContentWrap title="사진 업로드">
            <TitleWrap
              title={"대표 프로필 사진 업로드"}
              subTitle="4:5 / 10MB 이하"
              require
            >
              {artistProfile?.attachments!.some(
                (item) => item.type === "THUMBNAIL"
              ) ? (
                artistProfile
                  ?.attachments!.filter((item) => item.type === "THUMBNAIL")
                  .map((item, idx) => (
                    <div key={item.id} className="relative">
                      <img
                        className="w-full h-[418px] object-cover rounded-[10px]"
                        src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                        alt="THUMBNAIL"
                      />
                      <XButton
                        onClick={() => {
                          handleDeleteImage(item.id, item.isNew);
                        }}
                        className="absolute cursor-pointer top-4 right-4"
                      />
                    </div>
                  ))
              ) : (
                <ImgUpload
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={(e) => {
                    imgUpload(e, "THUMBNAIL");
                  }}
                  title="프로필 사진 업로드"
                  id={"profileImg"}
                ></ImgUpload>
              )}
            </TitleWrap>
            <TitleWrap
              title={"추가 사진 업로드"}
              subTitle={`최대 15장 (${additionalImagesLength}/15)`}
            >
              <ImgUpload
                multiple
                accept="image/jpg,image/png,image/jpeg"
                onChange={(e) => {
                  imgUpload(e, "ADDITIONAL_IMAGE");
                }}
                onDrop={onDrop}
                onDragOver={onDragOver}
                title="추가 사진 업로드"
                id={"ADDITIONAL_IMAGE"}
              ></ImgUpload>
              <div className="grid grid-cols-3 gap-1 items-center mt-3">
                {artistProfile
                  ?.attachments!.filter(
                    (item) => item.type === "ADDITIONAL_IMAGE"
                  )
                  .map((item, idx) => (
                    <div key={item.id} className={`relative`}>
                      <img
                        className="w-[117px] aspect-square object-cover rounded-[10px]"
                        src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                        alt="ADDITIONAL_IMAGE"
                      />
                      <XButton
                        onClick={() => {
                          handleDeleteImage(item.id, item.isNew);
                        }}
                        width={18}
                        height={18}
                        className="absolute cursor-pointer top-3 right-2 bg-black/90"
                      />
                    </div>
                  ))}
              </div>
            </TitleWrap>
          </ContentWrap>
          <ContentWrap
            title={"영상 업로드"}
            subTitle="연기영상/자유영상을 첨부하면 캐스팅 확률이 높아져요!"
          >
            <TitleWrap
              title={"영상 파일 첨부"}
              className="mb-4"
              subTitle="50mb/최대 3개"
            >
              <ImgUpload
                accept=".avi, .mov, .mp4"
                onChange={(e) => {
                  videoUpload(e);
                }}
                title="영상 파일 업로드"
                id={"ProfileVideo"}
              ></ImgUpload>
              {artistProfile?.attachments!.some(
                (item) => item.type === "ADDITIONAL_VIDEO"
              )
                ? artistProfile
                  ?.attachments!.filter(
                    (item) => item.type === "ADDITIONAL_VIDEO"
                  )
                  .map((item, idx) => (
                    <div key={item.id} className="relative mt-2 w-full">
                      <video
                        controls
                        className="w-full h-[186px] rounded-[10px]"
                      >
                        <source
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                        ></source>
                      </video>
                      <XButton
                        onClick={() => {
                          handleDeleteImage(item.id, item.isNew);
                        }}
                        className="absolute cursor-pointer top-4 right-4"
                      />
                    </div>
                  ))
                : ""}
            </TitleWrap>
            <TitleWrap title={"유튜브 링크"}>
              <div className="flex items-center gap-2 w-full">
                <Input
                  value={youtubeLink}
                  onChange={(e) => {
                    setYoutubeLink(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (youtubeLink !== "") {
                      if (e.key === "Enter") {
                        addVideoLink(youtubeLink, "YOUTUBE");
                        setYoutubeLink("");
                      }
                    }
                  }}
                  placeholder="링크 입력"
                  type={"text"}
                ></Input>
                <Button
                  onClick={() => {
                    if (youtubeLink !== "") {
                      addVideoLink(youtubeLink, "YOUTUBE");
                      setYoutubeLink("");
                    }
                  }}
                  className="min-w-[88px] h-[48px] flex-1 text-Gray09 bg-white !MBody16 border-Gray03 box-border border"
                  text="추가"
                ></Button>
              </div>
              {artistProfile.videoLinks.map((item, idx) => {
                let link = "";

                if (item.link.startsWith("https://www.youtube.com")) {
                  link = item.link.split("watch?v=")[1];
                } else {
                  link = item.link.split("?")[0].split("/")[3];
                }
                return (
                  <div key={item.orderNumber} className="relative mt-2 group">
                    <YouTube
                      className={"w-full"}
                      key={item.orderNumber}
                      opts={opt}
                      iframeClassName="youtubeRounded"
                      videoId={link}
                    />
                    <XButton
                      onClick={() => {
                        handleDeleteVideo(item.id!);
                      }}
                      className="absolute  cursor-pointer top-4 right-4 w-[18px] h-[18px] bg-black/90 group-hover:block"
                    />
                  </div>
                );
              })}
            </TitleWrap>
            {/* <div className="MBody14 w-full text-Gray09 my-4">
                💡드래그앤드롭으로 영상 순서를 바꿀 수 있어요
              </div> */}
          </ContentWrap>
        </div>
        <hr className="w-full h-3 bg-Gray01 my-10 border-none" />
        <div className="px-5 flex flex-col gap-10 items-start w-full">
          <ContentWrap title="기본정보">
            <TitleWrap title={"직업"} subTitle="최대 5개" require>
              <div className="flex items-center flex-wrap gap-2">
                {JOB_LIST.map((item) => {
                  return (
                    <Chip
                      selected={artistProfile?.jobs?.includes(item.title)}
                      onClick={() => {
                        toggleJob(item.title);
                      }}
                      key={item.id}
                      title={item.title}
                    />
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title={"활동명"} require>
              <Input
                value={artistProfile?.name}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    name: e.target.value,
                  });
                }}
                className="w-[589px]"
                placeholder="활동명 입력"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title={"생년월일"} require>
              <DateInput
                value={artistProfile?.birthDate}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    birthDate: e.target.value,
                  });
                }}
                min="1920-01-01"
                max={today()}
                className="w-[589px] !rounded-md h-[48px] placeholder:text-Gray05"
                placeholder="생년월일"
              ></DateInput>
            </TitleWrap>
            <TitleWrap title={"성별"} require>
              <div className="flex items-center gap-2 w-full">
                <Button
                  onClick={() => {
                    setArtistProfile({
                      ...artistProfile,
                      gender: "FEMALE",
                    });
                  }}
                  className={`
                           w-full border h-[48px] ${artistProfile?.gender === "FEMALE"
                      ? "border-Gray03 text-Gray09"
                      : "border-Gray03 bg-Gray00 text-Gray05 opacity-50"
                    } cursor-default
                           `}
                  text={"여성"}
                />
                <Button
                  onClick={() => {
                    setArtistProfile({
                      ...artistProfile,
                      gender: "MALE",
                    });
                  }}
                  className={`
                    w-full border RBody16 cursor-default h-[48px]
                     ${artistProfile?.gender === "MALE"
                      ? "border-Gray03 text-Gray09"
                      : "border-Gray03 bg-Gray00 text-Gray05 opacity-50"
                    }`}
                  text={"남성"}
                />
              </div>
            </TitleWrap>
            <TitleWrap title={"SNS"}>
              <Input
                value={artistProfile?.instagram}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    instagram: e.target.value,
                  });
                }}
                className="w-[589px] h-12 mb-2 pl-[52px]"
                placeholder="인스타그램 ID"
                type={"text"}
              >
                <ProfileInsta className="left-2.5 top-2.5 absolute" />
              </Input>
              <Input
                value={artistProfile?.youtube}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    youtube: e.target.value,
                  });
                }}
                className="w-[589px] h-12 m-0 pl-[52px]"
                placeholder="유튜브 채널 URL (영상링크 x)"
                type={"text"}
              >
                <ProfileYoutube className="left-2.5 top-2.5 absolute" />
              </Input>
            </TitleWrap>

            <TitleWrap title={"키"} require>
              <Input
                value={artistProfile?.height}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    height: Number(e.target.value.replace(/[^0-9]/g, "")),
                  });
                }}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                className="w-[285px]"
                inputMode="numeric"
                placeholder="키를 입력해 주세요 (cm)"
                type={"number"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="몸무게" require>
              <Input
                value={artistProfile?.weight}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    weight: Number(e.target.value.replace(/[^0-9]/g, "")),
                  });
                }}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                className="w-[285px] m-0"
                placeholder="몸무게를 입력해 주세요 (kg)"
                type={"number"}
                inputMode="numeric"
              ></Input>
            </TitleWrap>
            <TitleWrap title="학력">
              <Input
                value={artistProfile?.education}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    education: e.target.value,
                  });
                }}
                className="w-[589px] m-0"
                placeholder="최종학력 입력"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="소속사">
              <Input
                value={artistProfile?.agency}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    agency: e.target.value,
                  });
                }}
                className="w-[589px]"
                placeholder="소속사 입력"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="특기">
              <div>
                <div className="flex items-center gap-2">
                  <Input
                    value={hobbyItem.name}
                    onChange={(e) => {
                      setHobbyItem({
                        name: e.target.value,
                        isNew: true,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (hobbyItem.name) {
                        if (e.key === "Enter") {
                          setArtistProfile({
                            ...artistProfile,
                            hobbies: [
                              ...artistProfile?.hobbies!,
                              hobbyItem.name,
                            ],
                            addHobbies: [
                              ...artistProfile?.addHobbies!,
                              hobbyItem,
                            ],
                          });
                          setHobbyItem({
                            name: "",
                            isNew: false,
                          });
                        }
                      }
                    }}
                    className="m-0"
                    placeholder="영어/제주도 사투리/태권도 등"
                    type={"text"}
                  ></Input>
                  <Button
                    onClick={() => {
                      if (hobbyItem?.name !== "") {
                        setArtistProfile({
                          ...artistProfile,
                          hobbies: [...artistProfile?.hobbies!, hobbyItem.name],
                          addHobbies: [
                            ...artistProfile?.addHobbies!,
                            hobbyItem,
                          ],
                        });
                        setHobbyItem({
                          name: "",
                          isNew: false,
                        });
                      }
                    }}
                    className="min-w-[88px] h-[48px] flex-1 text-Gray09 bg-white !MBody16 border-Gray03 box-border border"
                    text="추가"
                  ></Button>
                </div>
                <div
                  className={`flex items-center flex-wrap ${artistProfile.hobbies &&
                    artistProfile.hobbies?.length > 0 &&
                    "mt-2"
                    }  gap-2`}
                >
                  {artistProfile.hobbies!.map(
                    (item: string, idx: React.Key | null | undefined) => {
                      return (
                        <Chip
                          closeButton
                          key={idx}
                          selected
                          onClick={() => {
                            setArtistProfile({
                              ...artistProfile,
                              hobbies: artistProfile.hobbies!.filter(
                                (x: any) => x !== item
                              ),
                            });
                          }}
                          title={item}
                        ></Chip>
                      );
                    }
                  )}
                </div>
              </div>
            </TitleWrap>
            <TitleWrap title="거주지">
              <Input
                value={artistProfile?.sido}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    sido: e.target.value,
                  });
                }}
                className="w-[589px]"
                placeholder="시까지 입력"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="자기소개" subTitle="최대 2000자">
              <Textarea
                value={artistProfile?.introduce}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const phoneNumberPattern = /01[0-9][ {}\[\]\/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]*?[0-9]{3,4}[ {}\[\]\/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]*?[0-9]{4}/g;
                  if (phoneNumberPattern.test(inputValue)) {
                    Toast.error(`휴대폰번호는 입력할 수 없습니다.`);
                    e.target.value = inputValue.replace(phoneNumberPattern, "");
                  }

                  setArtistProfile({
                    ...artistProfile,
                    introduce: e.target.value,
                  });
                }}
                placeholder="개인정보(휴대폰번호, 주소 등) 작성을 유의해주세요."
                className="w-full h-[270px]"
                maxlength={2000}
              />
            </TitleWrap>
          </ContentWrap>
        </div>
        <hr className="w-full h-3 bg-Gray01 my-10" />
        <div className="px-5">
          <div className="flex pb-4 justify-between items-center">
            <div className="BBody20 text-Gray09">필모그래피</div>
            <IconPlus
              onClick={() => {
                addFilmographyItem();
              }}
              className="cursor-pointer"
            />
          </div>
          {artistProfile.filmographies!.map((item, idx) => {
            return (
              <div key={idx} className="flex flex-col gap-4">
                <div className={`flex items-center gap-3`}>
                  <TitleWrap title={"제작년도"}>
                    <Input
                      value={artistProfile?.filmographies![idx].releaseYear}
                      onChange={(e) => {
                        const updatedFilmographies = [
                          ...artistProfile.filmographies!,
                        ];
                        updatedFilmographies[idx].releaseYear = Number(
                          e.target.value.replace(/[^0-9]/g, "").slice(0, 4)
                        );
                        setArtistProfile({
                          ...artistProfile,
                          filmographies: updatedFilmographies,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-", "."].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      placeholder="제작년도"
                      type={"number"}
                      inputMode="numeric"
                    ></Input>
                  </TitleWrap>
                  <TitleWrap title="분야">
                    <Dropdown
                      className="!rounded-md"
                      innerText={
                        artistProfile.filmographies![idx].type === "MOVIE"
                          ? "영화"
                          : artistProfile.filmographies![idx].type ===
                            "TV_SERIES"
                            ? "드라마"
                            : artistProfile.filmographies![idx].type ===
                              "VARIETY_SHOW"
                              ? "예능"
                              : artistProfile.filmographies![idx].type ===
                                "MUSIC_VIDEO"
                                ? "뮤직비디오"
                                : artistProfile.filmographies![idx].type ===
                                  "WEB_SERIES"
                                  ? "웹드라마"
                                  : artistProfile.filmographies![idx].type ===
                                    "SHORT_FORM"
                                    ? "숏폼"
                                    : artistProfile.filmographies![idx].type ===
                                      "INDEPENDENT_FILM"
                                      ? "독립영화"
                                      : artistProfile.filmographies![idx].type ===
                                        "SHORT_FILM"
                                        ? "단편영화"
                                        : artistProfile.filmographies![idx].type === "MUSICAL"
                                          ? "뮤지컬"
                                          : artistProfile.filmographies![idx].type === "THEATER"
                                            ? "연극"
                                            : artistProfile.filmographies![idx].type ===
                                              "DOCUMENTARY"
                                              ? "다큐멘터리"
                                              : "선택"
                      }
                    >
                      <div>
                        {TYPE_LIST.map((item) => {
                          return (
                            <li
                              onClick={() => {
                                const updatedFilmographies = [
                                  ...artistProfile.filmographies!,
                                ];
                                updatedFilmographies[idx].type = item.role;
                                setArtistProfile({
                                  ...artistProfile,
                                  filmographies: updatedFilmographies,
                                });
                              }}
                              key={item.id}
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </div>
                    </Dropdown>
                  </TitleWrap>
                </div>
                <TitleWrap title="작품 제목" subTitle="최대 36자">
                  <Input
                    value={artistProfile?.filmographies![idx].title}
                    onChange={(e) => {
                      const updatedFilmographies = [
                        ...artistProfile.filmographies!,
                      ];
                      updatedFilmographies[idx].title = e.target.value;
                      setArtistProfile({
                        ...artistProfile,
                        filmographies: updatedFilmographies,
                      });
                    }}
                    className="w-[589px]"
                    placeholder="작품 제목을 입력해 주세요"
                    type={"text"}
                  ></Input>
                </TitleWrap>
                <div className="flex items-center gap-3">
                  <TitleWrap title="배역">
                    <Dropdown
                      className="!rounded-md"
                      innerText={
                        roleMap[artistProfile.filmographies![idx].role || ""] ||
                        "선택"
                      }
                    >
                      <div>
                        {CASTING_LIST.map((item) => {
                          return (
                            <li
                              onClick={() => {
                                const updatedFilmographies = [
                                  ...artistProfile.filmographies!,
                                ];
                                updatedFilmographies[idx].role = item.role;
                                setArtistProfile({
                                  ...artistProfile,
                                  filmographies: updatedFilmographies,
                                });
                              }}
                              key={item.id}
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </div>
                    </Dropdown>
                  </TitleWrap>
                  <TitleWrap title={"배역명"}>
                    <Input
                      value={artistProfile?.filmographies![idx].description}
                      onChange={(e) => {
                        const updatedFilmographies = [
                          ...artistProfile.filmographies!,
                        ];
                        updatedFilmographies[idx].description = e.target.value;
                        setArtistProfile({
                          ...artistProfile,
                          filmographies: updatedFilmographies,
                        });
                      }}
                      className="w-[285px]"
                      placeholder="배역명"
                      type={"text"}
                    ></Input>
                  </TitleWrap>
                </div>
                <div
                  onClick={() => {
                    removeFilmographyItem(idx);
                  }}
                  className="flex items-center gap-2 cursor-pointer"
                >
                  <span className="border-t border-dashed flex-1" />
                  <Delete />
                  <span className="border-t border-dashed flex-1" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ButtonBackground>
        <Button
          onClick={() => {
            const validError = valid(artistProfile);
            if (validError !== null) {
              Toast.error(validError);
            } else {
              submitProfile();
            }
          }}
          className="w-full h-12 bg-Blue04 text-white border-none"
          text={`등록하기`}
        ></Button>
      </ButtonBackground>
    </div>
  );
};

export default ManageArtistProfile;
