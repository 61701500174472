import React, { useEffect, useState } from "react";

import { ReactComponent as Share } from "../assets/share.svg";

import { useLocation, useParams } from "react-router-dom";
import { ReactComponent as SendFeedback } from "assets/sendFeedback.svg";
import { ReactComponent as Feedback } from "assets/icon_checkbox.svg";
import { ReactComponent as BlankStar } from "assets/blankStar.svg";
import { ReactComponent as Star } from "assets/star.svg";
import {
  useCastingProfileDetails,
  useSubmitFeedback,
} from "api/DeliveryProfile/DeliveryProfile";
import BottomButtonPopup from "components/BottomButtonPopup";
import BottomPopup from "components/BottomPopup";
import Textarea from "components/Textarea";
import Button from "components/Button";
import { Toast } from "hooks/useToast";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useShareLink } from "api/Share/Share";
import Modal from "components/Modal";
import { handleCopyClipBoard } from "utils/onCopyClipBoard";
import ScrollToTop from "components/ScrollToTop";
import ButtonBackground from "components/ButtonBackground";
import ReviewTimer from "components/ReviewTimer";
import Skeleton from "components/Skeleton";
import ArtistAttachments from "./ArtistDetail/_components/ArtistAttachments";
import ArtistDesc from "./ArtistDetail/_components/ArtistDesc";
import ArtistFilmographies from "./ArtistDetail/_components/ArtistFilmographies";
import ArtistInfoCard from "./ArtistDetail/_components/ArtistInfoCard";

const ArtistFeedback = () => {
  const { artistId } = useParams();
  const { state } = useLocation();
  const location = useLocation();
  const isRef = location.hash === "#ref";

  const [tabId, setTabId] = useState(0);
  const [feedbackScore, setFeedbackScore] = useState(0);
  const [canReview, setCanReview] = useState<boolean>(false);

  const [review, setReview] = useState("");
  const [copyModal, setCopyModal] = useState(false);
  const [profileId, setProfileId] = useState<null | number>(null);
  const [sendFeedback, setSendFeedback] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const { data, isFetching, refetch } = useCastingProfileDetails({
    castingCallid: state.castingCallId,
    castingId: state.castingId,
  });

  const profileTab = [
    { id: 0, title: "프로필" },
    // { id: 1, title: "포트폴리오" },
  ]

  const ArtistData = data?.data?.data;

  const isPremiumDelivery = ArtistData?.type.code === "PREMIUM_DELIVERY";
  const isButtonDisabled = isPremiumDelivery
    ? review.length < 300 || feedbackScore === 0
    : false;

  const ratingStartValue = () => {
    let result = [];
    for (let i = 0; i < 5; i++) {
      result.push(
        <div
          key={i + 1}
          onClick={() => {
            setFeedbackScore(i + 1);
          }}
        >
          {i + 1 <= feedbackScore ? <Star /> : <BlankStar />}
        </div>
      );
    }
    return result;
  };

  const readRatingStartValue = () => {
    let result = [];
    for (let i = 0; i < 5; i++) {
      result.push(
        <div key={i + 1}>
          {i + 1 <= ArtistData?.rating! ? <Star /> : <BlankStar />}
        </div>
      );
    }
    return result;
  };

  const mutate = useSubmitFeedback();

  const submitFeedback = () => {
    mutate.mutate(
      {
        id: Number(artistId),
        rating: feedbackScore,
        comments: review,
      },
      {
        onSuccess: () => {
          Toast.success("피드백을 보냈어요");
          refetch();
          setSendFeedback(false);
        },
        onError: () => {
          setSendFeedback(false);
        },
      }
    );
  };

  const { data: link } = useShareLink({
    id: profileId,
    type: "DELIVERY_PROFILE",
    period: "MONTH",
  });

  useEffect(() => {
    if (ArtistData?.status.code === "FEEDBACK") setCanReview(true);
  }, [ArtistData?.status.code]);

  return (
    <>
      <HeaderWithBackButton
        phone={ArtistData?.phoneNumber}
        sms={ArtistData?.phoneNumber}
        email={ArtistData?.email}
        title=""
      >
        {/* <Bookmark className="cursor-pointer" /> */}
        <Share
          onClick={() => {
            setCopyModal(true);
            setProfileId(ArtistData?.deliveryProfileId!);
          }}
          className="cursor-pointer"
        />
      </HeaderWithBackButton>
      {isFetching ? (
        <Skeleton type="PROFILE_DETAIL" />
      ) : (
        <>
          <div className="pb-24 pt-5">
            <ScrollToTop />
            {ArtistData?.description && (
              <div className="px-5">
                <div className="p-5 w-full border mb-5 border-Gray03 bg-Gray01 rounded-[10px]">
                  <div className="w-full text-Gray09 BBody16">
                    <div>💌 {ArtistData?.profileName}님이 보낸 프로필</div>
                    {/* <ArrowDown /> */}
                  </div>
                  <div className="text-Gray09 break-words whitespace-pre-line RBody14 mt-4">
                    {ArtistData?.description}
                  </div>
                </div>
              </div>
            )}

            <div className="w-full">
              <ArtistInfoCard
                artistName={ArtistData?.profileName!}
                artistImageUrl={ArtistData?.thumbnailUrl!}
                jobs={ArtistData?.jobs!}
                birth={ArtistData?.birthDate!}
                height={ArtistData?.basicInfo.height!}
                weight={ArtistData?.basicInfo.weight!}
                instagram={ArtistData?.snsInfo.instagram!}
                youtube={ArtistData?.snsInfo.youtube!}
                isSharedPage
              />
              <div className="border-b border-Gray-3 mt-4">
                <div className="px-5 flex items-start gap-4 BCaption14">
                  {profileTab.map((item) => {
                    return (
                      <div key={item.id}
                        onClick={() => { setTabId(item.id) }}
                        className={`pb-4 cursor-pointer px-1 ${item.id === tabId ? "border-b-2 border-Gray09 text-Gray09" : "border-none text-Gray04"}`}>
                        {item.title}
                      </div>
                    )
                  })}
                </div>
              </div>
              {tabId === 0 ? (
                <div>
                  <ArtistAttachments
                    attachments={ArtistData?.attachments!}
                    videoLinks={ArtistData?.videoLinks!} />
                  <ArtistDesc
                    hobbies={ArtistData?.hobbies!}
                    address={ArtistData?.basicInfo.address.sido!}
                    education={ArtistData?.basicInfo.education!}
                    agency={ArtistData?.basicInfo.agency!}
                    introduce={ArtistData?.introduce!}
                  />
                  {ArtistData?.filmographies?.length! > 0 &&
                    <ArtistFilmographies filmographies={ArtistData?.filmographies!} />
                  }
                </div>
              ) : (
                <div />
              )}
            </div>

            {copyModal && (
              <Modal
                onClose={() => {
                  setProfileId(null);
                  setCopyModal(false);
                }}
                title="공유하기"
                subTitle="공유된 링크는 30일 동안 유효해요."
                closeButton
              >
                <Button
                  onClick={() => {
                    handleCopyClipBoard(
                      `${process.env.REACT_APP_URL}/artist/share?value=${link
                        ?.data.value!}&type=PROFILE`
                    );
                    setCopyModal(false);
                  }}
                  className="w-full border border-Gray03 text-Gray05"
                  text={"링크 복사하기"}
                ></Button>
              </Modal>
            )}
            {!isRef && (
              <>
                {feedback && (
                  <BottomPopup
                    onClose={() => {
                      setFeedback(false);
                    }}
                  >
                    <div className="BBody20">보낸 프로필 피드백</div>
                    <div className="mt-4 mb-10">
                      <div className="flex items-center gap-1">
                        <div className="RBody16 text-Gray09">별점</div>
                        <div className="BBody16 text-Blue04">
                          {ArtistData?.rating ? ArtistData?.rating : 0}/5
                        </div>
                      </div>
                      <div className="flex items-center mt-4 gap-4">
                        {readRatingStartValue()}
                      </div>
                    </div>
                    {ArtistData?.comments && (
                      <div className="p-4 bg-Gray01 max-h-64 overflow-y-scroll rounded-[10px] RBody16 break-words whitespace-pre-line">
                        {ArtistData.comments}
                      </div>
                    )}
                  </BottomPopup>
                )}
                {sendFeedback && (
                  <BottomPopup
                    onClose={() => {
                      setSendFeedback(false);
                      setFeedbackScore(0);
                    }}
                  >
                    <div className="BBody20">프로필 피드백</div>
                    <div className="mt-4 mb-10">
                      <div className="flex items-center gap-1">
                        <div className="RBody16 text-Gray09">별점</div>
                        <div className="BBody16 text-Blue04">
                          {feedbackScore ? feedbackScore : 0}/5
                        </div>
                      </div>
                      <div className="flex items-center mt-4 gap-4">
                        {ratingStartValue()}
                      </div>
                    </div>
                    <div className="RBody16">
                      이런 점이 좋아요🙂 / 아쉬워요😔
                    </div>
                    <Textarea
                      maxlength={2000}
                      onCopy={(e) => {
                        e.preventDefault();
                        e.clipboardData.setData("Text", "복사 불가능!");
                      }}
                      onCut={(e) => {
                        e.preventDefault();
                        e.clipboardData.setData("Text", "잘라내기 불가능!");
                      }}
                      onPaste={(e) => e.preventDefault()}
                      onChange={(e) => {
                        setReview(e.target.value.trim());
                      }}
                      value={review}
                      className="h-[105px] mt-4 mb-8"
                    ></Textarea>
                    <ol className="list-disc list-inside RBody14 text-Gray05">
                      <li>
                        {ArtistData?.type.code === "PREMIUM_DELIVERY" &&
                          "300자 이상/"}
                        좋은 점, 보완할 점 등을 상세하게 적어주세요.
                      </li>
                      <li>
                        타인을 비방하는 내용의 피드백을 보내면 정지될 수 있어요.
                      </li>
                    </ol>
                    <ButtonBackground>
                      <Button
                        onClick={() => {
                          submitFeedback();
                        }}
                        disabled={isButtonDisabled}
                        className="w-full bg-Blue04 text-Gray00 mt-4"
                        text={"피드백 보내기"}
                      />
                    </ButtonBackground>
                  </BottomPopup>
                )}
                {canReview && (
                  <>
                    {ArtistData?.status.code === "CANCELED" ||
                      ArtistData?.status.code === "REFUND_REQUEST" ||
                      ArtistData?.status.code === "REFUNDED" ? null : (
                      <BottomButtonPopup
                        onClick={() => {
                          if (
                            ArtistData?.status.code === "DELIVERED" ||
                            ArtistData?.status.code === "READ"
                          ) {
                            setSendFeedback(true);
                          } else {
                            setFeedback(true);
                          }
                        }}
                        onClose={() => { }}
                      >
                        <div className="flex flex-col h-full justify-center items-center MBody14 cursor-pointer text-Gray00">
                          {ArtistData?.status.code === "DELIVERED" ||
                            ArtistData?.status.code === "READ" ? (
                            <SendFeedback />
                          ) : (
                            <Feedback />
                          )}

                          <div>
                            {ArtistData?.status.code === "DELIVERED" ||
                              ArtistData?.status.code === "READ"
                              ? "피드백 보내기"
                              : "보낸 피드백 보기"}
                          </div>
                        </div>
                      </BottomButtonPopup>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {!isRef && (
            <>
              {(ArtistData?.status.code === "DELIVERED" ||
                ArtistData?.status.code === "READ") && (
                  <ReviewTimer
                    stayTime={
                      ArtistData?.type.code === "PREMIUM_DELIVERY" ? 10 : 1
                    }
                    onTimePassed={() => {
                      setCanReview(true);
                    }}
                  />
                )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ArtistFeedback;
