import React from 'react';

interface MemberSelectCardProps {
    imageUrl: string;
    title: string;
    subTitle: string;
    selected: boolean;
    onClick?: () => void;
}

const MemberSelectCard = ({
    imageUrl,
    title,
    subTitle,
    selected,
    onClick,
}: MemberSelectCardProps) => {
    const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

    return (
        <div onClick={onClick} className={`px-5 flex items-center gap-4 h-[75px] cursor-pointer ${selected ? "bg-Gray01" : "bg-Gray00"}`}>
            <img className='w-12 h-12 rounded-full object-cover' src={MEDIA_URL + imageUrl} alt="프로필 보내기 카드" />
            <div className='flex-1 flex flex-col items-start'>
                <div className='MBody18'>{title}</div>
                <div className='RCaption14 text-Gray05'>{subTitle}</div>
            </div>
            {selected &&
                <div className="w-6 h-6 rounded-full bg-Blue04 flex items-center justify-center">
                    <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 3L4.5 8.5L2 6"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>}
        </div>
    );
};

export default MemberSelectCard;