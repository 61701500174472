import {
  useScarpNotice,
  useScarpProduction,
  useScarpProfile,
} from "api/Scrap/Scrap";
import ArtistListCard from "components/ArtistListCard";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import StaffCard from "components/StaffCard";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useState } from "react";

const TabButton = ({
  label,
  isActive,
  onClick,
}: {
  label: string;
  isActive: boolean;
  onClick: () => void;
}) => (
  <button
    onClick={onClick}
    className={`w-full py-4 BCaption14 ${
      isActive
        ? "text-Gray09 border-b-2 border-black"
        : "text-Gray05 border-b-2 border-transparent"
    }`}
  >
    {label}
  </button>
);

interface Gender {
  code: "MALE" | "FEMALE";
  label: "남성" | "여성";
}

interface Role {
  code: string;
  label: string;
  role: [
    | "ADMIN"
    | "ARTIST"
    | "MANAGER"
    | "CASTING_DIRECTOR"
    | "DIRECTOR"
    | "PRODUCER"
    | "AGENCY"
    | "WRITER"
  ];
}

interface ProfileItem {
  favoriteProfileId: number;
  profileId: number;
  thumbnailUrl: string;
  profileName: string;
  gender: Gender;
  birthDate: string;
  role: Role;
  height: number;
  weight: number;
  agency: string;
  isActive: boolean;
}

interface WorkItem {
  favoriteProductionId: number;
  productionId: number;
  thumbnailUrl: string;
  title: string;
  isActive: boolean;
}

interface NoticeItem {
  favoriteCastingCallId: number;
  castingCallId: number;
  thumbnailUrl: string;
  title: string;
  isActive: boolean;
}

const Scrap = () => {
  const [activeTab, setActiveTab] = useState("공고");
  const Tabs = ["공고", "작품", "아티스트", "스탭"];
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  // Fetch data for each tab
  const {
    data: noticeData,
    fetchNextPage: fetchNextNotice,
    hasNextPage: hasNextNotice,
  } = useScarpNotice({});

  const {
    data: productionData,
    fetchNextPage: fetchNextProduction,
    hasNextPage: hasNextProduction,
  } = useScarpProduction({});

  const {
    data: artistData,
    fetchNextPage: fetchNextArtist,
    hasNextPage: hasNextArtist,
  } = useScarpProfile({ type: "ARTIST" });

  const {
    data: staffData,
    fetchNextPage: fetchNextStaff,
    hasNextPage: hasNextStaff,
  } = useScarpProfile({ type: "STAFF" });

  useEffect(() => {
    if (!inView) return;

    switch (activeTab) {
      case "공고":
        if (hasNextNotice) fetchNextNotice();
        break;
      case "작품":
        if (hasNextProduction) fetchNextProduction();
        break;
      case "아티스트":
        if (hasNextArtist) fetchNextArtist();
        break;
      case "스탭":
        if (hasNextStaff) fetchNextStaff();
        break;
    }
  }, [inView, activeTab]);

  const renderContent = () => {
    switch (activeTab) {
      case "공고":
        return (
          <div className="flex flex-col gap-4">
            {noticeData?.pages[0].data.content.map((item: NoticeItem) => (
              <div
                key={item.favoriteCastingCallId}
                className="p-4 border rounded-[6px]"
              >
                <div className="flex items-center gap-2 mb-2">
                  {/* <span className="MCaption14 text-Gray05">{item.category}</span> */}
                  <span className="MCaption14 text-Gray05">드라마</span>
                  <span className="BCaption14 text-Gray05">{item.title}</span>
                </div>
                <div className="BBody18 text-Gray09 mb-4">{item.title}</div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-2 MCaption14 text-Gray09">
                    <span>남녀무관</span>
                    <span>|</span>
                    <span>20대</span>
                  </div>
                  {/* <span>{item.location}</span>
                                        <span>|</span>
                                        <span>{item.age}</span>
                                    </div> */}
                  <div className="flex justify-between items-center">
                    <span className="MCaption14 text-Gray05">
                      {/* {item.deadline}까지 */}
                      2024.10.31까지
                    </span>
                    <span className="MCaption10 text-Error px-1 py-0.5 bg-[#ffefef] rounded-[6px]">
                      {/* {item.status} */}마감임박
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      case "작품":
        return (
          <div className="flex flex-col gap-4">
            {productionData?.pages[0].data.content.map((item: WorkItem) => (
              <div
                key={item.favoriteProductionId}
                className="flex flex-col rounded-[6px] border overflow-hidden"
              >
                {/* <div className="w-full h-[200px] bg-[#11225f]"></div> */}
                <img
                  src={item.thumbnailUrl}
                  alt=""
                  className="w-full h-[200px]"
                />
                <div className="flex flex-col gap-2 p-4">
                  {/* <span className="MCaption14 text-Gray05">{item.category}</span> */}
                  <span className="MCaption14 text-Gray05">드라마</span>
                  <span className="BBody18 text-Gray09">{item.title}</span>
                  <div className="flex gap-2 MCaption14 text-Gray09">
                    {/* <span>{item.artist}</span>
                                        <span>|</span>
                                        <span>{item.company}</span> */}
                    <span>김캐디</span>
                    <span>|</span>
                    <span>스튜디오제이티</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      case "아티스트":
        return (
          <div className="grid grid-cols-3 gap-y-4 gap-x-2">
            {artistData?.pages[0].data.content.map((item: ProfileItem) => (
              <ArtistListCard
                key={item.profileId}
                onClick={() => {}}
                title={item.profileName}
                src={item.thumbnailUrl}
                subTitle={item.birthDate}
              />
            ))}
          </div>
        );
      case "스탭":
        return (
          <div className="flex flex-col gap-2">
            {staffData?.pages[0].data.content.map((item: ProfileItem) => (
              <StaffCard
                key={item.profileId}
                // isCasting={item?.isCasting}
                // isPending={item?.status.code === "PENDING"}
                isCasting={true}
                isPending={false}
                image={item.thumbnailUrl}
                role={item.role.code}
                agency={item.agency}
                name={item.profileName}
                onClick={() => {}}
              />
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col">
      <HeaderWithBackButton title="스크랩" />
      <div className="flex flex-col">
        <div className="flex border-b px-5">
          {Tabs.map((tab) => (
            <TabButton
              key={tab}
              label={tab}
              isActive={activeTab === tab}
              onClick={() => setActiveTab(tab)}
            />
          ))}
        </div>
        <div className="py-4 px-5">
          {renderContent()}
          <div ref={ref} />
        </div>
      </div>
    </div>
  );
};

export default Scrap;
